* {
  font-family: 'Karla', sans-serif;
}



.title {
  text-align: center;
  /*margin-bottom: 5%; */
}

.ui.secondary.menu.header {
  margin-bottom: 0;
  height: 5rem;

}

.ui.secondary.menu .item .header-flex .ui.item.dropdown img {
  font-size: 14px;
}

.ui.secondary.menu.header .text {
  padding-right: 2rem;
}

.ui.dropdown.header.login {
  position: relative;
  top: .6rem;
  right: 4rem;
  font-size: 14px;
  color: #333f48;
}

.dropdown-header .ui.dropdown .text {
  font-weight: 400;
}

.dropdown-header a:hover {
  cursor: pointer;
}



.bell.outline.icon {
  position: relative;
  top: 1.7rem;
  right: 5rem;
}

.home-search-bar {
  padding: .3rem;
  background: rgba(255, 255, 255, .2);
  margin-top: 5rem;
  display: inline-block;
}

.ui.input.search-input-term>input {
  border-radius: 3px 0px 0px 3px;
}

.ui.input.search-input-urgency>input,
.ui.input.search-input-urgency>input+button,
.ui.input.search-input-location>input {
  border-radius: 0px;
  width: 20%
}

.ui.input.search-input-query>input::-webkit-input-placeholder {
  color: black;
}

.map.marker.alternate.icon {
  color: black;
}

.ui.icon.button.label.search.button {
  border-radius: 0px 3px 3px 0px;
  background-color: #00BF6F;
  width: 6rem;
  color: white;
}

div .ui.card .meta.bio {

  color: #43b02a;
}

h2.view-all-link {
  padding: 2rem;
  color: #43b02a;
  /* display: inline-block; */
}

div.listingsHeader {
  text-align: center
}

div.listingsHeader>* {
  display: inline-block;
  text-align: center
}

h1 {
  color: #333f48;
}

h1.homepage-title {
  color: White;
  text-align: center;
  padding-top: 20px;
}

p {
  color: #7a7a7a;
  font-size: 1.25rem;
}

img.rhiz-header-logo {
  margin-left: 4rem;
}

.home-page-section {
  padding: 100px 50px 100px 50px;
  max-width: 1500px;
  margin: 0 auto 0 auto;
}

/* .home-page-section-welcome-wrapper {

} */

.home-page-section-welcome-left {
  width: 40%;
  display: inline-block;
}

.home-page-section-welcome-left .home-page-section-welcome {
  margin: 0 0 .5em;
}


.home-page-section-welcome {
  text-align: left;
  /*font-size: 3rem;*/
  font-size: 60px;
  line-height: 1;
  font-weight: 600;
  display: inline-block;
  height: 10%;
  /*padding: 0 15rem 0 7rem; */
  color: #333f48;
}

.home-page-section-wrapper .business-pricing-cta {
  text-align: center;
}

.home-page-section-wrapper .business-pricing-cta a .button {
  margin-top: 2em;
  background-color: #00BF6F;
}

.home-page-section-wrapper .business-pricing-cta a .button:hover {
  background-color: #008c52;
}

.home-page-section-welcome-right {
  display: inline-block;
  position: absolute;
  right: 0;
}

.home-page-section-welcome-right img {
  width: 900px;
  margin-top: 50px;
}

.home-page-section-wrapper {
  margin: 0 auto 0 auto;
  justify-content: center;
}


.home-page-section-one-left {
  text-align: left;
  font-size: 60px;
  font-weight: 600;
  display: inline-block;
  width: 40%;
  height: 10%;
  /*padding: 0 15rem 0 7rem; */
  color: #333f48;
  line-height: 1;
  vertical-align: top;


  /* media queries for font size */
}

.home-page-section-one-line-break {
  border: .2rem solid #333f48;
  /*display: inline-block;*/
  margin: 2.1rem 0;
  width: 6rem;
}

.home-page-section-one-right {
  height: 10%;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.8;
  display: inline-block;
  text-align: left;
  width: 60%;
  margin: 0;
  padding-left: 6rem;

  /* media queries for font size */
}

.home-page-section-two-wrapper {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*flex-wrap: wrap;*/
  /*justify-content: center;*/
  /*padding: 0 2rem 2rem 2rem;*/
  /*text-align: center;*/
  margin-bottom: 3rem;
}

.home-page-section-two {
  display: inline-block;
  text-align: left;
  font-size: 0;
  vertical-align: top;
  width: 25%;
  margin-top: 50px;
  /*margin: 2rem;*/
}


.home-page-section-two:nth-child(1) {
  padding-right: 20px;
}

.home-page-section-two:nth-child(2) {
  padding-right: 20px;
}

.home-page-section-two:nth-child(4) {
  padding-left: 20px;
}


.home-page-section-two:nth-child(3) {
  padding-left: 20px;
}



.home-page-section-two>h1+p {
  vertical-align: middle;
  font-size: 1.25rem;
  line-height: 1.8 ;
  text-align: left;
  /*width: 18rem;*/
  font-size: 1.2rem;
}

.home-page-section-three-wrapper {
  /* height: 31rem; */
  background-color: whitesmoke;
  text-align: center;
  /* margin-bottom: 5%; */
}

/* .home-page-section-three-wrapper>div{
  /* padding: 10rem 38rem; */
/* width: 35%; */
/* }  */

.home-page-section-three-wrapper .consumer-blurb {
  margin: 3em 0;
  font-size: 1.25rem;
  line-height: 2;
}

.consumer-blurb p {
  font-size: 1.25rem;
  line-height: 1.8;
}

.home-page-section-three-wrapper>div>h1 {
  display: block;
  /* width: 30%; */
  font-size: 2rem;
  line-height: 1.3;
}

.home-page-section-three-wrapper .home-page-section-three {
  font-size: 3rem;
}

.home-page-section-three-wrapper>div button.ui.positive.button {
  /* font-size: .5rem; */
  background-color: #00BF6F;
  margin-top: 2rem;
}

.home-page-section-five-wrapper {
  /* height: 70rem; */
  /* background-color: whitesmoke; */
  text-align: center;
  /*display: inline-block;*/
  vertical-align: middle;
}

/* .home-page-section-five-header-div-center{
  /* padding: 6rem 2rem; */
/* } */
.home-page-section-five-header-div-center>h1 {
  line-height: 1;
  font-size: 3rem;
}

.home-page-section-five-header-div-center>p {
  line-height: 1;
  font-size: 1.25rem;
  line-height: 1.8;
}

.home-page-section-five-line-break {
  border: .13rem solid darkgrey;
  display: inline-block;
  width: 4rem;
}

.home-page-section-five-div-left {
  line-height: 1;
  width: 50%;
  display: inline-block;
  /*padding: 3rem 7rem;*/
  padding: 25px 50px 25px 0px;
  text-align: left;
}

.home-page-section-five-div-left>p {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #787878;
  margin: 2rem 0;
  letter-spacing: 0px;
}

.home-page-section-five-div-right {
  line-height: 1;
  width: 50%;
  display: inline-block;
  height: 30%;
  /*padding: 3rem 7rem;*/
  padding: 25px 0px 25px 50px;
  text-align: left;
  vertical-align: top;
}

.home-page-section-five-div-right>p {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #787878;
  margin: 2rem 0;
  letter-spacing: 0;
}

.intro-wrapper {
  background-color: whitesmoke;
  /* height: 120% */
}

/* .home-page-section-five-header-div-three{
  line-height: 1;
}
.home-page-section-five-header-div-four{
  line-height: 1;
} */



.home-page-section-six-wrapper {
  /*height: 31rem; */
  /*background-color: #00BF6F; */
  text-align: center;
}

/* .home-page-section-six-wrapper>div{ */
/* padding: 10rem 38rem; */
/* width: 35%; */
/* } */
.home-page-section-six-wrapper>div>h1 {
  display: block;
  /* width: 30%; */
  font-size: 2rem;
  line-height: 1.3;
  color: white;
}

.home-page-section-six-wrapper>div button.ui.huge.button {
  /* font-size: .5rem; */
  margin-top: 2rem;
  background-color: whitesmoke;
  color: black;
}

.questions-wrapper {
  line-height: normal;
  height: 40rem;
}

.home-feature {
  color: #00bf6f;
  font-size: 1.3rem;
  margin-top: 30px;
}

.home-feature-description {
  margin-top: 10px;
}

.home-feature-description p {
  line-height: 1.8;
  font-size: 1.25rem;
}

.home-page-section-three-wrapper>div button.ui.positive.button:hover {
  background-color: #008c52;
}

.home-page-section-six-wrapper>div button.ui.huge.button:hover {
  background-color: #008c52;
  color: white;
}

/*Update the search button and icon color*/
.ui.icon.button.label.search.button {
  background-color: #00BF6F;
}

#ui.icon.button.label.searchButton {
  background-color: #00BF6F;
}

i.search.icon {
  color: white;
}

.ui.button {
  font-family: 'Karla', sans-serif !important;
}

.featured-category {
  padding: 150px;
  background-color: black;
  display: inline-block;
  margin: 5px;
  background-repeat: no-repeat;
  background-size: cover;
}

.featured-categories {
  text-align: center;
}

.feature-category-wrapper {
  display: inline-block;
  margin-bottom: 20px;
}

.category-name {
  display: block;
  margin-top: 5px;
}

.featured-category#masonry {
  background-image: url("https://res.cloudinary.com/dqxnksmgd/image/upload/v1565626993/masonry.jpg");
}

.featured-category#sustainable-landscaping {
  background-image: url("https://res.cloudinary.com/dqxnksmgd/image/upload/v1565627002/landscaping.jpg");
}

.featured-category#electrical {
  background-image: url("https://res.cloudinary.com/dqxnksmgd/image/upload/v1565626990/electrical.jpg");
}

.featured-category#custom-pools {
  background-image: url("https://res.cloudinary.com/dqxnksmgd/image/upload/v1565626991/pools.jpg");
}

.home-page-section-two img {
  max-width: 75%;
}

.home-page-section-two h1 {
  margin-top: 0px;
}

.what-is-rhiz {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  padding-top: 0px;
}

.what-is-rhiz:first-child {
  text-align: center;
}


.what-is-rhiz img {
  max-width: 70%;
}

.what-is-rhiz h1 {
  font-size: 3rem !important;
}

.what-is-rhiz h2 {
  color: #bbb;
}

.what-is-rhiz p {
  line-height: 1.8;
  font-size: 1.25rem;
  max-width: 75%;
}

.what-is-rhiz button {
  background-color: #00bf6f !important;
}

.home-page-section-three-wrapper>div>div {
  display: inline-block;
  width: 60%;
  vertical-align: middle;
  padding-left: 6rem;
}

.home-page-section-three-wrapper>div>div:first-child {
  display: inline-block;
  width: 40%;
  vertical-align: middle;
  padding-left: 0;
}

.home-page-section-three-wrapper>div>div img {
  max-width: 90%;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1449px) {
  .home-page-section-welcome-right img {
    width: 100%;
  }

  .featured-category {
    padding: 125px;
  }

}

@media only screen and (max-width: 1330px) {
  .home-page-section-welcome-left {
    width: 100%;
  }

  .home-page-section-welcome {
    margin-bottom: 25px;
  }

  .home-page-section-welcome-right {
    position: static !important;
    margin-top: 50px;
  }

  .home-page-section-welcome-right img {
    width: 100%;
    margin-left: -40px;
  }

  .home-page-section-three-wrapper .home-page-section-three, .home-page-section-three-wrapper .consumer-blurb {
    padding: 0 0;
  }

  .home-page-section-wrapper, .home-page-section-welcome {
    text-align: center;
  }

  .home-page-section-welcome-right img {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1155px) {
  .featured-category {
    padding: 175px;
  }

  .featured-categories {
    max-width: 900px;
    margin: 0px auto 0px auto;
  }
}

@media only screen and (max-width: 999px) {
  .home-page-section-one-left {
    width: 100% !important;
    display: block;
    margin-bottom: 30px;
  }

  .home-page-section-one-right {
    width: 100%;
    display: block;
    padding: 0px;
  }

  .featured-categories {
    margin: 0px auto 0px auto;
  }

  .home-page-section-one-line-break, .home-page-section-two img {
    margin-left: auto;
    margin-right: auto;
  }

  .home-page-section-one-left, .home-page-section-two {
    text-align: center;
  }

  .home-page-section-three-wrapper>div>div{
    width: 100%;
  }
  .home-page-section-three-wrapper>div>div {
    padding-left: 0;
  }

}

@media only screen and (max-width: 767px) {
  h1.homepage-title{
    padding-top: 50px;
    line-height: 1;
  }
  .home-page-section-welcome, .home-page-section-one-left, .home-page-section-three-wrapper .home-page-section-three {
    font-size: 2.25rem;
    letter-spacing: 0;
    line-height: 1.15;
  }
  .home-page-section {
    padding: 50px 20px 50px 20px !important;
  }

  .home-page-section-two {
    width: 100% !important;
    display: block;
    padding: 10px 0px 10px 0px !important;
    margin-top: 25px !important;
  }

  .home-page-section-five-div-right,
  .home-page-section-five-div-left {
    width: 100% !important;
    padding: 10px 0px 10px 0px !important;
    margin-top: 25px;
    text-align: center;
  }

  .what-is-rhiz {
    padding-top: 0px !important;
  }
  .what-is-rhiz, .what-is-rhiz p {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .home-page-section-one-right, .home-page-section-two>h1+p {
    text-align: center;
  }
  .featured-category {
    padding: 150px;
  }
  .home-page-section-five-div-left h1, .home-page-section-five-div-right h1{
    font-size: 1.5rem;
  }
  .feature-category-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}