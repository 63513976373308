.ui.label {
  background-color: white !important;
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.team-page {
  margin: 50px 0px 100px 0px;
  margin-top: 70px;
  min-height: 100vh;
  max-height: 100%;
}

.headshot {
  border-radius: 50%;
  height: 10em;
  width: 10em;
  border: 3px solid #00bf6f;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
}

.team-member-position {
  color: #7a7a7a;
}

h2,
p {
  font-family: 'Karla', sans-serif !important;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.ui.items > .item {
  display: block !important;
}

.team-member {
  margin-bottom: 50px;
  display: inline-block;
  text-align: center;
}

.ui.items > .item:first-child {
  margin-top: auto !important;
  width: 200px;
}

.ui.items > .item:nth-child(2) {
  width: 200px;
}

.ui.items > .item:nth-child(3) {
  width: 1000px;
}

.ui.items > .item:nth-child(4) {
  width: 200px;
}

.ui.items > .item:nth-child(5) {
  width: 200px;
}

.ui.label > .icon {
  margin: 0px !important;
}

@media only screen and (max-width: 767px) {
  .ui.items > .item:first-child {
    margin-top: auto !important;
    width: auto;
  }
  .ui.items > .item:nth-child(2) {
    width: auto;
  }
  .ui.items > .item:nth-child(3) {
    width: auto;
  }
  .ui.items > .item:nth-child(4) {
    width: auto;
  }
  .ui.items > .item:nth-child(5) {
    width: auto;
  }
  .items {
    display: grid;
    grid-template-columns: 30% 30% 30%;
  }
}

@media only screen and (max-width: 600px) {
  .items {
    grid-template-columns: 50% 50%;
  }
  .team-page {
    text-align: center;
    height: 100%;
    margin: auto;
  }
}

@media only screen and (max-width: 480px) {
  .items {
    grid-template-columns: 100%;
  }
}
