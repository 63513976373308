.user-container {
  margin-bottom: 4em;
}

.user-container .user-header.title {
  font-size: 2.6em;
  font-weight: 700;
}

.user-container .sub-header {
  font-size: 1.5em;
  color: #525252;
}

.user-container .title {
  font-size: 2em;
  text-align: left;
  font-weight: 700;
  margin-bottom: .5em;
}

.title {
  margin-right: 2em;
}

.user-container .content {
  font-size: 1.3em;
  color: #000;
  margin: .5em 0 1.5em 0;
}

.user-container .faves {
  margin: 2.5em 0;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.user-container .faves .fave {
  width: 15%;
  margin-right: 4em;
}

.user-container .faves .fave img {
  width: 100%;
}

.user-container .reviews {
  margin-top: 1em;
}

.user-container .sub-header.button {
  color: #00BE6E;
}

.user-container .button {
  color: #00BE6E;
  background-color: unset;
}

.button:hover {
  text-decoration: underline;
}

.user-container .column img {
  max-width: 15em;
}

.consumerImg img {
  height: 15rem;
  width: 15rem;
  position: relative;
  top: -230px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button {
  background-color: #00BE6E;
}

.ui-button {
  cursor: pointer;
  background-color: #00BE6E;
  color: white;
  padding: 10px 20px;
  border: none;
  margin: 0;
  text-decoration: none;
}

.connect {
  position: relative;
  top: -50px;
}

/* style favorites images here */

.fave img {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* disable transform */

.react-parallax-bgimage {
  height: 400px;
  /* max-width: 70%; */
  width: 100%;
  position: absolute;
  left: 50%;
  transform-style: preserve-3d;
}

.parallax-section {
  position: relative;
}

.parallax-dropdown {
  position: absolute;
  top: 1em;
  left: 1em;
  background: #00BE6E;
  padding: 5px 10px;
  border-radius: 5%;
  letter-spacing: 0.03em;
  font-family: inherit;
}

#dropdown-text>.text {
  color: #fff;
}

#dropdown-text>i.icon {
  color: #fff;
}

/*===========================Horizontal Card Css =========================================*/

.myBusiness {
  margin-bottom: 4em;
}

.business-card {
  margin-bottom: 4em;
  text-align: left;
}

.business-card .selected {
  color: #00BE6E;
}

.business-card .business-card-container {
  border-bottom: 4px solid #d9d9d9;
  border-right: 2px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
}

.business-card .flex {
  display: flex;
  flex-direction: row;
}

.business-card .picture-container {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.business-card .picture-container .main-pic {
  width: 100%;
}

.business-card .picture-container .main-pic .pic {
  width: 100%;
  padding: .05em;
}

.business-card .sub-pics .pic {
  width: 33.3%;
  padding: .05em;
}

.business-card .business-info-container {
  padding: .5em 3em;
}

.business-card .business-info-container .row {
  margin-top: 1em;
}

.business-card .business-info-container .header-info {
  display: flex;
}

.business-card .business-info-container .header-info .name-location {
  width: 50%;
}

.business-card .business-info-container .header-info .name-location .business-location {
  padding-top: .3em;
  color: #a8a8a8;
}

.business-card .business-info-container .header-info .name-location .business-location .fa-map-marker-alt {
  margin-right: .5em;
  color: #000;
}

.business-card .business-info-container .header-info .scoring {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.business-card .business-info-container .header-info .scoring .icon {
  font-size: .65em!important;
}

.business-card .business-info-container .header-info .scoring .icon.filled {
  color: #000;
}

.business-card .business-info-container .header-info .scoring:not(:first-child) {
  font-size: 1.5em;
}

.business-card .business-info-container .subcategories {
  display: flex;
  justify-content: left;
}

.business-card .business-info-container .subcategories>div {
  padding-right: 2em;
}

.business-card .business-info-container .subcategories>div:not(.selected) {
  color: #a8a8a8;
}

.business-card .business-info-container .works-with .works-with-header {
  font-size: 1.3em;
  margin-bottom: .5em;
}

.business-card .business-info-container .works-with .favorites {
  display: flex;
  justify-content: space-between;
}

.business-card .business-info-container .works-with .favorites img {
  width: 18%;
  max-height: 4em;
}

.business-card .business-info-container .connections .facebook-social {
  width: 75%;
  margin: auto;
}

.business-card .business-info-container .connections .facebook-social .fa-facebook-square {
  color: #3b5998;
  margin-right: .4em;
  font-size: 1.4em;
  vertical-align: middle;
}

.business-card .business-info-container .connections .connect-buttons {
  width: 30%;
  display: flex;
}

.business-card .business-info-container .connections .connect-buttons .button {
  color: #fff;
  background-color: #00BE6E;
  border: 1px solid #00BE6E;
  margin-right: 0;
  border-radius: 0;
  width: 50%;
  padding: .9em 0;
}

.business-card .business-info-container .connections .connect-buttons .inverted {
  color: #00BE6E;
  background-color: #fff;
}

.profile-header .column {
  width: 43.75%;
}

.review {
  width: 45%;
  display: inline-block;
  margin-bottom: 40px;
}

@media only screen and (max-width: 999px) {
  .connect-modal-right h1{
    align-self: center;
  }
  .myBusiness {
    width: 100vw;
  }
  .user-container .sub-header {
    font-size: .95rem;
  }
  .ui[class*="equal width"].grid>.desktop.column:not(.row) {
    display: none;
  }
  .profile-header {
    width: 100%;
  }
  .profile-header .sub-header {
    text-align: right;
  }
  .user-container .user-header.title {
    line-height: 100%;
    font-size: 1.75rem;
  }
  .user-container .title {
    font-size: 1.15em;
  }
  .title {
    margin-right: 0px;
  }
  .title.favorites {
    font-size: 1.15em;
  }
  .user-container .content {
    font-size: 1.15em;
  }
  .consumerImg img {
    height: 8rem;
    width: 8rem;
  }
  .user-container .column img {
    margin-top: 100px;
  }
  .user-container .faves {
    margin: 1rem 0;
  }
  .myBusiness {
    margin-bottom: 0px;
  }
  .review {
    width: 100%;
  }
  .review-count {
    line-height: 38px;
  }
  .load-more {
    text-align: center;
  }
  #mobile-options-consumer {
    background-color: white;
    border-top: 1px solid #ebebeb;
    display: block;
    width: 100%;
    padding: 25px 20px;
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    vertical-align: middle;
  }
  #mobile-options {
    background-color: white;
    border-top: 1px solid #ebebeb;
    display: block;
    width: 100%;
    padding: 25px 20px;
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    vertical-align: middle;
  }
  .profile-page .react-parallax {
    height: 200px;
  }
  .profile-page .business-info-container {
    padding: 20px;
  }
  #mobile-options-consumer button {
    float: right;
  }
  #mobile-options button {
    float: right;
  }
}

/*===========================End Horizontal Card Css =========================================*/