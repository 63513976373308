.Contact-form {
  margin: 0 auto;
  margin-top: 90px;
  width: 800px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  border: 1px solid beige;
  padding: 50px;
  margin-bottom: 50px;
}

.Contact-container {
  height: 100vh;
}

.Contact-line {}

.Contact-input-flex {}

.Contact-radio-margin {
  margin-bottom: 5px !important;
}

#Contact-submit {
  background-color: #00BE6E;
  color: white;
}

.Contact-sent {
  color: #00BE6E;
}

.contact-email {
  width: 342px;
}

.contact-message {
  width: 80%;
}

@media only screen and (max-width: 999px) {
  .Contact-form {
    width: 100%;
    border: none;
    box-shadow: none;
    margin-top: 70px;
  }

  .contact-email {
    width: 100%;
  }

  .input {
    margin-bottom: 5px;
  }

  .contact-message {
    width: 100%;
  }

}

@media only screen and (max-width: 365px) {
  .Contact-container {
    margin-bottom: 60px;
  }
}