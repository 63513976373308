.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.view-message-modal {
  width: 1000px;
  height: 65%;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  position: fixed;
  top: 20vh;
  padding: 3em;
  left: 0;
  right: 0;
  z-index: 2;
}

.message-close-modal {
  position: absolute;
  right: 0;
  margin-right: 20px;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 2;
}

/* view message modal */

.view-message-flex {
  display: flex;
}

.view-message-left img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.view-message-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 42px;
  border-right: 1px solid #DDDDDD;
  padding-bottom: 30px;
  width: 300px;
}

.view-message-left {
  font-size: 1.2rem;
}

.view-message-left h2 {
  color: #333F48;
}

.view-message-flex-data {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.view-message-flex-data-sent {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.view-flex-data {
  display: flex;
  margin-left: 8px;
}

.view-flex-data h4 {
  margin-right: 5px;
  font-size: 1.2rem;
  color: #333F48;
}

.view-flex-data p {
  font-size: 1.2rem;
  color: #333F48;
}

.view-message-accept-pass {
  display: flex;
  align-items: baseline;
}

.view-message-accept {
  border: 1px solid #00be6e;
  color: #00be6e;
  padding: 10px 20px 10px 20px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
}

.view-message-pass {
  border-top: 1px solid #D82F2B;
  border-bottom: 1px solid #D82F2B;
  border-right: 1px solid #D82F2B;
  color: #D82F2B;
  padding: 10px 32px 10px 32px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
}

.view-message-right {
  margin-left: 25px;
  width: 70%;
}

.view-message-description p {
  font-size: 1.3rem;
  font-style: italic;
}

.view-message-description-edit textarea {
  padding-bottom: 120px;
  border: 1px solid #DDDDDD;
  font-size: 1.3rem;
  font-style: italic;
  color: #777777;
  border-radius: 10px;
}

.view-message-description {
  height: 200px;
}

.view-message-right h1 {
  font-size: 3rem;
  color: #333F48;
}

/* .__react_modal_image__modal_content img {
  width: 70%;
  height: 70%;
} */

#react-modal-image-img {
  width: 50%;
  height: 100%;
}

.view-message-attachment {
  margin-top: 10px;
  display: flex;
  overflow-x: auto;
}

.view-message-attachment img {
  max-width: none !important;
  max-height: none !important;
  width: 200px;
  height: 200px;
  margin-right: 5px;
}

.sent-save-description {
  color: #00be6e;
  cursor: pointer;
  margin-top: 15px;
}

/*
.connect-edit-urgency h4 {
  text-align: center;
  font-size: 1.2rem;
}

.connect-edit-service h4 {
  text-align: center;
  font-size: 1.2rem;
}

.connect-edit-service input {
  font-size: 1.4rem;
  padding: 0 !important;
  width: 50%;
}

.connect-edit-service{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* decline modal */

.decline-header-text p {
  font-size: 1.7rem;
}

.decline-optional-radio {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.decline-optional {
  margin-top: 20px;
  display: flex;
  align-items: baseline;
}

.decline-optional h3 {
  font-size: 1.6rem;
  color: #7A7A7A;
  margin-right: 5px;
}

.decline-optional p {
  font-size: 1.7rem;
}

.decline-optional-radio-flex {
  display: flex;
  align-items: center;
}

.decline-optional-radio-flex p {
  font-size: 1.4rem;
}

.decline-text {
  font-size: 1.3rem;
  padding: 0px;
}

.decline-send {
  margin-top: 20px;
  background-color: #D82F2B;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.decline-send p {
  color: white;
  font-size: 1.5rem;
}

.view-message-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.view-message-radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  margin-left: 0px;
}

/* On mouse-over, add a grey background color */

.view-message-radio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.view-message-radio input:checked~.checkmark {
  background-color: #00be6e;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.view-message-radio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.view-message-radio .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.view-message-radio p {
  font-size: 1.4rem;
  margin: -5px;
}

@media only screen and (max-width: 999px) {
  .view-message-description {
    height: 90px;
  }
  .view-message-right {
    margin-left: 0px;
  }
  .view-message-right {
    margin-top: 0px;
  }
  .connect-edit-urgency {
    margin-top: 10px;
  }
  .view-message-flex-data-sent {
    margin-top: 0px;
  }
  .view-message-modal {
    width: 100%;
    top: 0px;
    height: 100vh;
  }
  .view-message-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .view-message-left {
    border-right: 0px;
    padding-bottom: 10px;
  }
  .connect-request-box {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .connect-desciption {
    margin-left: 0px !important;
  }
}