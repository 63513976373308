.reviews-page-container {
  margin-top: 50px;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.reviews-header {
  margin-left: 20%;
  margin-top: 50px;
}

.reviews-tab {
  display: flex;
}

.reviews-header h4 {
  font-size: 2.2rem;
}

.review-nav-link {
  display: block;
  margin-bottom: 4%;
  color: #A2A8AC;
  margin-right: 2.5%;
}

.review-nav-link:hover {
  color: #00BE6E;
}

.review-nav-active {
  font-weight: bold;
  color: #000;
  /* font-size: 1.1em; */
}

.review-list {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.review-picture {
  width: 60px;
  height: 60px;
  margin-left: 20px;
  margin-right: 20px;
}

.review-flex-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.review-text {
  margin-bottom: 20px;
}

.review-title-date-flex {
  display: flex;
  justify-content: space-between;
}

.Edit-Reviews-flex {
  margin-top: 15px;
  display: flex;
  align-items: baseline;
}


.Edit-Reviews-flex .Edit-Reviews-Link {
  color: #00BE6E;
  margin-right: 5px;
  cursor: pointer;
}

.Edit-Reviews-flex .Delete-Reviews-Link {
  color: #D90000;
  margin-right: 5px;
  cursor: pointer;
}

.view-review {
  color: #00BE6E;
  margin-right: 5px;
  cursor: pointer;
}

.close-review {
  color: #D90000;
  margin-right: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 999px){
  .reviews-header, .review-list {
    margin-left: 1em;
    margin-right: 1em;
    justify-content: left;
  }

  .review-picture{
    width: 40px;
    height: 40px;
    margin-left: 0px;
  }

  .review-flex-right {
    width: 100%;
  }

}