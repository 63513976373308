#check-mark>path {
  color: green;
}

#xIcon>path {
  color: red;
}

#question-circle>path {
  color: #e9e9e9;
}

sub {
  font-size: 0.2em;
}

sup {
  font-size: 0.5em;
}

input {
  color: #999;
}

.icon.search.button {
  padding: 0px !important;
}

.icon.search.button i {
  font-size: .7em !important;
}

.ui.icon.button.label.search.button {
  background-color: #00BF6F !important;
}

.Pricing-Containter button.ui.button {
  background-color: #00be6e;
  color: white;
}

/* Popover styling */

a {
  text-decoration: none;
}

.popover__title {
  color: rgb(228, 68, 68);
}

.popover__wrapper {
  position: relative;
  display: inline-block;
}

.popover__wrapper>a {
  color: #a5a5a5;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  margin-top: -130px;
  transform: translate(0px, 10px);
  background-color: #f4f4f4;
  padding: 1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}

.popover__content:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 25%;
  width: 0;
  height: 0;
  border: 16px solid transparent;
  border-top-color: #f4f4f4;
  border-bottom: 0;
  margin-left: -16px;
  margin-bottom: -16px;
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(-150px, 20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
  font-family: 'Karla', sans-serif;
  font-size: 12px;
  width: 300px;
}

.subscription-price {
  margin-bottom: 40px;
  font-family: 'Karla', sans-serif;
  color: #333f48;
  font-weight: 700;
  text-align: center;
}

.subscription-Part-Time .subscription-monthly-amount:after {
  font-size: 3em;
  content: '39';
  color: #333f48;
}

.subscription-Part-Time .subscription-yearly-amount:after {
  font-size: 3em;
  content: '33.25';
  color: #333f48;
}

.subscription-Full-Time .subscription-monthly-amount:after {
  content: '79';
  font-size: 3em;
  color: #333f48;
}

.subscription-Full-Time .subscription-yearly-amount:after {
  font-size: 3em;
  content: '62.42';
  color: #333f48;
}

.subscription-Big-Time .subscription-monthly-amount:after {
  content: '99';
  font-size: 3em;
  color: #333f48;
}

.subscription-Big-Time .subscription-yearly-amount:after {
  font-size: 3em;
  content: '84.15';
  color: #333f48;
}

.BrainhubCarousel__arrows {
  border-radius: 50% !important;
  height: 50px
}

.subscription-Free-Time {
  font-size: 3em;
  color: #333f48;
  text-align: center;
  height: 87px;
  margin-bottom: 4.5%;
}

@media (max-width: 768px) {
  .subscription-Free-Time {
    height: 63px;
    margin-bottom: 0px;
  }
}

.ToggleSwitch {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
}

.ToggleSwitch.ToggleSwitch__rounded .Slider {
  border-radius: 15rem;
  background: #00bf6f;
  border: 1px solid #e7e7e7;
}

.ToggleSwitch.ToggleSwitch__rounded .Slider:before {
  border-radius: 50%;
}

.ToggleSwitch .ToggleSwitch__wrapper {
  position: relative;
  width: 3rem;
  height: 1.5rem;
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s ease;
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider:before {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  background: #fff;
  content: '';
  margin: 0;
  padding: 0;
  top: 50%;
  left: 0.1rem;
  transform: translateY(-50%);
  transition: 0.4s;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider.isChecked:before {
  left: calc(100% - 0.10rem - 1.6rem);
}

/*--------------------------------- End My Subscriptions CSS ------------------------------------- */

/*-------------------------- Start Subscription Popup Modal CSS ---------------------------------- */

.create-button {
  padding: '10px 25px';
  background-color: '#00BF6F';
  color: '#fff';
  border: 'none';
  border-radius: '3px';
  margin-top: '1em';
}

.business-sub-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "business-name business-name" "email email";
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 1em;
  width: 75%;
}

.business-name {
  grid-area: business-name;
  font-size: 16px;
}

.business-name label {
  font-size: 16px;
}

.email {
  font-size: 16px;
  grid-area: email;
  margin-top: 2%;
}

.business-sign-buttons {
  margin-top: 3%;
}