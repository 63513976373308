.tab-messages-flex {
  margin-top: 1px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 3%;
}

.Inbox-tab-messages {
  display: flex;
}

.Messages-Container {
  display: flex;
  margin-bottom: 22.3vh;
}

#mobile-card .ui.card>.content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.ui.card>.content {
  height: 777.5px;
}

.Messages-User {
  width: 300px;
  background-color: #F4F4F4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  text-align: center;
  height: 777.5px;
  border-top: 1px solid #d4d4d5;
  border-bottom: 1px solid #d4d4d5;
}

.User-viewProfile h4 {
  margin-bottom: 10px;
}

.User-viewProfile {
  margin-bottom: 10px;
}

.Messaged-PeopleList:hover {
  opacity: 0.5;
}

.Message-flex h4 {
  margin: 0;
}

.Message-flex {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.Message-flex p {
  line-height: 0px;
}

.Message-Consumer-flex h4 {
  padding-right: 3px;
}

.Message-Consumer-flex {
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.Message-horizontal-line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  margin-bottom: 45px;
  padding: 0;
}

.Message-flex-approved {
  display: flex;
  justify-content: center;
}

.Message-flex-approved h4 {
  padding-right: 3px;
  color: #00be6e;
}

.Message-flex-approved p {
  color: #00be6e;
}

.Message-flex-complete {
  display: flex;
  justify-content: center;
}

.Message-flex-complete h4 {
  padding-right: 3px;
  color: #00be6e;
}

.Message-flex-complete p {
  color: #00be6e;
}

.Message-flex-paid {
  display: flex;
  justify-content: center;
}

.Message-flex-paid h4 {
  padding-right: 3px;
  color: #00be6e;
}

.Message-flex-paid p {
  color: #00be6e;
}

.Message-flex-deny {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.Message-flex-deny h4 {
  padding-right: 3px;
  color: #D90000;
}

.Message-flex-deny p {
  color: #D90000;
}

.Message-flex-expired {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.Message-flex-expired h4 {
  padding-right: 3px;
  color: #D90000;
}

.Message-flex-expired p {
  color: #D90000;
}

.Message-flex h4 {
  padding-right: 3px;
}

.Messages-User img {
  border-radius: 100%;
  margin-bottom: 10px;
}

.ui.feed>.event>.content .user {
  margin-bottom: 10px;
  color: black;
}

.ui.feed>.event>.content .summary {
  font-weight: 0,
}

.ui.feed>.event:last-child {
  margin-bottom: 20px;
}

.ui.feed>.event>.label {
  width: 5em;
}

.date.Feed-Date {
  margin: 0;
}

.Messages-User-Messages {
  width: 600px;
  display: flex;
  flex-direction: column;
  padding: 30px 10px 10px 10px;
  height: 777.5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid #d4d4d5;
}

p.User-Own-Message {
  color: white;
  background-color: #00be6e;
  margin-left: 200px;
  padding: 15px;
  border-radius: 15px;
  margin-right: 10px;
  margin-bottom: 20px;
  float: right;
}

p.User-Recipient-Message {
  background-color: #EFEFEF;
  margin-right: 200px;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 20px;
  justify-self: flex-start;
  float: left;
}

p.Emoji-Message {
  background-color: transparent;
  border-color: transparent;
  font-size: 50px;
}

.css-y1c0xs {
  display: flex;
  flex-direction: column;
}

.Send-Quote {
  margin-top: 20px;
}

.View-Profile {
  color: #00be6e;
}

.Messages-Text-Box {
  border-top: 1px solid #F4F4F4;
  margin-top: 20px;
  margin-bottom: -40px;
}

.Quote-Form-Container {
  width: 600px;
  display: flex;
  flex-direction: column;
  height: 764px;
  border: 1px solid #F4F4F4;
  padding: 20px;
}

.Quote-Form h1, h3 {
  text-align: center;
}

h3 {
  margin-top: -1%;
}

.pending {
  font-weight: 800;
  color: #999;
}

.approved {
  font-weight: bold;
  color: #00be6e;
}

.rejected {
  font-weight: bold;
  color: red;
}

.Quote-Form {
  display: flex;
  flex-direction: column;
}

div.fields.Subtotal {
  display: flex;
  justify-content: flex-end;
  padding-right: 35px;
}

div.fields.Subtotal input {
  width: 93.25px !important;
}

div.three.wide.field.text-border {
  border-bottom: 0 none !important;
  box-shadow: none;
}

.Quote-Form p {
  color: #00be6e;
  cursor: pointer;
}

.approved-button h4 {
  margin-top: 20px;
  border: .5px solid #ECECEC;
  color: #A0A0A0;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
}

.pending h4 {
  margin-top: 20px;
  border: 2px solid #F1C847;
  color: #F1C847;
  padding: 10px 15px 10px 15px;
  font-size: 13px;
  border-radius: 5px;
}

.payment-outstanding h4 {
  margin-top: 20px;
  border: 2px solid #D81607;
  color: #D81607;
  padding: 10px 15px 10px 15px;
  font-size: 13px;
  border-radius: 5px;
}

.Send-Customer {
  margin-top: 15px;
  display: flex;
  align-items: baseline;
}

.Send-Customer h5 {
  color: #BCBCBC;
  margin-left: 5px;
  cursor: pointer;
}

.Quote-recieved p {
  color: #00be6e;
  cursor: pointer;
}

.Marked-complete p {
  color: #00be6e;
  cursor: pointer;
  align-self: flex-start;
}

.Marked-complete {
  width: 100%;
}

.Marked-complete-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30%;
}

.Quote-recieved-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30%;
}

.Quote-recieved-flex h3 {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.paid-button {
  margin-top: 20px;
  border: 1.3px solid #00be6e;
  color: #00be6e;
  background-color: #F4F4F4;
  padding: 10px 18px 10px 18px;
  cursor: pointer;
  border-radius: 5px;
}

.paid-button p {
  color: #00be6e;
  font-weight: 600;
}

.Marked-complete h4 {
  color: #A1A1A1;
  font-weight: 1px;
}

.write-review {
  color: #00be6e;
}

.Messages-Text-Box #story {
  outline: none;
  width: 100%;
  padding: 1em 1em 4em;
  border-radius: 2px;
  letter-spacing: 1px;
  line-height: 1.5em;
  font-size: 1.0rem;
  resize: none;
  border-radius: 20px;
}

.write-review-flex {
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  margin-top: 15px;
  justify-content: center;
}

.view-edit {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.view-edit h4 {
  padding-right: 3px;
  margin-bottom: 0;
  color: #C0C0C0;
  font-weight: 1000;
}

.view-edit p {
  color: #00be6e;
  font-weight: 550;
  cursor: pointer;
}

.view-business-review {
  display: flex;
  justify-content: center;
}

.view-business-review h4 {
  margin-top: 10px;
  color: #C0C0C0;
  font-weight: 1000;
}

.view-business-review p {
  color: #00be6e;
  font-weight: 550;
  cursor: pointer;
}

.messages-right {
  width: 600px;
  display: flex;
  flex-direction: column;
  height: 764px;
  border: 1px solid #F4F4F4;
  padding: 20px;
}

.view-quote-button {
  border-radius: 2px;
  padding: 10px 20px 10px 20px;
  background-color: #00be6e;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out
}

.view-quote-button:active {
  opacity: .8;
}

.view-quote-button-sandwiched:active {
  opacity: .8;
}

.view-quote-button-sandwiched {
  border-radius: 2px;
  padding: 10px 20px 10px 20px;
  background-color: #00be6e;
  cursor: pointer;
  margin-top: 3%;
  color: white;
  transition: opacity 0.1s ease-in-out
}

.view-quote-button p {
  color: white;
}

.ui.card {
  box-shadow: none;
  border-bottom: 1px solid #d4d4d5;
  border-left: 1px solid #d4d4d5;
  border-right: 1px solid #d4d4d5;
}

.True-tab-messages {
  display: flex;
  border-top: 1px solid #d4d4d5;
  border-left: 1px solid #d4d4d5;
  border-right: 1px solid #d4d4d5;
  padding: 15px 22px 15px 22px;
  align-items: baseline;
  cursor: pointer;
  z-index: 10;
  background-color: white;
}

.True-tab-messages h4 {
  margin-bottom: 0;
  font-size: 1em;
  margin-right: 10px;
  color: #00be6e;
}

.False-tab-messages {
  display: flex;
  padding: 15px 22px 15px 22px;
  border-bottom: 1px solid #d4d4d5;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
  z-index: 10;
}

.False-tab-messages h4 {
  margin-bottom: 0;
  font-size: 1em;
  margin-right: 10px;
  color: #959595;
}

.new-messages-messages {
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 1em;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 20px;
}

.new-messages-sidebar {
  position: absolute;
  top: 24px;
  right: 10px;
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 1em;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 20px;
}

.delete-report p {
  cursor: pointer;
  margin-bottom: 20px;
}

.accepted {
  color: #00BE6E;
}

.declined {
  color: red;
}

p.User-Recipient-Message {
  word-break: break-all;
}

.css-y1c0xs {
  display: flex;
  flex-direction: column;
  height: 570px !important;
  overflow-x: hidden;
}

#Quote-Items .css-y1c0xs {
  height: 300px !important;
}

.messages-scroll-details .css-y1c0xs {
  margin-top: 100px !important;
}

.animate-height {
  display: none;
}

#Quote-form button.ui.button {
  background-color: #00be6e;
  color: white;
  font-weight: 100;
  height: 40px;
}

.Modal-Items.ui.modal {
  margin-top: -100px !important;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  height: 380px;
}

.Send-Customer button.ui.button:nth-child(1) {
  background-color: #00be6e;
  color: white;
}

#Quote-Items {
  height: 300px;
  overflow: auto;
  overflow-x: hidden;
}

.ui.button.add-item {
  background-color: #00be6e;
  color: white;
  position: absolute;
  bottom: 10px;
}

.ui.button.save-items {
  background-color: #00be6e;
  color: white;
  color: white;
  position: absolute;
  bottom: 10px;
  right: 100px;
}

.ui.button.cancel-items {
  background-color: #D82F2B;
  color: white;
  color: white;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

p.delete-item {
  color: black;
  position: relative;
  align-self: center;
  margin-top: 15px;
  cursor: pointer;
}

#Quote-Items textarea {
  resize: none;
}

.Items-itemsubtotal {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  width: 93.7%;
  justify-content: space-between;
}

.Items-itemsubtotal input {
  width: 93.25px!important;
}

.Subtotal input {
  width: 93.25px!important;
}

.ui.form .fifteen.wide.field .Quote-textarea textarea {
  resize: none !important;
}

input.quote-data-picker {
  height: 38px;
  width: 208px !important;
  margin-top: 23px !important;
}

.ui.form .fifteen.wide.field.Quote-textarea {
  margin-top: 30px;
  margin-bottom: 30px;
}

.ui.form .fifteen.wide.field.Quote-textarea textarea {
  height: 120px;
  resize: none;
}

p.Messages-Date-Picker-Label {
  color: rgba(0, 0, 0, .87);
  font-size: .92857143em;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0px;
  position: relative;
  left: 8px;
}

.item-error {
  position: absolute;
  color: #D82F2B;
  bottom: 3px;
  left: 80px;
}

.Messages-Date-Picker {
  margin-top: 3.97%;
  width: 36%;
}
.delete-item-mobile{
  display: none;
}

@media only screen and (max-width: 999px) {
  .delete-item {
    display: none;
  }
  button.ui.button.delete-item-mobile {
    display: block;
    position: relative;
    top: 5px;
    left: 63vw;
    background-color: red ;
    color: white;
  }
  .Quote-Form h1 {
    margin-bottom: 50px;
  }
  .item-error {
    left: auto;
    bottom: auto;
    font-size: 16px;
  }
  div.fields.Subtotal {
    padding-right: 0px;
  }
  .Items-itemsubtotal {
    width: 100%;
  }
  .Modal-Items.ui.modal {
    top: 62%;
    height: 500px;
  }
  .Quote-Form {
    height: 100vh;
  }
  .ui.form .fifteen.wide.field.Quote-textarea textarea {
    height: 80px;
  }
  .messages-right {
    width: 100%;
    margin-bottom: 200px;
  }
  .Messages-Container {
    height: 100%;
  }
  .True-tab-messages-mobile {
    display: flex;
    border-top: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    padding: 10px 22.3px 10px 22.3px;
    align-items: baseline;
    cursor: pointer;
  }
  .True-tab-messages-mobile h4 {
    margin-bottom: 0;
    font-size: 1.2em;
    color: #00be6e;
  }
  .False-tab-messages-mobile {
    padding: 10px 22.3px 10px 22.3px;
    display: flex;
    align-items: baseline;
    background-color: #F9F9F9;
    cursor: pointer;
  }
  .False-tab-messages-mobile h4 {
    margin-bottom: 0;
    font-size: 1.2em;
    color: #777777;
  }
  .mobile-messages-topbar-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .back-inbox {
    background-color: #00be6e;
    color: white;
    padding: 0px;
    margin: 0px;
    text-align: center;
    padding: 11px;
  }
  .back-inbox a {
    color: white;
  }
  .MessagesUserContent {
    display: flex;
    flex-direction: column;
  }
  .Inbox-tab-messages-mobile {
    display: flex;
  }
  .ant-drawer-body {
    padding: 0px;
  }
  .ui.card.desktop {
    display: none;
  }
  .Messages-User-Messages {
    width: 100%;
    padding: 0px;
  }
  p.User-Recipient-Message {
    margin-right: 75px;
  }
  p.User-Own-Message {
    margin-left: 75px;
  }
  .Messages-Text-Box {
    margin-bottom: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .Messages-User-Messages {
    border: 0px;
  }
  .messages-scroll {
    padding-left: 1rem;
  }
  .mobile-messages-topbar {
    background-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }
  .mobile-messages-topbar .inbox-link {
    position: absolute;
    left: 1rem;
    margin-top: 10px;
    text-align: left;
  }
  .mobile-messages-topbar .details {
    text-align: right;
    position: absolute;
    right: 1rem;
    top: 1rem;
    margin-top: 6px;
  }
  .mobile-messages-topbar span {
    margin-left: 0px;
    width: 20%;
    display: inline-block;
  }
  .mobile-messages-topbar-img span {
    margin-left: 10px;
  }
  .mobile-messages-topbar-img span {
    display: inline-block;
    width: 30%;
  }
  .mobile-messages-topbar img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    vertical-align: middle;
  }
  .animate-height {
    display: block;
    background-color: #f7f7f7;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .mobile-messages-topbar-details {
    background-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    z-index: 10;
    height: 40%;
    position: absolute;
    width: 100%;
  }
  .mobile-messages-topbar-details .inbox-link {
    position: absolute;
    left: 1rem;
    margin-top: 10px;
    text-align: left;
  }
  .inbox-link .details {
    text-decoration: underline;
  }
  .mobile-messages-topbar-details .details {
    text-align: right;
    position: absolute;
    right: 1rem;
    top: 1rem;
    margin-top: 10px;
  }
  .mobile-messages-topbar-details span {
    margin-left: 0px;
    width: 20%;
    display: inline-block;
  }
  .mobile-messages-topbar-details-img {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
  .mobile-messages-topbar-details-img span {
    margin-left: 10px;
  }
  .mobile-messages-topbar-details-img span {
    display: inline-block;
    width: 30%;
  }
  .mobile-messages-topbar-details img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    vertical-align: middle;
  }
  .tab-messages-flex {
    margin-left: 0px;
  }
  .message-container-mobile {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) and (max-height: 830px) {
  .css-y1c0xs {
    height: 69vh !important;
  }
  .Messages-Text-Box #story {
    padding: 1em 1em 1em;
  }
  .Messages-User-Messages {
    height: 0px;
  }
}

@media only screen and (max-width: 360px) and (max-height: 640px) {
  .css-y1c0xs {
    height: 65vh !important;
  }
  .Messages-Text-Box #story {
    padding: 1em 1em 1em;
  }
}