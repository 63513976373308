.referral-container {
    height: 100vh;
    background-color: #f7f7f7;
}

.referral-container-referalls {
    padding-top: 20px;
    height: 100vh;
    background-color: #f7f7f7;
}

.ui.table.Referrals-Table {
    width: 800px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.Sent-Referrals-Earned {
    background-color: #ffffff;
    width: 288px;
    height: 76px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.ui.table.Referrals-Table tr td {
    border-top: 0px !important;
}

.Referral-Table-Cell {
    display: flex;
    align-items: center;
    position: relative;
    margin: 10px;
}

.Referral-Table-Cell i.small.icon {
    line-height: .8;
}

.Referral-Table-Cell p {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 0px;
    padding-right: 10px;
}

p.Redeem-Referral-Button {
    font-size: 14px;
    color: white;
    background-color: #00be6e;
    padding: 4px 8.5px 4px 8.5px;
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    right: 10px;
}

p.Delete-Referral-Button {
    font-size: 14px;
    color: white;
    background-color: #FF0000;
    padding: 4px 8.5px 4px 8.5px;
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    right: 10px;
}

p.Delete-Referral-Button:active {
    opacity: .6;
}

p.Redeem-Referral-Button:active {
    opacity: .8;
}

.Sent-Referrals-Text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Sent-Referrals-Line {
    border-left: 1px solid #e5e5e5;
    height: 40px;
}

.Sent-Referrals-Text h1 {
    font-size: 24px;
}

.Sent-Referrals-Text p {
    font-size: 16px;
}

.Sent-Referrals-Earned-Status-Container {
    margin-top: 30px;
    width: 800px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.Sent-Referrals {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 0px;
}

.Sent-Referrals-Earned {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 56px;
}

.Sent-Referrals-Earned-Referrals p:nth-child(1) {
    color: #333f48;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 30px;
}

.Sent-Referrals-Earned-Referrals p:nth-child(2) {
    color: #c4c4c4;
    font-size: 16px;
}

.Sent-Referrals-Earned-Earned p:nth-child(1) {
    color: #333f48;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 30px;
}

.Sent-Referrals-Earned-Earned p:nth-child(2) {
    color: #c4c4c4;
    font-size: 16px;
}

.Sent-Referrals-Status {
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 43px;
    width: 200px;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    position: relative;
}

.Sent-Referrals-Status p {
    color: black;
}

.menu.transition.visible.Sent-Referrals-Dropdown {
    border: none;
    width: 200px;
    left: -162px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.Sent-Referrals-Status p {
    font-size: 16px;
    margin-bottom: 0px;
}

.referral-content-referalls {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    margin: 0 auto;
    /* bring your own prefixes */
}

.referral-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 800px;
    top: 35%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

h1.extra-month {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 49px;
    text-align: center;
}

p.referral-text {
    margin-top: 20px;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.referral-content .ui.input>input {
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 3px;
    width: 330px;
    height: 40px;
}

.referral-content button.ui.button {
    background-color: #00be6e;
    color: white;
    width: 100px;
    height: 40px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.referral-content-referalls .ui.input>input {
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 3px;
    width: 330px;
    height: 40px;
}

.referral-content-referalls button.ui.button {
    background-color: #00be6e;
    color: white;
    width: 100px;
    height: 40px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.referral-emails {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 600px;
    justify-content: center;
}

.referral-email {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 15px;
    display: flex;
    background-color: #00be6e;
}

.referral-email p {
    margin-bottom: 0;
    margin-right: 5px;
    font-size: 15px;
    color: white;
}

.referral-emails button.ui.button {
    background-color: #00be6e;
    color: white;
    width: 100px;
    height: 40px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.ui.input.error>input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none;
}

p.referral-error-text {
    margin-top: 10px;
    font-size: 12px;
    color: red;
    width: 430px;
}

.Toastify__toast--success.Referral-Toast-Success {
    background: white !important;
    color: black;
    border-left: #00be6e 7px solid;
    width: 350px;
}

.Toastify__toast--success.Referral-Toast-Success .Toastify__close-button {
    color: #e0e0e0 !important;
}

.Toastify__toast--success.Referral-Toast-Success .Toastify__progress-bar {
    background-color: #b5ebb7 !important;
}

.table-cell-mobile {
    display: none;
}

@media only screen and (max-width: 840px) {
    .Toastify__toast--success.Referral-Toast-Success{
        width: 100%;
    }
    .Referral-Table-Cell {
        margin: 0px;
    }
    .Referral-Table-Cell p {
        display: inline;
        font-size: 14px;
    }
    .ui.table:not(.unstackable) thead {
        display: none;
    }
    .Sent-Referrals {
        padding: 50px 0px 120px 0px;
    }
    .referral-content-referalls .ui.input>input {
        width: 200px;
    }
    .ui.table thead tr:first-child>th:last-child {}
    .ui.table.Referrals-Table td {
        width: 100px !important;
    }
    .Sent-Referrals-Earned {
        margin-right: 0px;
    }
    .Sent-Referrals-Status {
        margin-top: 20px;
    }
    .Sent-Referrals-Earned-Status-Container {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .ui.table.Referrals-Table {
        width: 300px;
    }
    .Sent-Referrals-Container {
        text-align: center;
    }
    .Sent-Referrals-Text {
        width: 90%;
    }
    .referral-container {

        height: 100vh;
        background-color: #f7f7f7;
    }
    .referral-container-referalls {
        height: 100%;
        background-color: #f7f7f7;
    }
    .referral-container {
        height: 100vh;
    }
    .referral-emails {
        width: 95%;
    }
    .referral-content {
        width: 95%;
    }
    .referral-content-referalls {
        width: 95%;
    }
    h1.extra-month {
        font-size: 24px;
        line-height: 28px;
    }
    p.referral-text {
        font-size: 16px;
        line-height: 19px;
    }
    .referral-content img {
        width: 125px;
    }
}

@media only screen and (max-width: 473px) {
    .ui.basic.table tbody tr {
        border: none;
    }
    .table-cell-mobile {
        display: inline-block;
    }
    .table-cell-mobile {
        display: inline-block;
    }
    p.referral-error-text {
        font-size: 12px;
        color: red;
        width: 300px;
    }
    .referral-content .ui.input>input {
        border: 1px solid #ededed;
        box-sizing: border-box;
        border-radius: 3px;
        width: 200px;
        height: 40px;
    }
}