@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
.social-login-button {
    padding: 10px;
}

.ui.small.modal.transition.visible.active.loginModal {
    text-align: center;
}

.links {
    color: #43b02a;
    cursor: pointer;
    margin-top: 2%;
}

.links:hover {
    text-decoration: underline;
}

.forgot-password {
    margin-right: auto;
    cursor: pointer;
    font-size: 14px;
}

.forgot-password {
    text-decoration: underline;
}

.ui.secondary.menu.header {
    align-items: center;
}

.ui.button.nav-button {
    background: none;
    font-size: 15px;
    font-family: 'Karla', sans-serif;
    color: #999;
}

.ui.button.nav-button:hover {
    color: #21ba45;
    background: none;
}

.ui.menu:not(.vertical) .right.item,
.ui.menu:not(.vertical) .right.menu {
    margin-right: 1em;
}

.loginModal {
    width: 90% !important;
    position: fixed;
    top: 50vh;

}




/* -------------------------------------Begin Subscription Modal CSS ---------------------------------------------- */
.subscription-modal {
    text-align: center;
    padding: 0em 5em 6em;
}

.subscription-modal .image-container {
    width: 35%;
    margin: 0 auto;
}

.subscription-modal .subscription-type {
    margin: 2em 0;
    padding: 0 2em;
    font-size: .85em;
}
.subscription-modal .subscription-type h3 {
    font-size: 2.7em;
    
}

.subscription-modal .billing-info-container {
    margin: 4em 3em;
    padding-left: 2em;
    text-align: left;
}

.subscription-modal .billing-info-container p {
    font-size: 1.5em;
    color: #000000;
    margin-bottom: 0.75em;
}

.subscription-modal .btn {
    color: #21ba45;
    font-size: 1.5rem;
    font-weight: 100;
    padding: 0 .8em;
    border: none;
    background-color: #fff;
}

.subscription-modal .btn:focus {
    outline: none;
}

.subscription-modal .btn.cancel {
    color: red;
    font-size: 1.5em;
}



/* -------------------------------------End Subscription Modal CSS ---------------------------------------------- */
/* -------------------------------------Begin min-width 768px CSS ---------------------------------------------- */
@media (min-width: 768px) {
    .loginModal {
        width: 50rem !important;
        left: calc((100% - 50rem)/2);
    }
}
/* -------------------------------------End min-width 768px CSS ---------------------------------------------- */
.notification-drop-down {
  padding: 10px;
}

.view-notifications h2 {
  font-size: 18px;
  padding-bottom: 16px;
  cursor: pointer;
  display: inline-block;
  color: #999999;
}

.view-notifications {
  text-align: center;
}

.notification {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 15%;
  margin-right: 15%;
  height: 90vh;
  overflow-y: auto;
}

.notification-drop {
  overflow-y: auto;
  height: 50vh;
}

.notfication-hr-dropdown {
  border-width: 0.05em;
  margin-top: 1em;
  height: 1px;
}

.notfication-hr-page {
  border-width: 0.05em;
  margin-top: 2em;
  margin-bottom: 2em;
  height: 1px;
}

.notification {
  padding: 5px 40px 5px 40px;
}

.Toastify__toast--default {
  width: 500px;
  right: 200px;
}

.notification-name-text {
  display: flex;
  align-items: baseline;
}

.notification-name-text p {
  color: black;
  font-size: 14px;
  line-height: 0px;
}

.notfication-text-option {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 785px) {
  .notfication-text-option {
    width: 55vw;
  }
  .notification {
    padding: 5px 10px 5px 10px;
    margin: 0px;
    margin-bottom: 20px;
  }
  .notification-name-text {
    display: flex;
  }
  .notification-name-text p {
    line-height: 10px;
  }
}

.notification h2 {
  font-weight: 100;
  font-size: 15px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.view-message-modal {
  width: 1000px;
  height: 65%;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  position: fixed;
  top: 20vh;
  padding: 3em;
  left: 0;
  right: 0;
  z-index: 2;
}

.message-close-modal {
  position: absolute;
  right: 0;
  margin-right: 20px;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 2;
}

/* view message modal */

.view-message-flex {
  display: flex;
}

.view-message-left img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.view-message-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 42px;
  border-right: 1px solid #DDDDDD;
  padding-bottom: 30px;
  width: 300px;
}

.view-message-left {
  font-size: 1.2rem;
}

.view-message-left h2 {
  color: #333F48;
}

.view-message-flex-data {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.view-message-flex-data-sent {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.view-flex-data {
  display: flex;
  margin-left: 8px;
}

.view-flex-data h4 {
  margin-right: 5px;
  font-size: 1.2rem;
  color: #333F48;
}

.view-flex-data p {
  font-size: 1.2rem;
  color: #333F48;
}

.view-message-accept-pass {
  display: flex;
  align-items: baseline;
}

.view-message-accept {
  border: 1px solid #00be6e;
  color: #00be6e;
  padding: 10px 20px 10px 20px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
}

.view-message-pass {
  border-top: 1px solid #D82F2B;
  border-bottom: 1px solid #D82F2B;
  border-right: 1px solid #D82F2B;
  color: #D82F2B;
  padding: 10px 32px 10px 32px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
}

.view-message-right {
  margin-left: 25px;
  width: 70%;
}

.view-message-description p {
  font-size: 1.3rem;
  font-style: italic;
}

.view-message-description-edit textarea {
  padding-bottom: 120px;
  border: 1px solid #DDDDDD;
  font-size: 1.3rem;
  font-style: italic;
  color: #777777;
  border-radius: 10px;
}

.view-message-description {
  height: 200px;
}

.view-message-right h1 {
  font-size: 3rem;
  color: #333F48;
}

/* .__react_modal_image__modal_content img {
  width: 70%;
  height: 70%;
} */

#react-modal-image-img {
  width: 50%;
  height: 100%;
}

.view-message-attachment {
  margin-top: 10px;
  display: flex;
  overflow-x: auto;
}

.view-message-attachment img {
  max-width: none !important;
  max-height: none !important;
  width: 200px;
  height: 200px;
  margin-right: 5px;
}

.sent-save-description {
  color: #00be6e;
  cursor: pointer;
  margin-top: 15px;
}

/*
.connect-edit-urgency h4 {
  text-align: center;
  font-size: 1.2rem;
}

.connect-edit-service h4 {
  text-align: center;
  font-size: 1.2rem;
}

.connect-edit-service input {
  font-size: 1.4rem;
  padding: 0 !important;
  width: 50%;
}

.connect-edit-service{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* decline modal */

.decline-header-text p {
  font-size: 1.7rem;
}

.decline-optional-radio {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.decline-optional {
  margin-top: 20px;
  display: flex;
  align-items: baseline;
}

.decline-optional h3 {
  font-size: 1.6rem;
  color: #7A7A7A;
  margin-right: 5px;
}

.decline-optional p {
  font-size: 1.7rem;
}

.decline-optional-radio-flex {
  display: flex;
  align-items: center;
}

.decline-optional-radio-flex p {
  font-size: 1.4rem;
}

.decline-text {
  font-size: 1.3rem;
  padding: 0px;
}

.decline-send {
  margin-top: 20px;
  background-color: #D82F2B;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.decline-send p {
  color: white;
  font-size: 1.5rem;
}

.view-message-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.view-message-radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  margin-left: 0px;
}

/* On mouse-over, add a grey background color */

.view-message-radio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.view-message-radio input:checked~.checkmark {
  background-color: #00be6e;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.view-message-radio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.view-message-radio .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.view-message-radio p {
  font-size: 1.4rem;
  margin: -5px;
}

@media only screen and (max-width: 999px) {
  .view-message-description {
    height: 90px;
  }
  .view-message-right {
    margin-left: 0px;
  }
  .view-message-right {
    margin-top: 0px;
  }
  .connect-edit-urgency {
    margin-top: 10px;
  }
  .view-message-flex-data-sent {
    margin-top: 0px;
  }
  .view-message-modal {
    width: 100%;
    top: 0px;
    height: 100vh;
  }
  .view-message-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .view-message-left {
    border-right: 0px;
    padding-bottom: 10px;
  }
  .connect-request-box {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .connect-desciption {
    margin-left: 0px !important;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
}
button#clear-search {
  border: none;
  background: white;
  margin-right: 2em;
  color: #43b02a;
  font-weight: bold;
}

.horizontal-card-container {
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
  margin: 15px 0;
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: space-around;
}

.horizontal-card-container-mobile {
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
  margin: 15px 0;
}

p {
  font-size: 1.1em;
}

#rhiz-score {
  color: #21ba45;
  font-size: 22px;
  font-weight: inherit;
  font-family: inherit;
}

/* Business Images */

.horizontal-card-image-container {
  width: 300px;
  display: flex;
  flex-direction: column;
}

#main-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sub-image-section {
  display: flex;
  height: 30%;
}

.sub-image-section>img {
  width: 33.3%;
}

/* Business Info */

.business-name {
  padding-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  color: black;
}

#business-info-flex a {
  width: 500px;
}

.favorite-star {
  cursor: pointer;
}

.business-name:hover {
  text-decoration: underline;
}

#business-info-top {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  margin-bottom: 0.5em;
  width: 40%;
}

#business-info-flex {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  margin-bottom: 0.5em;
}

#business-info-top>span {
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
}

.business-address {
  color: #a3a3a3;
  font-weight: bold;
  cursor: pointer;
}

.business-address:hover {
  text-decoration: underline;
}

.business-tags {
  margin-bottom: 1em;
}

.business-tags>li {
  display: inline-block;
  margin-right: 2.5em;
  font-size: 1.1em;
  font-weight: bold;
}

li:first-child {
  color: #21ba45;
}

li:not(first-child) {
  color: #a3a3a3;
}

p#business-about {
  margin: 0 3em 1em 0;
}

h2#works-with {
  margin: 0 0 0.5em 0;
  font-size: 22px;
}

.works-with-images-container {
  display: flex;
}

.works-with-images-container>h4 {
  font-style: italic;
}

.works-with-image {
  width: 7em;
  height: 7em;
  margin-right: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.business-info-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5em;
}

.buttons {
  display: flex;
}

#view-profile-button {
  border: 1px solid #21ba45;
  width: 120px;
  height: 40px;
  color: #21ba45;
  background-color: white;
}

#connect-button {
  border: 1px solid #21ba45;
  width: 120px;
  height: 40px;
  color: white;
  background-color: #21ba45;
  margin-right: 20px;
}

/* icon */

#fb-icon>path {
  color: #3b5998;
}

.business-address svg {
  margin-right: 5px;
}

.mobile {
  display: none;
}

.business-info-container {
  padding: 20px;
  box-sizing: border-box;
  width: 70%;
}

@media only screen and (max-width: 999px) {
  #connect-button {
    margin-right: 0px;
  }
  .business-info-top-mobile {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    margin-bottom: 0.5em;
    width: 40%;
  }
  #business-info-top-mobile>span {
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;
  }
  .business-name, #business-info-top-mobile, h2#works-with {
    font-size: 18px;
  }
  .favorite-star, #business-info-top-mobile>span {
    float: right;
  }
  #main-image {
    height: auto;
  }
  .horizontal-card-image-container, .business-info-container {
    width: 100%;
  }
  .business-info-container {
    padding: 20px;
  }
  p#business-about {
    margin-right: 0px;
  }
  .business-name, #business-info-top, h2#works-with {
    font-size: 18px;
  }
  span {
    margin-left: 0px;
  }
  #business-info-top {
    display: inline;
  }
  .horizontal-card-container, .business-info-bottom, .buttons {
    display: block;
  }
  h1:first-child {
    font-size: 1.3rem;
  }
  #search-results {
    margin-left: 0px;
    margin-right: 0px;
  }
  .sort-tags, .sort-container, .business-tags li:first-child, .buttons {
    margin-top: 20px;
  }
  .sort-container select {
    max-width: 80%;
  }
  .sort-container {
    padding-bottom: 10px;
  }
  #view-profile-button, #connect-button {
    width: 50%;
  }
  .mobile {
    display: inline;
  }
  .mobile.business-facebook-likes {
    display: inline;
  }
  .desktop, #works-with, .works-with-images-container {
    display: none;
  }
  .favorite-star, #business-info-top>span {
    float: right;
  }
  .business-name {
    margin-left: 0px;
  }
  .listings-list {
    padding-right: 20px;
    padding-left: 20px;
  }
  .business-info-bottom {
    margin-top: 1em;
  }
  button#clear-search {
    text-align: left;
  }
}
.content, .review-body {
    font-size: 1em;
}

.content-title {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.banner-title {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.banner-category {
    font-size: 1.6em;
    text-transform: capitalize;
}

.address {
    color: #999;
}

#contact-radio input {
    cursor: pointer;
}

#contact-radio .jPykbq {
    display: flex;
    align-items: center;
}

.fb-icon {
    margin-right: 1%;
    width: 10px;
    height: 10px;
    margin-top: -0.2%;
}

.business-photo {
    height: 12rem;
    width: 12rem;
    margin-bottom: '6%';
    margin-top: '-20%';
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    left: 1em;
    margin-left: 3em;
}

.business-banner {
    height: 375px;
}

.business-map {
    width: 75%;
    margin-bottom: 5%;
}

.business-profile-left {
    width: 80%;
}

.pricepoint {
    font-size: .95rem;
}

.rhiz-score {
    margin-left: 30px;
    color: #00be6e;
}

.rhiz-score-base {
    margin-left: 0px;
}

.cDvSjc {
    font-size: 1em;
}

.desktop.business-sidebar {
    margin-right: 10%;
}

@media only screen and (max-width: 999px) {
    .content, .review-body {
        font-size: .95rem;
    }
    .content-title {
        font-size: 1.15em;
    }
    .banner-title {
        font-size: 1.75em;
        margin-top: -25px;
        margin-bottom: 5px;
    }
    .banner-category {
        font-size: 1em;
    }
    .business-map {
        width: 100%;
    }
    .business-profile-left {
        width: 100%;
        margin-top: 40px;
    }
    .business-photo {
        height: 8rem;
        width: 8rem;
        margin-top: 10px;
        margin-left: .5em;
    }
    .business-banner {
        height: 200px;
    }
    #mobile-options {
        margin-left: -5%
    }
    .workswith-image {
        width: 75px;
        height: auto;
    }
    .cDvSjc {
        font-size: .95rem;
    }
}

@media screen and (width: 320px) and (height: 568px) {
    /* .ui-button {
		width: 50px;
	} */
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
}

/* connect modal */

.connect-modal {
  width: 900px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  padding: 3em;
}

.connect-modal-left-C2C {
  padding-right: 3em;
  display: flex;
  flex-direction: column;
}

.connect-modal-left-C2C h4 {
  text-align: center;
  font-size: 1.3rem;
}

.connection-type {
  text-align: center;
  font-size: 1.1rem;
}

.connect-zip {
  margin-top: 20px;
  margin-bottom: 20px;
}

.connect-zip p {
  margin: 0;
  margin-bottom: 5px;
}

.connect-zip input {
  font-size: 1.4rem;
  padding: 0 !important;
}

.connect-modal-left h4 {
  align-self: center;
  margin-bottom: 0;
}

.connect-modal-left h3 {
  align-self: center;
  font-size: 1.2em;
  margin: 1em 0 2em;
  font-weight: normal;
  color: #999;
}

.connect-modal-right {
  padding-left: 3em;
  width: 60%;
}

/* Buttons */

#send-connect-button {
  width: 100%;
  background-color: #05BF6F;
  padding: 1em 2em;
  margin-top: 3em;
  border: none;
  border-radius: 2px;
  color: #fff;
}

#close-modal-button {
  position: absolute;
  right: 0;
  top: 0;
}

#connect-modal-form {
  display: flex;
  justify-content: center;
}

/* Labels */

label[for="story"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

label[for="start-date"] {
  margin-bottom: 0.3em;
  color: #999;
  font-size: 0.9em;
}

label[for="form-check"] {
  margin-bottom: 0.75em;
}

/* Inputs */

input[type="date"] {
  background-color: #F2F3F5;
  text-align: center;
  font-family: inherit;
  font-weight: bold;
  margin-bottom: 1em;
  color: #606060;
  padding: 0.3em 0.5em;
  border: none;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
}

input[type="radio"] {
  margin-right: 1em;
  width: auto
}

input#attachment-upload {
  display: none;
}

/* sub-radio-option-containers */

.attachment-radio-option-container {
  margin-bottom: 2em;
  display: flex;
}

.radio-option-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.radio-option-container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark-connect {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.radio-option-container:hover input~.checkmark-connect {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.radio-option-container input:checked~.checkmark-connect {
  background-color: #00be6e;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark-connect:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.radio-option-container input:checked~.checkmark-connect:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.radio-option-container .checkmark-connect:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.radio-option-container p {
  font-size: 1.1rem;
  margin: -5px;
}

/* textarea */

#story {
  border: 1px solid rgb(199, 194, 194);
  padding: 1em 1em 5em;
  border-radius: 2px;
  letter-spacing: 1px;
  line-height: 1.5em;
  margin-bottom: 0.5em;
  font-size: 1.0rem;
}

/* icons */

#attachment-icon>path {
  color: #999;
}

.attachment-container {
  display: flex;
}

/* Calendar */

::-webkit-datetime-edit-text {
  padding: 0 0.3em;
}

::-webkit-inner-spin-button {
  display: none;
}

::-webkit-calendar-picker-indicator {
  margin-right: 0.2em;
}

/* business profile pic */

#profile-pic-C2C {
  width: 150px;
  height: 150px;
  align-self: center;
  border-radius: 100px;
}

.ui.selection.dropdown.connect-mobile-urgency-dropdown {
  display: none;
}

/* media queries */

@media (max-width: 999px) {
  .connect-modal {
    width: 100vw;
    margin: 0px;
    top: 0px !important;
    height: 100vh !important;
    transform: none;
    left: 0px;
    z-index: 1000;
  }
  .connect-modal-left, .connect-modal-right {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  #connect-modal-form {
    display: block !important;
  }
  .connect-modal-left {
    border-right: 0px;
    padding-right: 0px !important;
  }
  .connect-modal-right {
    padding-left: 0px !important;
  }
  #profile-pic {
    width: 75px;
    height: 75px;
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .connect-request-business-photo {
    text-align: center;
  }
  #send-connect-button {
    margin-top: 10%;
  }
}

@media (max-height: 667px) {
  .ui.selection.dropdown.connect-mobile-urgency-dropdown {
    display: block;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    padding-left: 0px;
  }
  .ui.selection.dropdown.connect-mobile-urgency-dropdown .active.item {
    color: white;
  }
  #form-check {
    display: none;
  }
  #send-connect-button {
    margin-top: 1%;
  }
}
.user-container {
  margin-bottom: 4em;
}

.user-container .user-header.title {
  font-size: 2.6em;
  font-weight: 700;
}

.user-container .sub-header {
  font-size: 1.5em;
  color: #525252;
}

.user-container .title {
  font-size: 2em;
  text-align: left;
  font-weight: 700;
  margin-bottom: .5em;
}

.title {
  margin-right: 2em;
}

.user-container .content {
  font-size: 1.3em;
  color: #000;
  margin: .5em 0 1.5em 0;
}

.user-container .faves {
  margin: 2.5em 0;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.user-container .faves .fave {
  width: 15%;
  margin-right: 4em;
}

.user-container .faves .fave img {
  width: 100%;
}

.user-container .reviews {
  margin-top: 1em;
}

.user-container .sub-header.button {
  color: #00BE6E;
}

.user-container .button {
  color: #00BE6E;
  background-color: unset;
}

.button:hover {
  text-decoration: underline;
}

.user-container .column img {
  max-width: 15em;
}

.consumerImg img {
  height: 15rem;
  width: 15rem;
  position: relative;
  top: -230px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button {
  background-color: #00BE6E;
}

.ui-button {
  cursor: pointer;
  background-color: #00BE6E;
  color: white;
  padding: 10px 20px;
  border: none;
  margin: 0;
  text-decoration: none;
}

.connect {
  position: relative;
  top: -50px;
}

/* style favorites images here */

.fave img {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* disable transform */

.react-parallax-bgimage {
  height: 400px;
  /* max-width: 70%; */
  width: 100%;
  position: absolute;
  left: 50%;
  transform-style: preserve-3d;
}

.parallax-section {
  position: relative;
}

.parallax-dropdown {
  position: absolute;
  top: 1em;
  left: 1em;
  background: #00BE6E;
  padding: 5px 10px;
  border-radius: 5%;
  letter-spacing: 0.03em;
  font-family: inherit;
}

#dropdown-text>.text {
  color: #fff;
}

#dropdown-text>i.icon {
  color: #fff;
}

/*===========================Horizontal Card Css =========================================*/

.myBusiness {
  margin-bottom: 4em;
}

.business-card {
  margin-bottom: 4em;
  text-align: left;
}

.business-card .selected {
  color: #00BE6E;
}

.business-card .business-card-container {
  border-bottom: 4px solid #d9d9d9;
  border-right: 2px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
}

.business-card .flex {
  display: flex;
  flex-direction: row;
}

.business-card .picture-container {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.business-card .picture-container .main-pic {
  width: 100%;
}

.business-card .picture-container .main-pic .pic {
  width: 100%;
  padding: .05em;
}

.business-card .sub-pics .pic {
  width: 33.3%;
  padding: .05em;
}

.business-card .business-info-container {
  padding: .5em 3em;
}

.business-card .business-info-container .row {
  margin-top: 1em;
}

.business-card .business-info-container .header-info {
  display: flex;
}

.business-card .business-info-container .header-info .name-location {
  width: 50%;
}

.business-card .business-info-container .header-info .name-location .business-location {
  padding-top: .3em;
  color: #a8a8a8;
}

.business-card .business-info-container .header-info .name-location .business-location .fa-map-marker-alt {
  margin-right: .5em;
  color: #000;
}

.business-card .business-info-container .header-info .scoring {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.business-card .business-info-container .header-info .scoring .icon {
  font-size: .65em!important;
}

.business-card .business-info-container .header-info .scoring .icon.filled {
  color: #000;
}

.business-card .business-info-container .header-info .scoring:not(:first-child) {
  font-size: 1.5em;
}

.business-card .business-info-container .subcategories {
  display: flex;
  justify-content: left;
}

.business-card .business-info-container .subcategories>div {
  padding-right: 2em;
}

.business-card .business-info-container .subcategories>div:not(.selected) {
  color: #a8a8a8;
}

.business-card .business-info-container .works-with .works-with-header {
  font-size: 1.3em;
  margin-bottom: .5em;
}

.business-card .business-info-container .works-with .favorites {
  display: flex;
  justify-content: space-between;
}

.business-card .business-info-container .works-with .favorites img {
  width: 18%;
  max-height: 4em;
}

.business-card .business-info-container .connections .facebook-social {
  width: 75%;
  margin: auto;
}

.business-card .business-info-container .connections .facebook-social .fa-facebook-square {
  color: #3b5998;
  margin-right: .4em;
  font-size: 1.4em;
  vertical-align: middle;
}

.business-card .business-info-container .connections .connect-buttons {
  width: 30%;
  display: flex;
}

.business-card .business-info-container .connections .connect-buttons .button {
  color: #fff;
  background-color: #00BE6E;
  border: 1px solid #00BE6E;
  margin-right: 0;
  border-radius: 0;
  width: 50%;
  padding: .9em 0;
}

.business-card .business-info-container .connections .connect-buttons .inverted {
  color: #00BE6E;
  background-color: #fff;
}

.profile-header .column {
  width: 43.75%;
}

.review {
  width: 45%;
  display: inline-block;
  margin-bottom: 40px;
}

@media only screen and (max-width: 999px) {
  .connect-modal-right h1{
    align-self: center;
  }
  .myBusiness {
    width: 100vw;
  }
  .user-container .sub-header {
    font-size: .95rem;
  }
  .ui[class*="equal width"].grid>.desktop.column:not(.row) {
    display: none;
  }
  .profile-header {
    width: 100%;
  }
  .profile-header .sub-header {
    text-align: right;
  }
  .user-container .user-header.title {
    line-height: 100%;
    font-size: 1.75rem;
  }
  .user-container .title {
    font-size: 1.15em;
  }
  .title {
    margin-right: 0px;
  }
  .title.favorites {
    font-size: 1.15em;
  }
  .user-container .content {
    font-size: 1.15em;
  }
  .consumerImg img {
    height: 8rem;
    width: 8rem;
  }
  .user-container .column img {
    margin-top: 100px;
  }
  .user-container .faves {
    margin: 1rem 0;
  }
  .myBusiness {
    margin-bottom: 0px;
  }
  .review {
    width: 100%;
  }
  .review-count {
    line-height: 38px;
  }
  .load-more {
    text-align: center;
  }
  #mobile-options-consumer {
    background-color: white;
    border-top: 1px solid #ebebeb;
    display: block;
    width: 100%;
    padding: 25px 20px;
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    vertical-align: middle;
  }
  #mobile-options {
    background-color: white;
    border-top: 1px solid #ebebeb;
    display: block;
    width: 100%;
    padding: 25px 20px;
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    vertical-align: middle;
  }
  .profile-page .react-parallax {
    height: 200px;
  }
  .profile-page .business-info-container {
    padding: 20px;
  }
  #mobile-options-consumer button {
    float: right;
  }
  #mobile-options button {
    float: right;
  }
}

/*===========================End Horizontal Card Css =========================================*/
.Contact-form {
  margin: 0 auto;
  margin-top: 90px;
  width: 800px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  border: 1px solid beige;
  padding: 50px;
  margin-bottom: 50px;
}

.Contact-container {
  height: 100vh;
}

.Contact-line {}

.Contact-input-flex {}

.Contact-radio-margin {
  margin-bottom: 5px !important;
}

#Contact-submit {
  background-color: #00BE6E;
  color: white;
}

.Contact-sent {
  color: #00BE6E;
}

.contact-email {
  width: 342px;
}

.contact-message {
  width: 80%;
}

@media only screen and (max-width: 999px) {
  .Contact-form {
    width: 100%;
    border: none;
    box-shadow: none;
    margin-top: 70px;
  }

  .contact-email {
    width: 100%;
  }

  .input {
    margin-bottom: 5px;
  }

  .contact-message {
    width: 100%;
  }

}

@media only screen and (max-width: 365px) {
  .Contact-container {
    margin-bottom: 60px;
  }
}
/*========================= Begin businessDetails CSS ===============================*/
#BusinessDetails {
  margin-bottom: 5em;
}

#BusinessDetails .save-changes.button {
  background-color: #00be6e;
  padding: 1.3em 2em;
  width: 150px;
  margin-bottom: 1em;
}

#BusinessDetails .save-changes.button:hover {
  background-color: #16ab39;
}

.websiteWarning {
  color: "#F61C04";
}




/*========================= end businessDetails CSS ============================*/

/*========================= Begin edit business CSS ===============================*/


.sidebar {
  width: 20%;
  align-items: center;
  margin-right: 5%;
}

.edit-business-form {
  width: 60%;
}

.sidebar-nav-link {
  display: block;
  margin-bottom: 4%;
  color: #A2A8AC;
}

.sidebar-nav-link:hover {
  color: #00BE6E;
}

.sidebar-nav-active {
  font-weight: bold;
  color: #000;
  /* font-size: 1.1em; */

  
}

.Toastify__toast--success.Referral-Toast-Success-Edit-Changes {
  background: white !important;
  color: black;
  border-left: #00be6e 7px solid;
}

.Toastify__toast--success.Referral-Toast-Success-Edit-Changes .Toastify__close-button {
  color: #e0e0e0 !important;
}

.Toastify__toast--success.Referral-Toast-Success-Edit-Changes .Toastify__progress-bar {
  background-color: #b5ebb7 !important;
}

/*========================= end editBusiness CSS ============================*/
::-webkit-input-placeholder {
  color: #999;
}
:-ms-input-placeholder {
  color: #999;
}
::placeholder {
  color: #999;
}

input {
  color: #999;
}

#edit-business-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "business-name business-name"
    "category category"
    "sub-category sub-category"
    "subcategories subcategories"
    "about about"
    "street street"
    "city state"
    "zip zip"
  ;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 1em;
  width: 75%;
}

#business-name {
  font-size: 14px;
}

.business-name {
  grid-area: business-name;

}

.email {
  grid-area: email;
}

.state {
  grid-area: state;
}

.city {
  grid-area: city;
}

.zip-code {
  grid-area: zipCode;
}

.street {
  grid-area: street;
}

.about {
  grid-area: about;
}

.zip {
  grid-area: zip;
}

.category {
  grid-area: category;
}

#sub-category a.ui.label {
  line-height: .7;
}

#sub-category i.delete.icon {
  width: 30px;
  position: relative;
  top: 2.3px !important;
  font-size: 17px !important;
}

#sub-category .ui.selection.dropdown>.dropdown.icon {
  right: 0.5em;
}


#sub-category {
  grid-area: sub-category;
  width: 100%;
}

#sub-category .ui.fluid.multiple.search.selection.dropdown {
  border: none;
}

.subcategories {
  grid-area: subcategories;
}

input {
  color: #999;
}

.business-banner {
  width: 75%;
}

.approved-businesses, .awaiting-approval, .pending-requests {
  width: 40%;
}

.dream-team-header {
  margin-top: 50px;
}

.edit-business-form hr {
width: 75%;
}

.profile-progress-bar {
margin-top: 50px;
}

.edit-business-form {
margin-bottom: 20px;
}

.dream-member {
  margin-bottom: -1%;
}

.empty-dt {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 15px;
}

/*========================= Begin Media Queries CSS ============================*/

@media only screen and (max-width: 1000px) {

  .edit-business-form {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    min-height: 100vh;
  }

  .edit-business-form hr{
    width: 100%;
    height: auto;
  }

  #edit-business-form{
    width: 100%;
    display: block;
  }

  #edit-business-form div {
    margin-bottom: 20px;
  }

  .business-banner {
    width: 100%;
    margin-bottom: 20px;
  }

  .profile-progress-bar{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .approved-businesses, .awaiting-approval, .pending-requests {
    width: 100%;
  }

  .dream-team-header {
    margin-top: 20px;
  }

  .dream-member {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 500px) {}

/*========================= End Media Queries CSS ============================*/
/*========================= End Media Queries CSS ============================*/
#add-business>path {
  color: #DCDCDC;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

body, html {
  height: 100vh;
}

/* main profile page */

.profile-page-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin-bottom: 3%;
  margin-top: 100px;
}

/* side nav bar */

nav.side-nav-bar {
  grid-column: 1/2;
  grid-row: 1/3;
  justify-self: center;
  padding-top: 3.8em;
}

nav.side-nav-bar hr {
  width: 25%;
  margin: 7px 0;
  height: 1px;
  color: #000;
  background-color: #000;
  border: none;
}

.sidebar-nav-link {
  display: block;
  margin-bottom: 4%;
  color: #A2A8AC;
}

.sidebar-nav-link:hover {
  color: #00BE6E;
}

.sidebar-nav-active {
  font-weight: bold;
  color: #000;
  /* font-size: 1.1em; */
}

.profile-form {
  grid-column: 2/5;
  grid-row: 1/5;
  /* padding-top: 3.5em; */
}

/* form page */

label {
  display: block;
  color: #404040;
  font-weight: 500;
  margin-bottom: 0.5em;
}

button {
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.form-container {
  margin: 0 auto;
  max-width: 1000px;
}

#edit-profile-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "firstName lastName" "phone phone" "street street" "city state" "zipCode zipCode" "briefBio briefBio" "facebook facebook" "formBottom formBottom";
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 10em;
}

#change-password-title {
  font-weight: bold;
  font-size: 2rem;
}

#change-password-warn {
  margin-top: 7%;
  font-size: 15px;
  /* font-weight: bold; */
  /* text-align: center; */
}

#change-password-form {
  display: flex;
  margin-top: 2em;
  flex-wrap: wrap;
}

#change-password-form>div {
  width: 45%;
}

.first-name {
  grid-area: firstName;
}

.last-name {
  grid-area: lastName;
}

.email {
  grid-area: email;
}

.phone {
  grid-area: phone;
}

.state {
  grid-area: state;
}

.city {
  grid-area: city;
}

.zip-code {
  grid-area: zipCode;
}

.street {
  grid-area: street;
}

.brief-bio {
  grid-area: briefBio;
}

#briefBio {
  line-height: 1.5em;
}

input, textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid gray;
}

input:focus, textarea:focus {
  outline: none;
}

.ui.search.dropdown>.text {
  z-index: 1 !important;
}

input[type="text"], input[type="password"], textarea {
  padding: 1em;
}

/* Form page profile picture */

.profile-picture-container {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

#profile-picture {
  width: 100px;
  border-radius: 50%;
}

input.image-upload {
  border: none;
}

.delete-upload-buttons {
  color: #00BE6E;
  border: none;
  background-color: #fff;
  display: inline-block;
  cursor: pointer;
}

/* img upload input */

input#img-upload {
  visibility: hidden;
}

.delete-upload-container>span {
  margin: 0 5px;
}

/* Form Bottom */

.form-bottom {
  grid-area: formBottom;
}

#upload-identification {
  border: none;
  cursor: pointer;
}

#facebook-button {
  background-color: #335393;
  color: #fff;
  padding: .7em 1.5em;
  border: none;
  margin-bottom: 2.5em;
  border-radius: 3px;
}

#facebook-button .logo {
  width: 1.3em;
  margin-right: .5em;
  vertical-align: text-bottom;
}

.edit-save-buttons {
  margin-top: 3em;
}

.edit-save-buttons>button, #password-save-button {
  background-color: #00be6e;
  color: #fff;
  padding: 1em 2em;
  border-radius: 4px;
  border: none;
  margin-right: 1em;
  margin-top: 1em;
}

.edit-save-buttons>button:hover {
  background-color: #16ab39;
}

.ui.dropdown .menu .active.item {
  background: #00be6e !important;
}

.ui.search.dropdown>input.search {
  cursor: pointer !important;
}

#change-password-form>div input {
  width: 85%;
}

.content.ReferralSucess {
  padding: 100px 100px 50px 100px !important;
  text-align: center;
}

.content.ReferralSucess img {
  width: 300px;
}

.content.ReferralSucess h1 {
  font-size: 30px;
  line-height: 35px;
}

.content.ReferralSucess p {
  font-size: 17px;
  line-height: 150%;
}

.content.ReferralSucess button.ui.button {
  margin-top: 50px;
  background-color: #00BE6E;
  color: white;
  width: 200px;
  height: 50px;
  font-size: 16px;
}

@media only screen and (max-width: 999px) {
  #mobile-options-edit {
    background-color: white;
    border-top: 1px solid #ebebeb;
    display: block;
    width: 100%;
    padding: 25px 20px;
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    vertical-align: middle;
    /* margin-bottom: 60px; */
  }
  #mobile-options-edit {
    margin-left: 0;
  }
  #mobile-options-edit .active, #mobile-options .active {
    color: #00be6e;
  }
  #mobile-options-edit span:last-child, #mobile-options span:last-child {
    float: right;
  }
  #mobile-options-edit span:first-child, #mobile-options span:first-child {
    margin-right: 20px;
  }
  #edit-profile-form {
    display: block;
  }
  #edit-profile-form input {
    margin-bottom: 20px;
  }
  .profile-page-container {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 20px;
    padding-right: 20px;
    min-height: 100vh;
    margin-top: 0px;
  }
  #change-password-title {
    margin-bottom: 10%;
    margin-top: 5%;
  }
  #change-password-form>div {
    width: 100%;
    margin-bottom: 20px;
  }
  #change-password-form>div input {
    width: 100%;
  }
  #mobile-options-edit a {
    color: rgba(0, 0, 0, .87);
  }
  .profile-picture-container {
    margin-top: 20px;
  }
  .link {
    font-size: 12px;
  }
  .profile-form {
    grid-column: 1;
  }
}

/* for iphone X and pixel 2 xl */

@media screen and (width: 375px) and (height: 812px), screen and (width: 411px) and (height: 823px) {
  #mobile-options-edit {
    margin-bottom: 60px;
  }
}
* {
  font-family: 'Karla', sans-serif;
}



.title {
  text-align: center;
  /*margin-bottom: 5%; */
}

.ui.secondary.menu.header {
  margin-bottom: 0;
  height: 5rem;

}

.ui.secondary.menu .item .header-flex .ui.item.dropdown img {
  font-size: 14px;
}

.ui.secondary.menu.header .text {
  padding-right: 2rem;
}

.ui.dropdown.header.login {
  position: relative;
  top: .6rem;
  right: 4rem;
  font-size: 14px;
  color: #333f48;
}

.dropdown-header .ui.dropdown .text {
  font-weight: 400;
}

.dropdown-header a:hover {
  cursor: pointer;
}



.bell.outline.icon {
  position: relative;
  top: 1.7rem;
  right: 5rem;
}

.home-search-bar {
  padding: .3rem;
  background: rgba(255, 255, 255, .2);
  margin-top: 5rem;
  display: inline-block;
}

.ui.input.search-input-term>input {
  border-radius: 3px 0px 0px 3px;
}

.ui.input.search-input-urgency>input,
.ui.input.search-input-urgency>input+button,
.ui.input.search-input-location>input {
  border-radius: 0px;
  width: 20%
}

.ui.input.search-input-query>input::-webkit-input-placeholder {
  color: black;
}

.map.marker.alternate.icon {
  color: black;
}

.ui.icon.button.label.search.button {
  border-radius: 0px 3px 3px 0px;
  background-color: #00BF6F;
  width: 6rem;
  color: white;
}

div .ui.card .meta.bio {

  color: #43b02a;
}

h2.view-all-link {
  padding: 2rem;
  color: #43b02a;
  /* display: inline-block; */
}

div.listingsHeader {
  text-align: center
}

div.listingsHeader>* {
  display: inline-block;
  text-align: center
}

h1 {
  color: #333f48;
}

h1.homepage-title {
  color: White;
  text-align: center;
  padding-top: 20px;
}

p {
  color: #7a7a7a;
  font-size: 1.25rem;
}

img.rhiz-header-logo {
  margin-left: 4rem;
}

.home-page-section {
  padding: 100px 50px 100px 50px;
  max-width: 1500px;
  margin: 0 auto 0 auto;
}

/* .home-page-section-welcome-wrapper {

} */

.home-page-section-welcome-left {
  width: 40%;
  display: inline-block;
}

.home-page-section-welcome-left .home-page-section-welcome {
  margin: 0 0 .5em;
}


.home-page-section-welcome {
  text-align: left;
  /*font-size: 3rem;*/
  font-size: 60px;
  line-height: 1;
  font-weight: 600;
  display: inline-block;
  height: 10%;
  /*padding: 0 15rem 0 7rem; */
  color: #333f48;
}

.home-page-section-wrapper .business-pricing-cta {
  text-align: center;
}

.home-page-section-wrapper .business-pricing-cta a .button {
  margin-top: 2em;
  background-color: #00BF6F;
}

.home-page-section-wrapper .business-pricing-cta a .button:hover {
  background-color: #008c52;
}

.home-page-section-welcome-right {
  display: inline-block;
  position: absolute;
  right: 0;
}

.home-page-section-welcome-right img {
  width: 900px;
  margin-top: 50px;
}

.home-page-section-wrapper {
  margin: 0 auto 0 auto;
  justify-content: center;
}


.home-page-section-one-left {
  text-align: left;
  font-size: 60px;
  font-weight: 600;
  display: inline-block;
  width: 40%;
  height: 10%;
  /*padding: 0 15rem 0 7rem; */
  color: #333f48;
  line-height: 1;
  vertical-align: top;


  /* media queries for font size */
}

.home-page-section-one-line-break {
  border: .2rem solid #333f48;
  /*display: inline-block;*/
  margin: 2.1rem 0;
  width: 6rem;
}

.home-page-section-one-right {
  height: 10%;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.8;
  display: inline-block;
  text-align: left;
  width: 60%;
  margin: 0;
  padding-left: 6rem;

  /* media queries for font size */
}

.home-page-section-two-wrapper {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*flex-wrap: wrap;*/
  /*justify-content: center;*/
  /*padding: 0 2rem 2rem 2rem;*/
  /*text-align: center;*/
  margin-bottom: 3rem;
}

.home-page-section-two {
  display: inline-block;
  text-align: left;
  font-size: 0;
  vertical-align: top;
  width: 25%;
  margin-top: 50px;
  /*margin: 2rem;*/
}


.home-page-section-two:nth-child(1) {
  padding-right: 20px;
}

.home-page-section-two:nth-child(2) {
  padding-right: 20px;
}

.home-page-section-two:nth-child(4) {
  padding-left: 20px;
}


.home-page-section-two:nth-child(3) {
  padding-left: 20px;
}



.home-page-section-two>h1+p {
  vertical-align: middle;
  font-size: 1.25rem;
  line-height: 1.8 ;
  text-align: left;
  /*width: 18rem;*/
  font-size: 1.2rem;
}

.home-page-section-three-wrapper {
  /* height: 31rem; */
  background-color: whitesmoke;
  text-align: center;
  /* margin-bottom: 5%; */
}

/* .home-page-section-three-wrapper>div{
  /* padding: 10rem 38rem; */
/* width: 35%; */
/* }  */

.home-page-section-three-wrapper .consumer-blurb {
  margin: 3em 0;
  font-size: 1.25rem;
  line-height: 2;
}

.consumer-blurb p {
  font-size: 1.25rem;
  line-height: 1.8;
}

.home-page-section-three-wrapper>div>h1 {
  display: block;
  /* width: 30%; */
  font-size: 2rem;
  line-height: 1.3;
}

.home-page-section-three-wrapper .home-page-section-three {
  font-size: 3rem;
}

.home-page-section-three-wrapper>div button.ui.positive.button {
  /* font-size: .5rem; */
  background-color: #00BF6F;
  margin-top: 2rem;
}

.home-page-section-five-wrapper {
  /* height: 70rem; */
  /* background-color: whitesmoke; */
  text-align: center;
  /*display: inline-block;*/
  vertical-align: middle;
}

/* .home-page-section-five-header-div-center{
  /* padding: 6rem 2rem; */
/* } */
.home-page-section-five-header-div-center>h1 {
  line-height: 1;
  font-size: 3rem;
}

.home-page-section-five-header-div-center>p {
  line-height: 1;
  font-size: 1.25rem;
  line-height: 1.8;
}

.home-page-section-five-line-break {
  border: .13rem solid darkgrey;
  display: inline-block;
  width: 4rem;
}

.home-page-section-five-div-left {
  line-height: 1;
  width: 50%;
  display: inline-block;
  /*padding: 3rem 7rem;*/
  padding: 25px 50px 25px 0px;
  text-align: left;
}

.home-page-section-five-div-left>p {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #787878;
  margin: 2rem 0;
  letter-spacing: 0px;
}

.home-page-section-five-div-right {
  line-height: 1;
  width: 50%;
  display: inline-block;
  height: 30%;
  /*padding: 3rem 7rem;*/
  padding: 25px 0px 25px 50px;
  text-align: left;
  vertical-align: top;
}

.home-page-section-five-div-right>p {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #787878;
  margin: 2rem 0;
  letter-spacing: 0;
}

.intro-wrapper {
  background-color: whitesmoke;
  /* height: 120% */
}

/* .home-page-section-five-header-div-three{
  line-height: 1;
}
.home-page-section-five-header-div-four{
  line-height: 1;
} */



.home-page-section-six-wrapper {
  /*height: 31rem; */
  /*background-color: #00BF6F; */
  text-align: center;
}

/* .home-page-section-six-wrapper>div{ */
/* padding: 10rem 38rem; */
/* width: 35%; */
/* } */
.home-page-section-six-wrapper>div>h1 {
  display: block;
  /* width: 30%; */
  font-size: 2rem;
  line-height: 1.3;
  color: white;
}

.home-page-section-six-wrapper>div button.ui.huge.button {
  /* font-size: .5rem; */
  margin-top: 2rem;
  background-color: whitesmoke;
  color: black;
}

.questions-wrapper {
  line-height: normal;
  height: 40rem;
}

.home-feature {
  color: #00bf6f;
  font-size: 1.3rem;
  margin-top: 30px;
}

.home-feature-description {
  margin-top: 10px;
}

.home-feature-description p {
  line-height: 1.8;
  font-size: 1.25rem;
}

.home-page-section-three-wrapper>div button.ui.positive.button:hover {
  background-color: #008c52;
}

.home-page-section-six-wrapper>div button.ui.huge.button:hover {
  background-color: #008c52;
  color: white;
}

/*Update the search button and icon color*/
.ui.icon.button.label.search.button {
  background-color: #00BF6F;
}

#ui.icon.button.label.searchButton {
  background-color: #00BF6F;
}

i.search.icon {
  color: white;
}

.ui.button {
  font-family: 'Karla', sans-serif !important;
}

.featured-category {
  padding: 150px;
  background-color: black;
  display: inline-block;
  margin: 5px;
  background-repeat: no-repeat;
  background-size: cover;
}

.featured-categories {
  text-align: center;
}

.feature-category-wrapper {
  display: inline-block;
  margin-bottom: 20px;
}

.category-name {
  display: block;
  margin-top: 5px;
}

.featured-category#masonry {
  background-image: url("https://res.cloudinary.com/dqxnksmgd/image/upload/v1565626993/masonry.jpg");
}

.featured-category#sustainable-landscaping {
  background-image: url("https://res.cloudinary.com/dqxnksmgd/image/upload/v1565627002/landscaping.jpg");
}

.featured-category#electrical {
  background-image: url("https://res.cloudinary.com/dqxnksmgd/image/upload/v1565626990/electrical.jpg");
}

.featured-category#custom-pools {
  background-image: url("https://res.cloudinary.com/dqxnksmgd/image/upload/v1565626991/pools.jpg");
}

.home-page-section-two img {
  max-width: 75%;
}

.home-page-section-two h1 {
  margin-top: 0px;
}

.what-is-rhiz {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  padding-top: 0px;
}

.what-is-rhiz:first-child {
  text-align: center;
}


.what-is-rhiz img {
  max-width: 70%;
}

.what-is-rhiz h1 {
  font-size: 3rem !important;
}

.what-is-rhiz h2 {
  color: #bbb;
}

.what-is-rhiz p {
  line-height: 1.8;
  font-size: 1.25rem;
  max-width: 75%;
}

.what-is-rhiz button {
  background-color: #00bf6f !important;
}

.home-page-section-three-wrapper>div>div {
  display: inline-block;
  width: 60%;
  vertical-align: middle;
  padding-left: 6rem;
}

.home-page-section-three-wrapper>div>div:first-child {
  display: inline-block;
  width: 40%;
  vertical-align: middle;
  padding-left: 0;
}

.home-page-section-three-wrapper>div>div img {
  max-width: 90%;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1449px) {
  .home-page-section-welcome-right img {
    width: 100%;
  }

  .featured-category {
    padding: 125px;
  }

}

@media only screen and (max-width: 1330px) {
  .home-page-section-welcome-left {
    width: 100%;
  }

  .home-page-section-welcome {
    margin-bottom: 25px;
  }

  .home-page-section-welcome-right {
    position: static !important;
    margin-top: 50px;
  }

  .home-page-section-welcome-right img {
    width: 100%;
    margin-left: -40px;
  }

  .home-page-section-three-wrapper .home-page-section-three, .home-page-section-three-wrapper .consumer-blurb {
    padding: 0 0;
  }

  .home-page-section-wrapper, .home-page-section-welcome {
    text-align: center;
  }

  .home-page-section-welcome-right img {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1155px) {
  .featured-category {
    padding: 175px;
  }

  .featured-categories {
    max-width: 900px;
    margin: 0px auto 0px auto;
  }
}

@media only screen and (max-width: 999px) {
  .home-page-section-one-left {
    width: 100% !important;
    display: block;
    margin-bottom: 30px;
  }

  .home-page-section-one-right {
    width: 100%;
    display: block;
    padding: 0px;
  }

  .featured-categories {
    margin: 0px auto 0px auto;
  }

  .home-page-section-one-line-break, .home-page-section-two img {
    margin-left: auto;
    margin-right: auto;
  }

  .home-page-section-one-left, .home-page-section-two {
    text-align: center;
  }

  .home-page-section-three-wrapper>div>div{
    width: 100%;
  }
  .home-page-section-three-wrapper>div>div {
    padding-left: 0;
  }

}

@media only screen and (max-width: 767px) {
  h1.homepage-title{
    padding-top: 50px;
    line-height: 1;
  }
  .home-page-section-welcome, .home-page-section-one-left, .home-page-section-three-wrapper .home-page-section-three {
    font-size: 2.25rem;
    letter-spacing: 0;
    line-height: 1.15;
  }
  .home-page-section {
    padding: 50px 20px 50px 20px !important;
  }

  .home-page-section-two {
    width: 100% !important;
    display: block;
    padding: 10px 0px 10px 0px !important;
    margin-top: 25px !important;
  }

  .home-page-section-five-div-right,
  .home-page-section-five-div-left {
    width: 100% !important;
    padding: 10px 0px 10px 0px !important;
    margin-top: 25px;
    text-align: center;
  }

  .what-is-rhiz {
    padding-top: 0px !important;
  }
  .what-is-rhiz, .what-is-rhiz p {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .home-page-section-one-right, .home-page-section-two>h1+p {
    text-align: center;
  }
  .featured-category {
    padding: 150px;
  }
  .home-page-section-five-div-left h1, .home-page-section-five-div-right h1{
    font-size: 1.5rem;
  }
  .feature-category-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.Inbox-Container {
  margin: 20px 2% 20px 5%;
  width: 70%;
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
  border-radius: 20px;
}

.Inbox-Container-no-business {
  margin: 20px 2% 20px 5%;
  width: 70%;
}

.Inbox-Connect-Flex {
  display: flex;
  align-items: baseline;
}

.Inbox-tab {
  display: flex;
}

.True-tab {
  display: flex;
  border-top: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
  padding: 25px 40px 25px 40px;
  align-items: baseline;
  cursor: pointer;
  background-color: white;
}

.True-tab h4 {
  margin-bottom: 0;
  font-size: 1.5em;
  margin-right: 10px;
  color: #00be6e;
}

.True-tab-no-business {
  display: flex;
  border-top: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
  padding: 25px 40px 25px 40px;
  align-items: baseline;
  cursor: pointer;
  background-color: white;
}

.True-tab-no-business h4 {
  margin-bottom: 0;
  font-size: 1.5em;
  margin-right: 10px;
  color: #00be6e;
}

.False-tab-no-business {
  display: flex;
  padding: 25px 40px 25px 40px;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
}

.False-tab-no-business h4 {
  margin-bottom: 0;
  font-size: 1.5em;
  margin-right: 10px;
  color: #777777;
}

.False-tab {
  display: flex;
  padding: 25px 40px 25px 40px;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
}

.False-tab h4 {
  margin-bottom: 0;
  font-size: 1.5em;
  margin-right: 10px;
  color: #777777;
}

.new-messages {
  padding: 0;
  width: 28px;
  height: 28px;
  font-size: 1.5em;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 28px;
}

.Inbox-message {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #F9F9F9;
}

.Inbox-message:hover {
  opacity: .7;
}

.Inbox-message-unread {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  width: 100%;
}

.Inbox-message-unread:hover {
  opacity: .7;
}

.inbox-profile-picture {
  object-fit: contain;
  height: 5em;
  width: 5em;
  border-radius: 100%;
  margin-left: 20px;
  margin-right: 20px;
}

.inbox-business-picture {
  width: 80px;
  margin-left: 20px;
  margin-right: 20px;
}

.name-date-flex {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: baseline;
}

.name-date-flex h4 {
  text-overflow: ellipsis;
  color: #777777;
  font-size: 1.6em;
  overflow: hidden;
}

.name-date-flex p {
  font-size: 1.3em;
}

.name-message-flex {
  width: 100%;
}

.text-number-flex {
  display: flex;
  justify-content: space-between;
  width: 89%;
  align-items: baseline;
}

.text-number-flex h4 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #777777;
  font-size: 1.3em;
  width: 100%;
  overflow: hidden;
}

.message-list-container {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.connect-requests {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.connect-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  box-sizing: border-box;
}

.Connect-number-flex {
  display: flex;
  margin-bottom: 25px;
  align-items: baseline;
  margin-left: 10px;
}

.Connect-Tab {
  margin-bottom: 0;
  font-size: 1.5em;
  margin-right: 15px;
  color: #00be6e;
}

.connect-request-box {
  border: 1px solid #DDDDDD;
  width: 350px;
  padding: 0px 10px 0px 15px;
  margin-bottom: 20px;
  margin-top: 10px;
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
  border-radius: 20px;
  margin-left: 10px;
}

.connect-img-header-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.connect-img-header-flex img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.connect-name-time-type-flex {
  display: flex;
  flex-direction: column;
}

.connect-name-time-flex {
  display: flex;
}

p .connect-time-ago {
  float: right;
}

.connect-name-time-flex p {
  margin: 0;
  font-size: 14px;
}

.connect-flex-data {
  display: flex;
  margin-left: 8px;
  align-items: center;
}

.connect-flex-data h4 {
  margin-right: 5px;
  color: #777777;
  margin-bottom: 0px;
}

.connect-desciption h4 {
  margin-top: 20px;
  color: #777777;
}

.connect-desciption {
  overflow: hidden;
  height: 130px;
  line-height: 25px;
  margin-left: 8px;
}

.connect-desciption:before {
  content: "";
  float: left;
  width: 5px;
  height: 200px;
}

.connect-desciption>*:first-child {
  float: right;
  width: 100%;
  margin-left: -5px;
}

.connect-desciption:after {
  content: "\02026";
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  float: right;
  position: relative;
  top: -25px;
  left: 100%;
  width: 3em;
  margin-left: -3em;
  padding-right: 5px;
  text-align: right;
  background-size: 100% 100%;
}

.connect-attachment {
  margin-left: 8px;
}

.connect-attachment {
  margin-top: 20px;
  display: flex;
}

.connect-clip {
  margin-right: 5px;
}

.connect-vertical-line {
  border-left: 1px solid #DDDDDD;
}

.connect-view-decline {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #DDDDDD;
  display: flex;
  justify-content: space-evenly;
}

.connect-view-decline h4 {
  color: #00be6e;
  cursor: pointer;
}

.connect-view-decline p {
  cursor: pointer;
  color: #D82F2B;
}

.connect-type p {
  color: #00be6e;
}

.pending-outgoing-declined {
  display: flex;
  margin-left: 10px;
}

h4.True-connect {
  margin: 0;
  font-size: 1em;
  color: #00be6e;
}

.True-connect {
  display: flex;
  border: 1px solid #DDDDDD;
  padding: 10px 20px 10px 20px;
  align-items: baseline;
  cursor: pointer;
  background-color: white;
}

h4.True-connect-no-business {
  margin: 0;
  font-size: 1em;
  color: #00be6e;
}

.True-connect-no-business {
  display: flex;
  border: 1px solid #DDDDDD;
  padding: 10px 20px 10px 20px;
  align-items: baseline;
  cursor: pointer;
  background-color: white;
}

.False-connect {
  display: flex;
  padding: 10px 20px 10px 20px;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
}

h4.False-connect {
  margin: 0;
  font-size: 1em;
  color: #777777;
}

h4.False-connect {
  margin: 0;
  font-size: 1em;
  color: #777777;
}

.False-connect-no-business {
  display: flex;
  padding: 10px 20px 10px 20px;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
}

h4.True-connect-no-business {
  margin: 0;
  font-size: 1.5em;
  color: #00be6e;
}

.True-connect-no-business {
  display: flex;
  border: 1px solid #DDDDDD;
  padding: 10px 33px 10px 33px;
  align-items: baseline;
  cursor: pointer;
  background-color: white;
}

.False-connect-no-business {
  display: flex;
  padding: 15px 33px 10px 33px;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
}

h4.False-connect-no-business {
  margin: 0;
  font-size: 1.5em;
  color: #777777;
}

.no-message-business {
  margin: auto auto;
}

.no-connect-requests-text {
  height: 80%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.no-connect-requests-text p {
  width: 40%;
  text-align: center;
}

@media only screen and (max-width: 999px) {
  .no-message-business {
    padding: 10px 10px 0px 10px;
  }
  .pending-outgoing-declined {
    margin-left: 0px;
  }
  .True-connect, .False-connect {
    width: 34%;
    text-align: center;
  }
  .True-connect-no-business, .False-connect-no-business {
    width: 50%;
    text-align: center;
  }
  .Inbox-Container {
    border-radius: 0px;
    box-shadow: none;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .Inbox-Container {
    width: 100%;
  }
  .name-date-flex p {
    font-size: 1rem;
    position: absolute;
    right: 1rem;
  }
  .True-tab, .False-tab {
    padding: 10px;
    width: 34%;
    justify-content: center;
  }
  .True-tab-no-business, .False-tab-no-business {
    padding: 10px;
    width: 50%;
    justify-content: center;
  }
  .Inbox-tab {
    justify-content: center;
    width: 100vw;
  }
  h4.True-connect {
    justify-content: center;
  }
  h4.False-connect {
    justify-content: center;
  }
  h4.False-connect-no-business {
    justify-content: center;
  }
  h4.True-connect-no-business {
    justify-content: center;
  }
  .True-tab h4, .False-tab h4 {
    font-size: .95rem;
  }
  .True-tab-no-business h4, .False-tab-no-business h4 {
    font-size: .95rem;
  }
  h4.True-connect-no-business, h4.False-connect-no-business {
    font-size: .95rem;
  }
  .name-date-flex {
    width: 75%;
  }
  .text-number-flex {
    width: 90%;
    margin-right: 1rem;
  }
  .inbox-profile-picture {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 50px;
    height: 50px;
  }
  .text-number-flex h4 {
    font-size: 1em;
  }
  .new-messages {
    width: 20px;
    height: 20px;
    font-size: 1rem;
    margin-left: 10px;
  }
  .text-number-flex h4 {
    width: 80%;
  }
  .connect-request-box {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .view-message-left {
    padding-right: 0px;
  }
  .no-connect-requests-text {
    height: 80vh;
    width: 100%;
  }
}

@media only screen and (max-width: 785px) {
  .connect-requests {
    height: 100%;
  }
  .message-list-container {
    overflow: hidden;
    height: 100%;
  }
  .text-number-flex {
    display: flex;
    justify-content: none;
  }
  .name-date-flex h4 {
    text-overflow: ellipsis;
    color: #777777;
    font-size: 1.2em;
    overflow: hidden;
  }
  .name-date-flex {
    display: flex;
  }
  .Inbox-Container {
    margin: 0;
  }
}
.learning-page-container {
    margin: 0;
    padding: 0;
    background-color: #f7f7f7;
}

.learning-page-top {
    margin: auto;
    padding: 5% 0;
    width: 50%;
    text-align: center;
}

.learning-page-title {
    font-size: 48px;
}

.learning-page-text {
    margin: auto;
    width: 75%;
    font-size: 18px;
}

.learning-page-mid {
    margin: auto;
    width: 60%;
    padding-bottom: 5%;
}

.learning-page-section {
    display: flex;
    margin: 0;
    padding: 5% 0;
    margin-top: 20px;
}
.learning-page-section:nth-child(6) .learning-page-section-wrapper-right .BrainhubCarousel_container .BrainhubCarousel {
    display: none;
}

.learning-page-section-wrapper-left {
    /* margin-top: 5%; */
    width: 20%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.landing-page-image-wrapper {
    margin-right: 10%;
    margin-top: 1%;
}

.icon-img {
    width: auto;
    height: auto;
}

.learning-page-section-title {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.learning-page-section-title h3 {
    font-size: 24px;
    text-align: left;
    margin: 0;
}

.title-b {
    margin-bottom: 10%;
}

.line-box {
    width: 100%;
}

.title {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
}

.brand {
    width: 100%;
}

.line-container {
    width: 12%;
    margin: 5% auto;
}

.line-mob {
    height: 1px;
    width: 100%;
    border: 2px solid black;
}

.learning-page-section-wrapper-right {
    width: 90%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#card-name {
    font-size: 18px;
}

#card-desc {
    font-size: 14px;
}

.ui.card:first-child {
    margin-top: 0;
}

.ui.card {
    height: 275px;
    width: 250px;
    margin: 0 1% 1% 1%;
    border: none;
}

.ui.card:last-child {
    margin-bottom: 1%;
}

.ui.card>.extra, .ui.card>.card>.extra {
    border-top: none !important;
    /* padding: .75em 1em !important; */
}

#card-desc-link {
    color: #00BF6F;
    cursor: pointer;
    font-size: 14px;
}

.learning-page-section-text {
    padding: 0 5% 5% 5%;
}

.learning-page-section-text h5 {
    font-size: 18px;
}

.learning-page-section-text p {
    font-size: 16px;
}

.learning-page-bot {
    background-color: #00BF6F;
}

.learning-page-bot-wrapper {
    width: 40%;
    margin: 0 auto;
    padding: 10% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.learning-page-bot-wrapper h2 {
    color: white;
    font-size: 36px;
    padding-bottom: 5%;
    width: 75%;
    margin: auto;
}

.learning-page-bot-wrapper p {
    color: white;
    padding-bottom: 5%;
    font-size: 18px;
}

.learning-page-bot-button {
    width: 50%;
    margin: 0 auto;
    text-align: center;
}

#botBtn {
    background-color: white;
    color: #00BF6F;
    font-size: 18px;
}

@media only screen and (max-width: 1449px) {
    .learning-page-top {
        width: 75%;
    }
    
    .learning-page-mid {
        width: 90%;
    }
}

@media only screen and (max-width: 1155px) {
    .learning-page-mid {
        width: 80%;
    }

    .learning-page-section-text {
        padding: 0 5%;
    }
}

@media only screen and (max-width: 999px) {
    .learning-page-mid {
        width: 95%;
    }

    .learning-page-section-wrapper-right {
        width: 80%;
    }
}

@media only screen and (max-width: 767px) {
    .learning-page-top {
        width: 80%;
    }

    .learning-page-text {
        width: 100%;
    }

    .learning-page-section-title h3 {
        text-align: center;
        width: 100%;
    }

    .line-box {
        width: 80%;
    }

    .line {
        width: 50%;
        margin: 0 auto;
    }

    .branding {
        display: flex;
        flex-direction: row;
        width: 80%;
    }

    .branding h3 {
        width: 90%;
    }

    .learning-page-mid {
        width: 90%;
    }

    .learning-page-section {
        flex-direction: column;
    }

    .learning-page-section-wrapper-left {
        width: 60%;
        justify-content: center;
        margin: 5% auto;
        align-items: flex-start;
        margin-bottom: 15%;
    }

    .landing-page-image-wrapper {
        margin-right: 5%;
    }

    .learning-page-section-title {
        width: 50%;
    }

    .uniform {
        width: 75%;
    }

    .brand {
        width: 90%;
    }

    .learning-page-section-title h3 {
        letter-spacing: 1px;
        text-align: left;
    }

    .title-b {
        width: 80%;
    }

    .title-b h3 {
        margin: 0;
    }

    .learning-page-section-text h5 {
        text-align: center;
    }

    .learning-page-section-text p {
        text-align: center;
    }
}

@media (max-width: 766px) {
    .learning-page-title h1 {
        font-weight: bold;
        font-size: 35px;
        margin: 40px;
    }

    .learning-page-mid {
        width: 95%;
    }

    .learning-page-section-wrapper-left {
        margin-bottom: 20%;
    }

    .landing-page-image-wrapper {
        margin-top: 0;
    }
    
    

    .learning-page-section:nth-child(1) .landing-page-image-wrapper {
        margin-right: 10%;
    }

    .learning-page-section:nth-child(2) .landing-page-image-wrapper {
        margin-right: 0%;
    }

    .learning-page-section:nth-child(3) .landing-page-image-wrapper {
        margin-right: 0%;
    }

    .learning-page-section:nth-child(5) .landing-page-image-wrapper {
        margin-right: 8%;
        margin-bottom: 2%;
    }

    .learning-page-section:nth-child(6) .landing-page-image-wrapper {
        margin-right: 0%;
    }

    #desktop {
        margin-top: .5% !important;
        margin-right: 5% !important;
    }

    .line-box {
        width: 60%;
        margin: auto;
    }

    .learning-page-section-wrapper-right {
        width: 100%;
    }

    .ui.card:first-child {
        margin-top: 1% auto;
    }

    .ui.card {
        height: 300px;
        margin: 1% auto;
    }

    .ui.card:last-child {
        margin-bottom: 1% auto;
    }

    .ui.card>.content {
        padding: 10%;
    }

    .learning-page-bot-wrapper {
        width: 90%;
        margin: 0 auto;
        padding: 10% 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    
    .learning-page-bot-wrapper h2 {
        color: white;
        font-size: 2rem;
        padding-bottom: 5%;
        width: 85%;
    }
    
    .learning-page-bot-wrapper p {
        color: white;
        padding-bottom: 5%;
    }
    
    .learning-page-bot-button {
        width: 50%;
        margin: 0 auto;
        text-align: center;
    }
    
    #botBtn {
        background-color: white;
        color: #00BF6F;
        font-size: 1.25rem;
    }

    .uniform {
        width: 82%;
    }

    .brand {
        width: 100%;
    }
    
}

@media (max-width: 500px) {
    .learning-page-mid {
        width: 100%;
    }

    .learning-page-section-wrapper-left {
        width: 70%;
    }

    .title-b {
        width: 100%;
    }

    .branding {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .line-box {
        width: 80%;
    }

    .line {
        width: 40%;
        text-align: center;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .b {
        width: 25%;
    }
}

@media (max-width: 412px) {
    .learning-page-section-wrapper-left {
        width: 90%;
        margin: auto;
        margin-bottom: 40%;
    }

    .mobile {
        margin-bottom: 20%;
    }

    .brand {
        width: 100%;
    }
}
.tab-messages-flex {
  margin-top: 1px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 3%;
}

.Inbox-tab-messages {
  display: flex;
}

.Messages-Container {
  display: flex;
  margin-bottom: 22.3vh;
}

#mobile-card .ui.card>.content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.ui.card>.content {
  height: 777.5px;
}

.Messages-User {
  width: 300px;
  background-color: #F4F4F4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  text-align: center;
  height: 777.5px;
  border-top: 1px solid #d4d4d5;
  border-bottom: 1px solid #d4d4d5;
}

.User-viewProfile h4 {
  margin-bottom: 10px;
}

.User-viewProfile {
  margin-bottom: 10px;
}

.Messaged-PeopleList:hover {
  opacity: 0.5;
}

.Message-flex h4 {
  margin: 0;
}

.Message-flex {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.Message-flex p {
  line-height: 0px;
}

.Message-Consumer-flex h4 {
  padding-right: 3px;
}

.Message-Consumer-flex {
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.Message-horizontal-line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  margin-bottom: 45px;
  padding: 0;
}

.Message-flex-approved {
  display: flex;
  justify-content: center;
}

.Message-flex-approved h4 {
  padding-right: 3px;
  color: #00be6e;
}

.Message-flex-approved p {
  color: #00be6e;
}

.Message-flex-complete {
  display: flex;
  justify-content: center;
}

.Message-flex-complete h4 {
  padding-right: 3px;
  color: #00be6e;
}

.Message-flex-complete p {
  color: #00be6e;
}

.Message-flex-paid {
  display: flex;
  justify-content: center;
}

.Message-flex-paid h4 {
  padding-right: 3px;
  color: #00be6e;
}

.Message-flex-paid p {
  color: #00be6e;
}

.Message-flex-deny {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.Message-flex-deny h4 {
  padding-right: 3px;
  color: #D90000;
}

.Message-flex-deny p {
  color: #D90000;
}

.Message-flex-expired {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.Message-flex-expired h4 {
  padding-right: 3px;
  color: #D90000;
}

.Message-flex-expired p {
  color: #D90000;
}

.Message-flex h4 {
  padding-right: 3px;
}

.Messages-User img {
  border-radius: 100%;
  margin-bottom: 10px;
}

.ui.feed>.event>.content .user {
  margin-bottom: 10px;
  color: black;
}

.ui.feed>.event>.content .summary {
  font-weight: 0,
}

.ui.feed>.event:last-child {
  margin-bottom: 20px;
}

.ui.feed>.event>.label {
  width: 5em;
}

.date.Feed-Date {
  margin: 0;
}

.Messages-User-Messages {
  width: 600px;
  display: flex;
  flex-direction: column;
  padding: 30px 10px 10px 10px;
  height: 777.5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid #d4d4d5;
}

p.User-Own-Message {
  color: white;
  background-color: #00be6e;
  margin-left: 200px;
  padding: 15px;
  border-radius: 15px;
  margin-right: 10px;
  margin-bottom: 20px;
  float: right;
}

p.User-Recipient-Message {
  background-color: #EFEFEF;
  margin-right: 200px;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 20px;
  justify-self: flex-start;
  float: left;
}

p.Emoji-Message {
  background-color: transparent;
  border-color: transparent;
  font-size: 50px;
}

.css-y1c0xs {
  display: flex;
  flex-direction: column;
}

.Send-Quote {
  margin-top: 20px;
}

.View-Profile {
  color: #00be6e;
}

.Messages-Text-Box {
  border-top: 1px solid #F4F4F4;
  margin-top: 20px;
  margin-bottom: -40px;
}

.Quote-Form-Container {
  width: 600px;
  display: flex;
  flex-direction: column;
  height: 764px;
  border: 1px solid #F4F4F4;
  padding: 20px;
}

.Quote-Form h1, h3 {
  text-align: center;
}

h3 {
  margin-top: -1%;
}

.pending {
  font-weight: 800;
  color: #999;
}

.approved {
  font-weight: bold;
  color: #00be6e;
}

.rejected {
  font-weight: bold;
  color: red;
}

.Quote-Form {
  display: flex;
  flex-direction: column;
}

div.fields.Subtotal {
  display: flex;
  justify-content: flex-end;
  padding-right: 35px;
}

div.fields.Subtotal input {
  width: 93.25px !important;
}

div.three.wide.field.text-border {
  border-bottom: 0 none !important;
  box-shadow: none;
}

.Quote-Form p {
  color: #00be6e;
  cursor: pointer;
}

.approved-button h4 {
  margin-top: 20px;
  border: .5px solid #ECECEC;
  color: #A0A0A0;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
}

.pending h4 {
  margin-top: 20px;
  border: 2px solid #F1C847;
  color: #F1C847;
  padding: 10px 15px 10px 15px;
  font-size: 13px;
  border-radius: 5px;
}

.payment-outstanding h4 {
  margin-top: 20px;
  border: 2px solid #D81607;
  color: #D81607;
  padding: 10px 15px 10px 15px;
  font-size: 13px;
  border-radius: 5px;
}

.Send-Customer {
  margin-top: 15px;
  display: flex;
  align-items: baseline;
}

.Send-Customer h5 {
  color: #BCBCBC;
  margin-left: 5px;
  cursor: pointer;
}

.Quote-recieved p {
  color: #00be6e;
  cursor: pointer;
}

.Marked-complete p {
  color: #00be6e;
  cursor: pointer;
  align-self: flex-start;
}

.Marked-complete {
  width: 100%;
}

.Marked-complete-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30%;
}

.Quote-recieved-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30%;
}

.Quote-recieved-flex h3 {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.paid-button {
  margin-top: 20px;
  border: 1.3px solid #00be6e;
  color: #00be6e;
  background-color: #F4F4F4;
  padding: 10px 18px 10px 18px;
  cursor: pointer;
  border-radius: 5px;
}

.paid-button p {
  color: #00be6e;
  font-weight: 600;
}

.Marked-complete h4 {
  color: #A1A1A1;
  font-weight: 1px;
}

.write-review {
  color: #00be6e;
}

.Messages-Text-Box #story {
  outline: none;
  width: 100%;
  padding: 1em 1em 4em;
  border-radius: 2px;
  letter-spacing: 1px;
  line-height: 1.5em;
  font-size: 1.0rem;
  resize: none;
  border-radius: 20px;
}

.write-review-flex {
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  margin-top: 15px;
  justify-content: center;
}

.view-edit {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.view-edit h4 {
  padding-right: 3px;
  margin-bottom: 0;
  color: #C0C0C0;
  font-weight: 1000;
}

.view-edit p {
  color: #00be6e;
  font-weight: 550;
  cursor: pointer;
}

.view-business-review {
  display: flex;
  justify-content: center;
}

.view-business-review h4 {
  margin-top: 10px;
  color: #C0C0C0;
  font-weight: 1000;
}

.view-business-review p {
  color: #00be6e;
  font-weight: 550;
  cursor: pointer;
}

.messages-right {
  width: 600px;
  display: flex;
  flex-direction: column;
  height: 764px;
  border: 1px solid #F4F4F4;
  padding: 20px;
}

.view-quote-button {
  border-radius: 2px;
  padding: 10px 20px 10px 20px;
  background-color: #00be6e;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out
}

.view-quote-button:active {
  opacity: .8;
}

.view-quote-button-sandwiched:active {
  opacity: .8;
}

.view-quote-button-sandwiched {
  border-radius: 2px;
  padding: 10px 20px 10px 20px;
  background-color: #00be6e;
  cursor: pointer;
  margin-top: 3%;
  color: white;
  transition: opacity 0.1s ease-in-out
}

.view-quote-button p {
  color: white;
}

.ui.card {
  box-shadow: none;
  border-bottom: 1px solid #d4d4d5;
  border-left: 1px solid #d4d4d5;
  border-right: 1px solid #d4d4d5;
}

.True-tab-messages {
  display: flex;
  border-top: 1px solid #d4d4d5;
  border-left: 1px solid #d4d4d5;
  border-right: 1px solid #d4d4d5;
  padding: 15px 22px 15px 22px;
  align-items: baseline;
  cursor: pointer;
  z-index: 10;
  background-color: white;
}

.True-tab-messages h4 {
  margin-bottom: 0;
  font-size: 1em;
  margin-right: 10px;
  color: #00be6e;
}

.False-tab-messages {
  display: flex;
  padding: 15px 22px 15px 22px;
  border-bottom: 1px solid #d4d4d5;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
  z-index: 10;
}

.False-tab-messages h4 {
  margin-bottom: 0;
  font-size: 1em;
  margin-right: 10px;
  color: #959595;
}

.new-messages-messages {
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 1em;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 20px;
}

.new-messages-sidebar {
  position: absolute;
  top: 24px;
  right: 10px;
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 1em;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 20px;
}

.delete-report p {
  cursor: pointer;
  margin-bottom: 20px;
}

.accepted {
  color: #00BE6E;
}

.declined {
  color: red;
}

p.User-Recipient-Message {
  word-break: break-all;
}

.css-y1c0xs {
  display: flex;
  flex-direction: column;
  height: 570px !important;
  overflow-x: hidden;
}

#Quote-Items .css-y1c0xs {
  height: 300px !important;
}

.messages-scroll-details .css-y1c0xs {
  margin-top: 100px !important;
}

.animate-height {
  display: none;
}

#Quote-form button.ui.button {
  background-color: #00be6e;
  color: white;
  font-weight: 100;
  height: 40px;
}

.Modal-Items.ui.modal {
  margin-top: -100px !important;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  height: 380px;
}

.Send-Customer button.ui.button:nth-child(1) {
  background-color: #00be6e;
  color: white;
}

#Quote-Items {
  height: 300px;
  overflow: auto;
  overflow-x: hidden;
}

.ui.button.add-item {
  background-color: #00be6e;
  color: white;
  position: absolute;
  bottom: 10px;
}

.ui.button.save-items {
  background-color: #00be6e;
  color: white;
  color: white;
  position: absolute;
  bottom: 10px;
  right: 100px;
}

.ui.button.cancel-items {
  background-color: #D82F2B;
  color: white;
  color: white;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

p.delete-item {
  color: black;
  position: relative;
  align-self: center;
  margin-top: 15px;
  cursor: pointer;
}

#Quote-Items textarea {
  resize: none;
}

.Items-itemsubtotal {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  width: 93.7%;
  justify-content: space-between;
}

.Items-itemsubtotal input {
  width: 93.25px!important;
}

.Subtotal input {
  width: 93.25px!important;
}

.ui.form .fifteen.wide.field .Quote-textarea textarea {
  resize: none !important;
}

input.quote-data-picker {
  height: 38px;
  width: 208px !important;
  margin-top: 23px !important;
}

.ui.form .fifteen.wide.field.Quote-textarea {
  margin-top: 30px;
  margin-bottom: 30px;
}

.ui.form .fifteen.wide.field.Quote-textarea textarea {
  height: 120px;
  resize: none;
}

p.Messages-Date-Picker-Label {
  color: rgba(0, 0, 0, .87);
  font-size: .92857143em;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0px;
  position: relative;
  left: 8px;
}

.item-error {
  position: absolute;
  color: #D82F2B;
  bottom: 3px;
  left: 80px;
}

.Messages-Date-Picker {
  margin-top: 3.97%;
  width: 36%;
}
.delete-item-mobile{
  display: none;
}

@media only screen and (max-width: 999px) {
  .delete-item {
    display: none;
  }
  button.ui.button.delete-item-mobile {
    display: block;
    position: relative;
    top: 5px;
    left: 63vw;
    background-color: red ;
    color: white;
  }
  .Quote-Form h1 {
    margin-bottom: 50px;
  }
  .item-error {
    left: auto;
    bottom: auto;
    font-size: 16px;
  }
  div.fields.Subtotal {
    padding-right: 0px;
  }
  .Items-itemsubtotal {
    width: 100%;
  }
  .Modal-Items.ui.modal {
    top: 62%;
    height: 500px;
  }
  .Quote-Form {
    height: 100vh;
  }
  .ui.form .fifteen.wide.field.Quote-textarea textarea {
    height: 80px;
  }
  .messages-right {
    width: 100%;
    margin-bottom: 200px;
  }
  .Messages-Container {
    height: 100%;
  }
  .True-tab-messages-mobile {
    display: flex;
    border-top: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    padding: 10px 22.3px 10px 22.3px;
    align-items: baseline;
    cursor: pointer;
  }
  .True-tab-messages-mobile h4 {
    margin-bottom: 0;
    font-size: 1.2em;
    color: #00be6e;
  }
  .False-tab-messages-mobile {
    padding: 10px 22.3px 10px 22.3px;
    display: flex;
    align-items: baseline;
    background-color: #F9F9F9;
    cursor: pointer;
  }
  .False-tab-messages-mobile h4 {
    margin-bottom: 0;
    font-size: 1.2em;
    color: #777777;
  }
  .mobile-messages-topbar-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .back-inbox {
    background-color: #00be6e;
    color: white;
    padding: 0px;
    margin: 0px;
    text-align: center;
    padding: 11px;
  }
  .back-inbox a {
    color: white;
  }
  .MessagesUserContent {
    display: flex;
    flex-direction: column;
  }
  .Inbox-tab-messages-mobile {
    display: flex;
  }
  .ant-drawer-body {
    padding: 0px;
  }
  .ui.card.desktop {
    display: none;
  }
  .Messages-User-Messages {
    width: 100%;
    padding: 0px;
  }
  p.User-Recipient-Message {
    margin-right: 75px;
  }
  p.User-Own-Message {
    margin-left: 75px;
  }
  .Messages-Text-Box {
    margin-bottom: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .Messages-User-Messages {
    border: 0px;
  }
  .messages-scroll {
    padding-left: 1rem;
  }
  .mobile-messages-topbar {
    background-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }
  .mobile-messages-topbar .inbox-link {
    position: absolute;
    left: 1rem;
    margin-top: 10px;
    text-align: left;
  }
  .mobile-messages-topbar .details {
    text-align: right;
    position: absolute;
    right: 1rem;
    top: 1rem;
    margin-top: 6px;
  }
  .mobile-messages-topbar span {
    margin-left: 0px;
    width: 20%;
    display: inline-block;
  }
  .mobile-messages-topbar-img span {
    margin-left: 10px;
  }
  .mobile-messages-topbar-img span {
    display: inline-block;
    width: 30%;
  }
  .mobile-messages-topbar img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    vertical-align: middle;
  }
  .animate-height {
    display: block;
    background-color: #f7f7f7;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .mobile-messages-topbar-details {
    background-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    z-index: 10;
    height: 40%;
    position: absolute;
    width: 100%;
  }
  .mobile-messages-topbar-details .inbox-link {
    position: absolute;
    left: 1rem;
    margin-top: 10px;
    text-align: left;
  }
  .inbox-link .details {
    text-decoration: underline;
  }
  .mobile-messages-topbar-details .details {
    text-align: right;
    position: absolute;
    right: 1rem;
    top: 1rem;
    margin-top: 10px;
  }
  .mobile-messages-topbar-details span {
    margin-left: 0px;
    width: 20%;
    display: inline-block;
  }
  .mobile-messages-topbar-details-img {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
  .mobile-messages-topbar-details-img span {
    margin-left: 10px;
  }
  .mobile-messages-topbar-details-img span {
    display: inline-block;
    width: 30%;
  }
  .mobile-messages-topbar-details img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    vertical-align: middle;
  }
  .tab-messages-flex {
    margin-left: 0px;
  }
  .message-container-mobile {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) and (max-height: 830px) {
  .css-y1c0xs {
    height: 69vh !important;
  }
  .Messages-Text-Box #story {
    padding: 1em 1em 1em;
  }
  .Messages-User-Messages {
    height: 0px;
  }
}

@media only screen and (max-width: 360px) and (max-height: 640px) {
  .css-y1c0xs {
    height: 65vh !important;
  }
  .Messages-Text-Box #story {
    padding: 1em 1em 1em;
  }
}
.Review-Form h1 {
  text-align: center;
}

.Review-Form {
  display: flex;
  flex-direction: column;
}


.recommend-choice {
  margin-top: 15px;

}

.recommend-choice h5 {
  color: #8E8E8E;

}

.recommend-choice-flex {
  display: flex;
}

.recommend-button-false {
  padding: 10px 25px 10px 25px;
  border: 1px solid #BCBCBC;
  cursor: pointer;
}

.recommend-button-true {
  padding: 10px 25px 10px 25px;
  background-color: #00be6e;
  cursor: pointer;
}

.recommend-button-true p {
  color: white;
}

.review-type {
  margin-top: 20px;
}

.review-type h5 {
  color: #8E8E8E;
}

.Review-Form-back {
  color: #00be6e;
}

.Review-Submit {
  display: inline-block;
  padding: 10px 35px 10px 35px;
  background-color: #00be6e;
  cursor: pointer;
  border-radius: 3px;
}

.Edit-Review {
  display: inline-block;
  padding: 10px 25px 10px 25px;
  background-color: #00be6e;
  cursor: pointer;
  border-radius: 3px;
  color: white;
  margin-right: 10px;
}

.review-error-text{
  text-align: center;
  color:red;
}

.Review-Submit p {
  color: white;
}

.star-flex {
  display: flex;
}

.star-flex h5 {
  margin-right: 8px;
  color: #8E8E8E;
}

.Edit-Review-flex {
  margin-top: 15px;
  display: flex;
  align-items: baseline;
}

.Edit-Review-flex h5 {
  color: #D90000;
  margin-left: 5px;
  cursor: pointer;
}
.reviews-page-container {
  margin-top: 50px;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.reviews-header {
  margin-left: 20%;
  margin-top: 50px;
}

.reviews-tab {
  display: flex;
}

.reviews-header h4 {
  font-size: 2.2rem;
}

.review-nav-link {
  display: block;
  margin-bottom: 4%;
  color: #A2A8AC;
  margin-right: 2.5%;
}

.review-nav-link:hover {
  color: #00BE6E;
}

.review-nav-active {
  font-weight: bold;
  color: #000;
  /* font-size: 1.1em; */
}

.review-list {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.review-picture {
  width: 60px;
  height: 60px;
  margin-left: 20px;
  margin-right: 20px;
}

.review-flex-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.review-text {
  margin-bottom: 20px;
}

.review-title-date-flex {
  display: flex;
  justify-content: space-between;
}

.Edit-Reviews-flex {
  margin-top: 15px;
  display: flex;
  align-items: baseline;
}


.Edit-Reviews-flex .Edit-Reviews-Link {
  color: #00BE6E;
  margin-right: 5px;
  cursor: pointer;
}

.Edit-Reviews-flex .Delete-Reviews-Link {
  color: #D90000;
  margin-right: 5px;
  cursor: pointer;
}

.view-review {
  color: #00BE6E;
  margin-right: 5px;
  cursor: pointer;
}

.close-review {
  color: #D90000;
  margin-right: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 999px){
  .reviews-header, .review-list {
    margin-left: 1em;
    margin-right: 1em;
    justify-content: left;
  }

  .review-picture{
    width: 40px;
    height: 40px;
    margin-left: 0px;
  }

  .review-flex-right {
    width: 100%;
  }

}
.personal-favorites-container {
  margin-bottom: 5%;

}

.personal-favorites-header {
  padding-top: 30px;
  padding-bottom: 60px;
  margin-bottom: 70px;
  background-color: #F8F9FA;
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personal-favorites-header img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
}

.personal-favorites-header h5 {
  margin-top: 0px;
  font-style: italic;
}

.listings-list {
  margin-bottom: 50px;
}

@media only screen and (max-width: 450px) {
  .no-favorites img {
    width: 200px;
    object-fit: fill;
  }

}


.no-favorites img {
  width: 200px;
}

.no-favorites {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  height: 50vh;
}


.no-favorites p {
  margin-top: 20px;
  font-size: 20px;
}

.personal-favorites-results {
  margin: 0px 21% 0px 21%;
}

@media only screen and (max-width: 999px) {
  .personal-favorites-results {
    margin: 0px;
  }

  .personal-favorites-header,
  .no-favorites {
    text-align: center;
  }

  .no-favorites img {
    width: 100px;
  }

  /* .personal-favorites-header {
    padding-top:
  } */
}
.sort-container select {
  padding: 10px 20px;
  background-color: #F1F3F5;
  color: #696969;
  font-weight: bold;
}

.sort-tags {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 640px) {
  .listings-list {
    margin: 0 auto;
    max-width: 1600px;
  }



  .sort-tag-main-container {
    display: flex;
    justify-content: space-between;
    padding: 2.5em 0;
  }

  .tag-container h1 {
    font-family: inherit;
  }

  .tag {
    display: inline-block;
  }

  .tag-section {
    border: none;
    border-radius: 12px;
    padding: 5px 12px;
    margin-right: 15px;
    font-size: 12px;
    font-weight: bold;
    background-color: #333F48;
    color: #fff;
    text-transform: uppercase;
  }
}

.sort-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#x-icon>path {
  color: white;
}

.no-listing img {
  width: 500px;
}

.no-listing {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.no-listing h2 {
  font-size: 30px;
  margin-bottom: 30px;
}

.no-listing p {
  font-size: 20px;
}

.tag-container,
.sort-container {
  padding-left: 20px;
  padding-right: 20px;
}
.referral-container {
    height: 100vh;
    background-color: #f7f7f7;
}

.referral-container-referalls {
    padding-top: 20px;
    height: 100vh;
    background-color: #f7f7f7;
}

.ui.table.Referrals-Table {
    width: 800px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.Sent-Referrals-Earned {
    background-color: #ffffff;
    width: 288px;
    height: 76px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.ui.table.Referrals-Table tr td {
    border-top: 0px !important;
}

.Referral-Table-Cell {
    display: flex;
    align-items: center;
    position: relative;
    margin: 10px;
}

.Referral-Table-Cell i.small.icon {
    line-height: .8;
}

.Referral-Table-Cell p {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 0px;
    padding-right: 10px;
}

p.Redeem-Referral-Button {
    font-size: 14px;
    color: white;
    background-color: #00be6e;
    padding: 4px 8.5px 4px 8.5px;
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    right: 10px;
}

p.Delete-Referral-Button {
    font-size: 14px;
    color: white;
    background-color: #FF0000;
    padding: 4px 8.5px 4px 8.5px;
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    right: 10px;
}

p.Delete-Referral-Button:active {
    opacity: .6;
}

p.Redeem-Referral-Button:active {
    opacity: .8;
}

.Sent-Referrals-Text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Sent-Referrals-Line {
    border-left: 1px solid #e5e5e5;
    height: 40px;
}

.Sent-Referrals-Text h1 {
    font-size: 24px;
}

.Sent-Referrals-Text p {
    font-size: 16px;
}

.Sent-Referrals-Earned-Status-Container {
    margin-top: 30px;
    width: 800px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.Sent-Referrals {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 0px;
}

.Sent-Referrals-Earned {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 56px;
}

.Sent-Referrals-Earned-Referrals p:nth-child(1) {
    color: #333f48;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 30px;
}

.Sent-Referrals-Earned-Referrals p:nth-child(2) {
    color: #c4c4c4;
    font-size: 16px;
}

.Sent-Referrals-Earned-Earned p:nth-child(1) {
    color: #333f48;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 30px;
}

.Sent-Referrals-Earned-Earned p:nth-child(2) {
    color: #c4c4c4;
    font-size: 16px;
}

.Sent-Referrals-Status {
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 43px;
    width: 200px;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    position: relative;
}

.Sent-Referrals-Status p {
    color: black;
}

.menu.transition.visible.Sent-Referrals-Dropdown {
    border: none;
    width: 200px;
    left: -162px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.Sent-Referrals-Status p {
    font-size: 16px;
    margin-bottom: 0px;
}

.referral-content-referalls {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    margin: 0 auto;
    /* bring your own prefixes */
}

.referral-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 800px;
    top: 35%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

h1.extra-month {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 49px;
    text-align: center;
}

p.referral-text {
    margin-top: 20px;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.referral-content .ui.input>input {
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 3px;
    width: 330px;
    height: 40px;
}

.referral-content button.ui.button {
    background-color: #00be6e;
    color: white;
    width: 100px;
    height: 40px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.referral-content-referalls .ui.input>input {
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 3px;
    width: 330px;
    height: 40px;
}

.referral-content-referalls button.ui.button {
    background-color: #00be6e;
    color: white;
    width: 100px;
    height: 40px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.referral-emails {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 600px;
    justify-content: center;
}

.referral-email {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 15px;
    display: flex;
    background-color: #00be6e;
}

.referral-email p {
    margin-bottom: 0;
    margin-right: 5px;
    font-size: 15px;
    color: white;
}

.referral-emails button.ui.button {
    background-color: #00be6e;
    color: white;
    width: 100px;
    height: 40px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.ui.input.error>input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none;
}

p.referral-error-text {
    margin-top: 10px;
    font-size: 12px;
    color: red;
    width: 430px;
}

.Toastify__toast--success.Referral-Toast-Success {
    background: white !important;
    color: black;
    border-left: #00be6e 7px solid;
    width: 350px;
}

.Toastify__toast--success.Referral-Toast-Success .Toastify__close-button {
    color: #e0e0e0 !important;
}

.Toastify__toast--success.Referral-Toast-Success .Toastify__progress-bar {
    background-color: #b5ebb7 !important;
}

.table-cell-mobile {
    display: none;
}

@media only screen and (max-width: 840px) {
    .Toastify__toast--success.Referral-Toast-Success{
        width: 100%;
    }
    .Referral-Table-Cell {
        margin: 0px;
    }
    .Referral-Table-Cell p {
        display: inline;
        font-size: 14px;
    }
    .ui.table:not(.unstackable) thead {
        display: none;
    }
    .Sent-Referrals {
        padding: 50px 0px 120px 0px;
    }
    .referral-content-referalls .ui.input>input {
        width: 200px;
    }
    .ui.table thead tr:first-child>th:last-child {}
    .ui.table.Referrals-Table td {
        width: 100px !important;
    }
    .Sent-Referrals-Earned {
        margin-right: 0px;
    }
    .Sent-Referrals-Status {
        margin-top: 20px;
    }
    .Sent-Referrals-Earned-Status-Container {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .ui.table.Referrals-Table {
        width: 300px;
    }
    .Sent-Referrals-Container {
        text-align: center;
    }
    .Sent-Referrals-Text {
        width: 90%;
    }
    .referral-container {

        height: 100vh;
        background-color: #f7f7f7;
    }
    .referral-container-referalls {
        height: 100%;
        background-color: #f7f7f7;
    }
    .referral-container {
        height: 100vh;
    }
    .referral-emails {
        width: 95%;
    }
    .referral-content {
        width: 95%;
    }
    .referral-content-referalls {
        width: 95%;
    }
    h1.extra-month {
        font-size: 24px;
        line-height: 28px;
    }
    p.referral-text {
        font-size: 16px;
        line-height: 19px;
    }
    .referral-content img {
        width: 125px;
    }
}

@media only screen and (max-width: 473px) {
    .ui.basic.table tbody tr {
        border: none;
    }
    .table-cell-mobile {
        display: inline-block;
    }
    .table-cell-mobile {
        display: inline-block;
    }
    p.referral-error-text {
        font-size: 12px;
        color: red;
        width: 300px;
    }
    .referral-content .ui.input>input {
        border: 1px solid #ededed;
        box-sizing: border-box;
        border-radius: 3px;
        width: 200px;
        height: 40px;
    }
}
#check-mark>path {
  color: green;
}

#xIcon>path {
  color: red;
}

#question-circle>path {
  color: #e9e9e9;
}

sub {
  font-size: 0.2em;
}

sup {
  font-size: 0.5em;
}

input {
  color: #999;
}

.icon.search.button {
  padding: 0px !important;
}

.icon.search.button i {
  font-size: .7em !important;
}

.ui.icon.button.label.search.button {
  background-color: #00BF6F !important;
}

.Pricing-Containter button.ui.button {
  background-color: #00be6e;
  color: white;
}

/* Popover styling */

a {
  text-decoration: none;
}

.popover__title {
  color: rgb(228, 68, 68);
}

.popover__wrapper {
  position: relative;
  display: inline-block;
}

.popover__wrapper>a {
  color: #a5a5a5;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  margin-top: -130px;
  transform: translate(0px, 10px);
  background-color: #f4f4f4;
  padding: 1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}

.popover__content:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 25%;
  width: 0;
  height: 0;
  border: 16px solid transparent;
  border-top-color: #f4f4f4;
  border-bottom: 0;
  margin-left: -16px;
  margin-bottom: -16px;
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(-150px, 20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
  font-family: 'Karla', sans-serif;
  font-size: 12px;
  width: 300px;
}

.subscription-price {
  margin-bottom: 40px;
  font-family: 'Karla', sans-serif;
  color: #333f48;
  font-weight: 700;
  text-align: center;
}

.subscription-Part-Time .subscription-monthly-amount:after {
  font-size: 3em;
  content: '39';
  color: #333f48;
}

.subscription-Part-Time .subscription-yearly-amount:after {
  font-size: 3em;
  content: '33.25';
  color: #333f48;
}

.subscription-Full-Time .subscription-monthly-amount:after {
  content: '79';
  font-size: 3em;
  color: #333f48;
}

.subscription-Full-Time .subscription-yearly-amount:after {
  font-size: 3em;
  content: '62.42';
  color: #333f48;
}

.subscription-Big-Time .subscription-monthly-amount:after {
  content: '99';
  font-size: 3em;
  color: #333f48;
}

.subscription-Big-Time .subscription-yearly-amount:after {
  font-size: 3em;
  content: '84.15';
  color: #333f48;
}

.BrainhubCarousel__arrows {
  border-radius: 50% !important;
  height: 50px
}

.subscription-Free-Time {
  font-size: 3em;
  color: #333f48;
  text-align: center;
  height: 87px;
  margin-bottom: 4.5%;
}

@media (max-width: 768px) {
  .subscription-Free-Time {
    height: 63px;
    margin-bottom: 0px;
  }
}

.ToggleSwitch {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
}

.ToggleSwitch.ToggleSwitch__rounded .Slider {
  border-radius: 15rem;
  background: #00bf6f;
  border: 1px solid #e7e7e7;
}

.ToggleSwitch.ToggleSwitch__rounded .Slider:before {
  border-radius: 50%;
}

.ToggleSwitch .ToggleSwitch__wrapper {
  position: relative;
  width: 3rem;
  height: 1.5rem;
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s ease;
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider:before {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  background: #fff;
  content: '';
  margin: 0;
  padding: 0;
  top: 50%;
  left: 0.1rem;
  transform: translateY(-50%);
  transition: 0.4s;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider.isChecked:before {
  left: calc(100% - 0.10rem - 1.6rem);
}

/*--------------------------------- End My Subscriptions CSS ------------------------------------- */

/*-------------------------- Start Subscription Popup Modal CSS ---------------------------------- */

.create-button {
  padding: '10px 25px';
  background-color: '#00BF6F';
  color: '#fff';
  border: 'none';
  border-radius: '3px';
  margin-top: '1em';
}

.business-sub-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "business-name business-name" "email email";
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 1em;
  width: 75%;
}

.business-name {
  grid-area: business-name;
  font-size: 16px;
}

.business-name label {
  font-size: 16px;
}

.email {
  font-size: 16px;
  grid-area: email;
  margin-top: 2%;
}

.business-sign-buttons {
  margin-top: 3%;
}
.ui.label {
  background-color: white !important;
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.team-page {
  margin: 50px 0px 100px 0px;
  margin-top: 70px;
  min-height: 100vh;
  max-height: 100%;
}

.headshot {
  border-radius: 50%;
  height: 10em;
  width: 10em;
  border: 3px solid #00bf6f;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
}

.team-member-position {
  color: #7a7a7a;
}

h2,
p {
  font-family: 'Karla', sans-serif !important;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.ui.items > .item {
  display: block !important;
}

.team-member {
  margin-bottom: 50px;
  display: inline-block;
  text-align: center;
}

.ui.items > .item:first-child {
  margin-top: auto !important;
  width: 200px;
}

.ui.items > .item:nth-child(2) {
  width: 200px;
}

.ui.items > .item:nth-child(3) {
  width: 1000px;
}

.ui.items > .item:nth-child(4) {
  width: 200px;
}

.ui.items > .item:nth-child(5) {
  width: 200px;
}

.ui.label > .icon {
  margin: 0px !important;
}

@media only screen and (max-width: 767px) {
  .ui.items > .item:first-child {
    margin-top: auto !important;
    width: auto;
  }
  .ui.items > .item:nth-child(2) {
    width: auto;
  }
  .ui.items > .item:nth-child(3) {
    width: auto;
  }
  .ui.items > .item:nth-child(4) {
    width: auto;
  }
  .ui.items > .item:nth-child(5) {
    width: auto;
  }
  .items {
    display: grid;
    grid-template-columns: 30% 30% 30%;
  }
}

@media only screen and (max-width: 600px) {
  .items {
    grid-template-columns: 50% 50%;
  }
  .team-page {
    text-align: center;
    height: 100%;
    margin: auto;
  }
}

@media only screen and (max-width: 480px) {
  .items {
    grid-template-columns: 100%;
  }
}

/* Karla font */
html {
  box-sizing: border-box;
}

body, h1 {
  font-family: 'Karla', sans-serif !important;
}

*, *:before, *:after {
  box-sizing: inherit;
}


.rhiz-green {
  background-color: #00BE6E;
}

.rhiz-green h1 {
  color: white;
}

.react-parallax {
  color: white;
}

.sign.icon {
  opacity: .2;
}

.coverImage {
  max-width: 100%;
  height: auto;
}

.ui.link.list .item, .ui.link.list .item a:not(.ui), .ui.link.list a.item, h4.ui.header {
  color: #fff !important;
}

.ui.link.list .item a:not(.ui):hover, .ui.link.list a.item:hover {
  color: #3ebcb2 !important;
}

.ui.item.image {
  height: '50px';
  width: '50px'
}

.ui.link.list {
  font-size: 12px;
  line-height: 1.8;
}

.ui.secondary.menu.header {
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
}

a.email {
  color: #fff;
}

a.email:hover {
  color: #3ebcb2;
}

.card-icons {
  margin-top: 5%;
  margin-bottom: 5%;
}

.ui.secondary.menu {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.ui.menu .right.menu .dropdown:last-child .menu {
  left: auto;
  right: -90px !important;
}

.ui.secondary.menu .active.item {
  background: none !important;
}

.chevron {
  color: #333f48;
}

/* .rhiz-header-logo {
  height: 38px;
  width: 70px;
  margin-bottom: -10px;
} */

.ui.secondary.menu .active.item .item:hover {
  opacity: .7 !important;
}

.bio {
  margin-bottom: 5%;
  font-family: 'Karla', sans-serif;
}

.businessCard {
  font-family: 'Karla', sans-serif;
}

#header-flex {
  margin-top: 8px;
  display: flex;
  margin-right: 1.5em;
  /* flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 50px; */
}

.ui.secondary.menu .dropdown.item>.menu {
  margin-top: 47px !important;
}

#bell-svg .ui.item.dropdown.dream-team-dropdown svg {
  margin-top: 30px;
  margin-right: 10px;
  position: absolute;
}

#bell-svg-count-true .ui.item.dropdown.dream-team-dropdown svg {
  margin-top: 30px;
  margin-right: 10px;
  position: absolute;
}

#leaf-svg img {
  margin-top: 30px;
  position: absolute;
  width: 35px;
}

#leaf-svg-count-true img {
  margin-top: 30px;
  position: absolute;
  width: 35px;
  /* margin-left: -8px; */
}

#envelope-svg {
  margin-right: 35px;
  margin-left: 5px;
}

.safari-icon {
  margin-top: -15px;
}

.ui.item.dropdown.dream-team-dropdown {
  margin-left: -25px !important;
}

.dream-team-dropdown:hover {
  background: inherit !important;
}

.dream-team-dropdown-true:hover {
  background: inherit !important;
}

.dropdown.dream-team-dropdown {
  padding: 0px !important;
}

.ui.item.dropdown.dream-team-dropdown-false {
  margin-left: -20px !important;
}

.dream-team-dropdown-false:hover {
  background: inherit !important;
}

.dream-team-dropdown-false-true:hover {
  background: inherit !important;
}

.dropdown.dream-team-dropdown-false {
  padding: 0px !important;
}

.header-login {
  margin-top: 5px !important;
}

.ui.small.middle.aligned.image.rhiz-header-logo-R {
  height: 50px;
  width: 50px;
  margin-left: 0px;
}

.my-business-button {
  background-color: white !important;
  margin-bottom: 21px !important;
  padding-top: 30% !important;
  height: 80% !important;
}

.connect-ig-business {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.connect-ig-business h1 {
  margin: 0;
  font-size: 16px;
}

.loader-height {
  height: 100vh;
}

.connect-ig-business img {
  margin-right: 8px;
  width: 37px;
}

.icon-count-message {
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 15px;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  top: 3px;
  left: 35px;
}

.icon-count-bell {
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 15px;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  top: 3px;
  right: 54px;
}

.icon-count-leaf {
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 15px;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  top: 3px;
  left: 0px;
}

.connect-success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connect-success h3 {
  color: #969696;
  margin-top: 40px;
  margin-bottom: 50px;
  font-weight: normal;
}

.connect-success h1 {
  font-size: 40px;
}

.connect-success-buttons {
  display: flex;
  align-items: center;
  width: 350px;
  justify-content: space-between;
}

button.connect-success-button-message {
  color: #565B60;
  font-size: 20px;
  font-weight: 600;
}

button.connect-success-button-close {
  font-size: 20px;
  background-color: #00BE6E;
  color: white;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  border-color: transparent;
  cursor: pointer;
}

p.connect-success-button-close:hover {
  opacity: .8;
}

button.connect-success-button-close:active {
  opacity: .8;
}

@media only screen and (min-width: 785px) {
  .ui.small.middle.aligned.image.rhiz-header-logo-R {
    display: none;
  }
  .dropdown-header-mobile {
    display: none;
  }
  .Social-Links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .Social-Links img {
    height: auto;
    width: 50px;
    cursor: pointer;
    margin-right: 15px;
  }
  .my-business-button-false {
    background-color: white !important;
    margin-bottom: 21px !important;
    padding-top: 30% !important;
  }
}

#signup-button {
  background-color: #00bf6f;
  color: white;
  padding: 15px 20px;
  margin-bottom: 30px;
  margin-right: 100px;
}

#signup-button:hover {
  background-color: #008c52;
}

.ui.secondary.header.menu .ui.button.nav-button, .ui.secondary.header.menu .ui.button {
  color: #333f48;
}

/* .ui.secondary.header.menu {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 1000;
  top: 0;
} */

@media only screen and (max-width: 785px) {

  .mobile-top-padding{
    padding-top: 70px;
  }

  button.ui.button.my-business-button-false {}
  .icon-count-message{
    left: 22px;
  }
  .icon-count-leaf{
    left: -17px;
  }
  .header.ui.menu:not(.vertical) .right.menu {
    margin: 0 auto !important;
  }
  #header-flex {
    margin-top: 8px;
    display: flex;
    margin-right: 18vw;
  }
  #envelope-svg {
    margin-left: 18px;
  }
  .connect-success {
    height: 100vh;
    top: 15vh;
    position: relative;
  }
  .connect-success-buttons {
    display: flex;
    align-items: center;
    width: 85vw;
    justify-content: space-between;
  }
  p.connect-success-button-message {
    font-size: 17px;
    font-weight: 600;
  }
  p.connect-success-button-close {
    font-size: 17px;
    padding: 10px 15px 10px 15px;
  }
  .ui.menu .right.menu .dropdown:last-child .menu {
    position: fixed;
    top: 1.5%;
    left: 0px !important;
    right: 0px !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 92vh !important;
    /* bring your own prefixes */
  }
  #signup-button {
    background-color: #00bf6f;
    color: white;
    padding: 15px 20px;
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .my-business-button-false {
    background-color: white !important;
    margin-bottom: 0px !important;
    padding-top: 30% !important;
  }
  .ui.secondary.menu.header {
    width: 100vw;
    position: fixed;
    z-index: 10;
    background-color: white;
  }
  .dropdown-header {
    display: none;
  }
  .ui.item.dropdown.dream-team-dropdown-false {
    display: none !important;
  }
  .dropdown-leaf {
    display: none;
  }
  .dropdown.dream-team-dropdown {
    padding: -0 !important;
  }
  .rhiz-header-logo {
    display: none !important;
  }
  .my-business-button {
    display: none !important;
  }
  .my-business-button-false {
    margin-top: 5px !important;
    padding: 0 !important;
  }
  .ui.item.dropdown.header.login {
    right: -1vw;
    position: absolute;
  }
  .Social-Links img {
    height: auto;
    width: 30px;
    cursor: pointer;
    margin-right: 5px;
  }
  .Social-Links {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .nav-button.ui.button {
    padding: 0px 10px;
  }
  .ui.secondary.header.menu .item {
    padding-left: 0px;
  }
  .ui.secondary.menu.header .text {
    padding-right: 1rem;
  }
  .dropdown-header-mobile .item {
    padding-left: 15px !important;
  }
  .dropdown-header-mobile .text {
    padding-right: 0px;
  }
}

.tooltip {
  position: relative;
}

.tooltip-trigger {
  display: inline-block;
  text-decoration: underline;
}

.tooltip-bubble {
  min-width: 120px;
  max-width: 210px;
  position: absolute;
  z-index: -10;
}

.tooltip-bubble::after {
  content: '';
  position: absolute;
}

.tooltip-top {
  bottom: 70%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);
}

.tooltip-top::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid rgba(0, 0, 0, .7);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);
}

.tooltip-bottom ::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid rgba(0, 0, 0, .7);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);
}

.tooltip-left::after {
  border-left: 9px solid rgba(0, 0, 0, .7);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);
}

.tooltip-right::after {
  border-right: 9px solid rgba(0, 0, 0, .7);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.tooltip-message {
  background: rgba(0, 0, 0, .7);
  border-radius: 3px;
  color: #fff;
  font-size: .75rem;
  line-height: 1.4;
  padding: .75em;
  text-align: center;
}

/* react toast notfication */

.Toastify__toast--success {
  background: #00be6e !important;
  font-weight: bold;
}

.Toastify__toast--error {
  background: #D82F2B !important;
  font-weight: bold;
}

/* react toast notfication */

/* button active and hover effect */

.ui.button:active {
  opacity: .8;
}

/* button active and hover effect */

/* For some reason there is a margin on the footer on small screens, resulting in a white border on the left and right*/

.ui.fluid.container {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
  background-color: #1b1c1d;
}
