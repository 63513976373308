.Review-Form h1 {
  text-align: center;
}

.Review-Form {
  display: flex;
  flex-direction: column;
}


.recommend-choice {
  margin-top: 15px;

}

.recommend-choice h5 {
  color: #8E8E8E;

}

.recommend-choice-flex {
  display: flex;
}

.recommend-button-false {
  padding: 10px 25px 10px 25px;
  border: 1px solid #BCBCBC;
  cursor: pointer;
}

.recommend-button-true {
  padding: 10px 25px 10px 25px;
  background-color: #00be6e;
  cursor: pointer;
}

.recommend-button-true p {
  color: white;
}

.review-type {
  margin-top: 20px;
}

.review-type h5 {
  color: #8E8E8E;
}

.Review-Form-back {
  color: #00be6e;
}

.Review-Submit {
  display: inline-block;
  padding: 10px 35px 10px 35px;
  background-color: #00be6e;
  cursor: pointer;
  border-radius: 3px;
}

.Edit-Review {
  display: inline-block;
  padding: 10px 25px 10px 25px;
  background-color: #00be6e;
  cursor: pointer;
  border-radius: 3px;
  color: white;
  margin-right: 10px;
}

.review-error-text{
  text-align: center;
  color:red;
}

.Review-Submit p {
  color: white;
}

.star-flex {
  display: flex;
}

.star-flex h5 {
  margin-right: 8px;
  color: #8E8E8E;
}

.Edit-Review-flex {
  margin-top: 15px;
  display: flex;
  align-items: baseline;
}

.Edit-Review-flex h5 {
  color: #D90000;
  margin-left: 5px;
  cursor: pointer;
}