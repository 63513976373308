.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
}

/* connect modal */

.connect-modal {
  width: 900px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  padding: 3em;
}

.connect-modal-left-C2C {
  padding-right: 3em;
  display: flex;
  flex-direction: column;
}

.connect-modal-left-C2C h4 {
  text-align: center;
  font-size: 1.3rem;
}

.connection-type {
  text-align: center;
  font-size: 1.1rem;
}

.connect-zip {
  margin-top: 20px;
  margin-bottom: 20px;
}

.connect-zip p {
  margin: 0;
  margin-bottom: 5px;
}

.connect-zip input {
  font-size: 1.4rem;
  padding: 0 !important;
}

.connect-modal-left h4 {
  align-self: center;
  margin-bottom: 0;
}

.connect-modal-left h3 {
  align-self: center;
  font-size: 1.2em;
  margin: 1em 0 2em;
  font-weight: normal;
  color: #999;
}

.connect-modal-right {
  padding-left: 3em;
  width: 60%;
}

/* Buttons */

#send-connect-button {
  width: 100%;
  background-color: #05BF6F;
  padding: 1em 2em;
  margin-top: 3em;
  border: none;
  border-radius: 2px;
  color: #fff;
}

#close-modal-button {
  position: absolute;
  right: 0;
  top: 0;
}

#connect-modal-form {
  display: flex;
  justify-content: center;
}

/* Labels */

label[for="story"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

label[for="start-date"] {
  margin-bottom: 0.3em;
  color: #999;
  font-size: 0.9em;
}

label[for="form-check"] {
  margin-bottom: 0.75em;
}

/* Inputs */

input[type="date"] {
  background-color: #F2F3F5;
  text-align: center;
  font-family: inherit;
  font-weight: bold;
  margin-bottom: 1em;
  color: #606060;
  padding: 0.3em 0.5em;
  border: none;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
}

input[type="radio"] {
  margin-right: 1em;
  width: auto
}

input#attachment-upload {
  display: none;
}

/* sub-radio-option-containers */

.attachment-radio-option-container {
  margin-bottom: 2em;
  display: flex;
}

.radio-option-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.radio-option-container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark-connect {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.radio-option-container:hover input~.checkmark-connect {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.radio-option-container input:checked~.checkmark-connect {
  background-color: #00be6e;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark-connect:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.radio-option-container input:checked~.checkmark-connect:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.radio-option-container .checkmark-connect:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.radio-option-container p {
  font-size: 1.1rem;
  margin: -5px;
}

/* textarea */

#story {
  border: 1px solid rgb(199, 194, 194);
  padding: 1em 1em 5em;
  border-radius: 2px;
  letter-spacing: 1px;
  line-height: 1.5em;
  margin-bottom: 0.5em;
  font-size: 1.0rem;
}

/* icons */

#attachment-icon>path {
  color: #999;
}

.attachment-container {
  display: flex;
}

/* Calendar */

::-webkit-datetime-edit-text {
  padding: 0 0.3em;
}

::-webkit-inner-spin-button {
  display: none;
}

::-webkit-calendar-picker-indicator {
  margin-right: 0.2em;
}

/* business profile pic */

#profile-pic-C2C {
  width: 150px;
  height: 150px;
  align-self: center;
  border-radius: 100px;
}

.ui.selection.dropdown.connect-mobile-urgency-dropdown {
  display: none;
}

/* media queries */

@media (max-width: 999px) {
  .connect-modal {
    width: 100vw;
    margin: 0px;
    top: 0px !important;
    height: 100vh !important;
    transform: none;
    left: 0px;
    z-index: 1000;
  }
  .connect-modal-left, .connect-modal-right {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  #connect-modal-form {
    display: block !important;
  }
  .connect-modal-left {
    border-right: 0px;
    padding-right: 0px !important;
  }
  .connect-modal-right {
    padding-left: 0px !important;
  }
  #profile-pic {
    width: 75px;
    height: 75px;
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .connect-request-business-photo {
    text-align: center;
  }
  #send-connect-button {
    margin-top: 10%;
  }
}

@media (max-height: 667px) {
  .ui.selection.dropdown.connect-mobile-urgency-dropdown {
    display: block;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    padding-left: 0px;
  }
  .ui.selection.dropdown.connect-mobile-urgency-dropdown .active.item {
    color: white;
  }
  #form-check {
    display: none;
  }
  #send-connect-button {
    margin-top: 1%;
  }
}