.personal-favorites-container {
  margin-bottom: 5%;

}

.personal-favorites-header {
  padding-top: 30px;
  padding-bottom: 60px;
  margin-bottom: 70px;
  background-color: #F8F9FA;
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personal-favorites-header img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
}

.personal-favorites-header h5 {
  margin-top: 0px;
  font-style: italic;
}

.listings-list {
  margin-bottom: 50px;
}

@media only screen and (max-width: 450px) {
  .no-favorites img {
    width: 200px;
    object-fit: fill;
  }

}


.no-favorites img {
  width: 200px;
}

.no-favorites {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  height: 50vh;
}


.no-favorites p {
  margin-top: 20px;
  font-size: 20px;
}

.personal-favorites-results {
  margin: 0px 21% 0px 21%;
}

@media only screen and (max-width: 999px) {
  .personal-favorites-results {
    margin: 0px;
  }

  .personal-favorites-header,
  .no-favorites {
    text-align: center;
  }

  .no-favorites img {
    width: 100px;
  }

  /* .personal-favorites-header {
    padding-top:
  } */
}