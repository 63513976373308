/*========================= Begin businessDetails CSS ===============================*/
#BusinessDetails {
  margin-bottom: 5em;
}

#BusinessDetails .save-changes.button {
  background-color: #00be6e;
  padding: 1.3em 2em;
  width: 150px;
  margin-bottom: 1em;
}

#BusinessDetails .save-changes.button:hover {
  background-color: #16ab39;
}

.websiteWarning {
  color: "#F61C04";
}




/*========================= end businessDetails CSS ============================*/

/*========================= Begin edit business CSS ===============================*/


.sidebar {
  width: 20%;
  align-items: center;
  margin-right: 5%;
}

.edit-business-form {
  width: 60%;
}

.sidebar-nav-link {
  display: block;
  margin-bottom: 4%;
  color: #A2A8AC;
}

.sidebar-nav-link:hover {
  color: #00BE6E;
}

.sidebar-nav-active {
  font-weight: bold;
  color: #000;
  /* font-size: 1.1em; */

  
}

.Toastify__toast--success.Referral-Toast-Success-Edit-Changes {
  background: white !important;
  color: black;
  border-left: #00be6e 7px solid;
}

.Toastify__toast--success.Referral-Toast-Success-Edit-Changes .Toastify__close-button {
  color: #e0e0e0 !important;
}

.Toastify__toast--success.Referral-Toast-Success-Edit-Changes .Toastify__progress-bar {
  background-color: #b5ebb7 !important;
}

/*========================= end editBusiness CSS ============================*/
::placeholder {
  color: #999;
}

input {
  color: #999;
}

#edit-business-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "business-name business-name"
    "category category"
    "sub-category sub-category"
    "subcategories subcategories"
    "about about"
    "street street"
    "city state"
    "zip zip"
  ;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 1em;
  width: 75%;
}

#business-name {
  font-size: 14px;
}

.business-name {
  grid-area: business-name;

}

.email {
  grid-area: email;
}

.state {
  grid-area: state;
}

.city {
  grid-area: city;
}

.zip-code {
  grid-area: zipCode;
}

.street {
  grid-area: street;
}

.about {
  grid-area: about;
}

.zip {
  grid-area: zip;
}

.category {
  grid-area: category;
}

#sub-category a.ui.label {
  line-height: .7;
}

#sub-category i.delete.icon {
  width: 30px;
  position: relative;
  top: 2.3px !important;
  font-size: 17px !important;
}

#sub-category .ui.selection.dropdown>.dropdown.icon {
  right: 0.5em;
}


#sub-category {
  grid-area: sub-category;
  width: 100%;
}

#sub-category .ui.fluid.multiple.search.selection.dropdown {
  border: none;
}

.subcategories {
  grid-area: subcategories;
}

input {
  color: #999;
}

.business-banner {
  width: 75%;
}

.approved-businesses, .awaiting-approval, .pending-requests {
  width: 40%;
}

.dream-team-header {
  margin-top: 50px;
}

.edit-business-form hr {
width: 75%;
}

.profile-progress-bar {
margin-top: 50px;
}

.edit-business-form {
margin-bottom: 20px;
}

.dream-member {
  margin-bottom: -1%;
}

.empty-dt {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 15px;
}

/*========================= Begin Media Queries CSS ============================*/

@media only screen and (max-width: 1000px) {

  .edit-business-form {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    min-height: 100vh;
  }

  .edit-business-form hr{
    width: 100%;
    height: auto;
  }

  #edit-business-form{
    width: 100%;
    display: block;
  }

  #edit-business-form div {
    margin-bottom: 20px;
  }

  .business-banner {
    width: 100%;
    margin-bottom: 20px;
  }

  .profile-progress-bar{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .approved-businesses, .awaiting-approval, .pending-requests {
    width: 100%;
  }

  .dream-team-header {
    margin-top: 20px;
  }

  .dream-member {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 500px) {}

/*========================= End Media Queries CSS ============================*/
/*========================= End Media Queries CSS ============================*/