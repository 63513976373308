.sort-container select {
  padding: 10px 20px;
  background-color: #F1F3F5;
  color: #696969;
  font-weight: bold;
}

.sort-tags {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 640px) {
  .listings-list {
    margin: 0 auto;
    max-width: 1600px;
  }



  .sort-tag-main-container {
    display: flex;
    justify-content: space-between;
    padding: 2.5em 0;
  }

  .tag-container h1 {
    font-family: inherit;
  }

  .tag {
    display: inline-block;
  }

  .tag-section {
    border: none;
    border-radius: 12px;
    padding: 5px 12px;
    margin-right: 15px;
    font-size: 12px;
    font-weight: bold;
    background-color: #333F48;
    color: #fff;
    text-transform: uppercase;
  }
}

.sort-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#x-icon>path {
  color: white;
}

.no-listing img {
  width: 500px;
}

.no-listing {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.no-listing h2 {
  font-size: 30px;
  margin-bottom: 30px;
}

.no-listing p {
  font-size: 20px;
}

.tag-container,
.sort-container {
  padding-left: 20px;
  padding-right: 20px;
}