.learning-page-container {
    margin: 0;
    padding: 0;
    background-color: #f7f7f7;
}

.learning-page-top {
    margin: auto;
    padding: 5% 0;
    width: 50%;
    text-align: center;
}

.learning-page-title {
    font-size: 48px;
}

.learning-page-text {
    margin: auto;
    width: 75%;
    font-size: 18px;
}

.learning-page-mid {
    margin: auto;
    width: 60%;
    padding-bottom: 5%;
}

.learning-page-section {
    display: flex;
    margin: 0;
    padding: 5% 0;
    margin-top: 20px;
}
.learning-page-section:nth-child(6) .learning-page-section-wrapper-right .BrainhubCarousel_container .BrainhubCarousel {
    display: none;
}

.learning-page-section-wrapper-left {
    /* margin-top: 5%; */
    width: 20%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.landing-page-image-wrapper {
    margin-right: 10%;
    margin-top: 1%;
}

.icon-img {
    width: auto;
    height: auto;
}

.learning-page-section-title {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.learning-page-section-title h3 {
    font-size: 24px;
    text-align: left;
    margin: 0;
}

.title-b {
    margin-bottom: 10%;
}

.line-box {
    width: 100%;
}

.title {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
}

.brand {
    width: 100%;
}

.line-container {
    width: 12%;
    margin: 5% auto;
}

.line-mob {
    height: 1px;
    width: 100%;
    border: 2px solid black;
}

.learning-page-section-wrapper-right {
    width: 90%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#card-name {
    font-size: 18px;
}

#card-desc {
    font-size: 14px;
}

.ui.card:first-child {
    margin-top: 0;
}

.ui.card {
    height: 275px;
    width: 250px;
    margin: 0 1% 1% 1%;
    border: none;
}

.ui.card:last-child {
    margin-bottom: 1%;
}

.ui.card>.extra, .ui.card>.card>.extra {
    border-top: none !important;
    /* padding: .75em 1em !important; */
}

#card-desc-link {
    color: #00BF6F;
    cursor: pointer;
    font-size: 14px;
}

.learning-page-section-text {
    padding: 0 5% 5% 5%;
}

.learning-page-section-text h5 {
    font-size: 18px;
}

.learning-page-section-text p {
    font-size: 16px;
}

.learning-page-bot {
    background-color: #00BF6F;
}

.learning-page-bot-wrapper {
    width: 40%;
    margin: 0 auto;
    padding: 10% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.learning-page-bot-wrapper h2 {
    color: white;
    font-size: 36px;
    padding-bottom: 5%;
    width: 75%;
    margin: auto;
}

.learning-page-bot-wrapper p {
    color: white;
    padding-bottom: 5%;
    font-size: 18px;
}

.learning-page-bot-button {
    width: 50%;
    margin: 0 auto;
    text-align: center;
}

#botBtn {
    background-color: white;
    color: #00BF6F;
    font-size: 18px;
}

@media only screen and (max-width: 1449px) {
    .learning-page-top {
        width: 75%;
    }
    
    .learning-page-mid {
        width: 90%;
    }
}

@media only screen and (max-width: 1155px) {
    .learning-page-mid {
        width: 80%;
    }

    .learning-page-section-text {
        padding: 0 5%;
    }
}

@media only screen and (max-width: 999px) {
    .learning-page-mid {
        width: 95%;
    }

    .learning-page-section-wrapper-right {
        width: 80%;
    }
}

@media only screen and (max-width: 767px) {
    .learning-page-top {
        width: 80%;
    }

    .learning-page-text {
        width: 100%;
    }

    .learning-page-section-title h3 {
        text-align: center;
        width: 100%;
    }

    .line-box {
        width: 80%;
    }

    .line {
        width: 50%;
        margin: 0 auto;
    }

    .branding {
        display: flex;
        flex-direction: row;
        width: 80%;
    }

    .branding h3 {
        width: 90%;
    }

    .learning-page-mid {
        width: 90%;
    }

    .learning-page-section {
        flex-direction: column;
    }

    .learning-page-section-wrapper-left {
        width: 60%;
        justify-content: center;
        margin: 5% auto;
        align-items: flex-start;
        margin-bottom: 15%;
    }

    .landing-page-image-wrapper {
        margin-right: 5%;
    }

    .learning-page-section-title {
        width: 50%;
    }

    .uniform {
        width: 75%;
    }

    .brand {
        width: 90%;
    }

    .learning-page-section-title h3 {
        letter-spacing: 1px;
        text-align: left;
    }

    .title-b {
        width: 80%;
    }

    .title-b h3 {
        margin: 0;
    }

    .learning-page-section-text h5 {
        text-align: center;
    }

    .learning-page-section-text p {
        text-align: center;
    }
}

@media (max-width: 766px) {
    .learning-page-title h1 {
        font-weight: bold;
        font-size: 35px;
        margin: 40px;
    }

    .learning-page-mid {
        width: 95%;
    }

    .learning-page-section-wrapper-left {
        margin-bottom: 20%;
    }

    .landing-page-image-wrapper {
        margin-top: 0;
    }
    
    

    .learning-page-section:nth-child(1) .landing-page-image-wrapper {
        margin-right: 10%;
    }

    .learning-page-section:nth-child(2) .landing-page-image-wrapper {
        margin-right: 0%;
    }

    .learning-page-section:nth-child(3) .landing-page-image-wrapper {
        margin-right: 0%;
    }

    .learning-page-section:nth-child(5) .landing-page-image-wrapper {
        margin-right: 8%;
        margin-bottom: 2%;
    }

    .learning-page-section:nth-child(6) .landing-page-image-wrapper {
        margin-right: 0%;
    }

    #desktop {
        margin-top: .5% !important;
        margin-right: 5% !important;
    }

    .line-box {
        width: 60%;
        margin: auto;
    }

    .learning-page-section-wrapper-right {
        width: 100%;
    }

    .ui.card:first-child {
        margin-top: 1% auto;
    }

    .ui.card {
        height: 300px;
        margin: 1% auto;
    }

    .ui.card:last-child {
        margin-bottom: 1% auto;
    }

    .ui.card>.content {
        padding: 10%;
    }

    .learning-page-bot-wrapper {
        width: 90%;
        margin: 0 auto;
        padding: 10% 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    
    .learning-page-bot-wrapper h2 {
        color: white;
        font-size: 2rem;
        padding-bottom: 5%;
        width: 85%;
    }
    
    .learning-page-bot-wrapper p {
        color: white;
        padding-bottom: 5%;
    }
    
    .learning-page-bot-button {
        width: 50%;
        margin: 0 auto;
        text-align: center;
    }
    
    #botBtn {
        background-color: white;
        color: #00BF6F;
        font-size: 1.25rem;
    }

    .uniform {
        width: 82%;
    }

    .brand {
        width: 100%;
    }
    
}

@media (max-width: 500px) {
    .learning-page-mid {
        width: 100%;
    }

    .learning-page-section-wrapper-left {
        width: 70%;
    }

    .title-b {
        width: 100%;
    }

    .branding {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .line-box {
        width: 80%;
    }

    .line {
        width: 40%;
        text-align: center;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .b {
        width: 25%;
    }
}

@media (max-width: 412px) {
    .learning-page-section-wrapper-left {
        width: 90%;
        margin: auto;
        margin-bottom: 40%;
    }

    .mobile {
        margin-bottom: 20%;
    }

    .brand {
        width: 100%;
    }
}