button#clear-search {
  border: none;
  background: white;
  margin-right: 2em;
  color: #43b02a;
  font-weight: bold;
}

.horizontal-card-container {
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
  margin: 15px 0;
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: space-around;
}

.horizontal-card-container-mobile {
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
  margin: 15px 0;
}

p {
  font-size: 1.1em;
}

#rhiz-score {
  color: #21ba45;
  font-size: 22px;
  font-weight: inherit;
  font-family: inherit;
}

/* Business Images */

.horizontal-card-image-container {
  width: 300px;
  display: flex;
  flex-direction: column;
}

#main-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sub-image-section {
  display: flex;
  height: 30%;
}

.sub-image-section>img {
  width: 33.3%;
}

/* Business Info */

.business-name {
  padding-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  color: black;
}

#business-info-flex a {
  width: 500px;
}

.favorite-star {
  cursor: pointer;
}

.business-name:hover {
  text-decoration: underline;
}

#business-info-top {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  margin-bottom: 0.5em;
  width: 40%;
}

#business-info-flex {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  margin-bottom: 0.5em;
}

#business-info-top>span {
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
}

.business-address {
  color: #a3a3a3;
  font-weight: bold;
  cursor: pointer;
}

.business-address:hover {
  text-decoration: underline;
}

.business-tags {
  margin-bottom: 1em;
}

.business-tags>li {
  display: inline-block;
  margin-right: 2.5em;
  font-size: 1.1em;
  font-weight: bold;
}

li:first-child {
  color: #21ba45;
}

li:not(first-child) {
  color: #a3a3a3;
}

p#business-about {
  margin: 0 3em 1em 0;
}

h2#works-with {
  margin: 0 0 0.5em 0;
  font-size: 22px;
}

.works-with-images-container {
  display: flex;
}

.works-with-images-container>h4 {
  font-style: italic;
}

.works-with-image {
  width: 7em;
  height: 7em;
  margin-right: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.business-info-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5em;
}

.buttons {
  display: flex;
}

#view-profile-button {
  border: 1px solid #21ba45;
  width: 120px;
  height: 40px;
  color: #21ba45;
  background-color: white;
}

#connect-button {
  border: 1px solid #21ba45;
  width: 120px;
  height: 40px;
  color: white;
  background-color: #21ba45;
  margin-right: 20px;
}

/* icon */

#fb-icon>path {
  color: #3b5998;
}

.business-address svg {
  margin-right: 5px;
}

.mobile {
  display: none;
}

.business-info-container {
  padding: 20px;
  box-sizing: border-box;
  width: 70%;
}

@media only screen and (max-width: 999px) {
  #connect-button {
    margin-right: 0px;
  }
  .business-info-top-mobile {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    margin-bottom: 0.5em;
    width: 40%;
  }
  #business-info-top-mobile>span {
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;
  }
  .business-name, #business-info-top-mobile, h2#works-with {
    font-size: 18px;
  }
  .favorite-star, #business-info-top-mobile>span {
    float: right;
  }
  #main-image {
    height: auto;
  }
  .horizontal-card-image-container, .business-info-container {
    width: 100%;
  }
  .business-info-container {
    padding: 20px;
  }
  p#business-about {
    margin-right: 0px;
  }
  .business-name, #business-info-top, h2#works-with {
    font-size: 18px;
  }
  span {
    margin-left: 0px;
  }
  #business-info-top {
    display: inline;
  }
  .horizontal-card-container, .business-info-bottom, .buttons {
    display: block;
  }
  h1:first-child {
    font-size: 1.3rem;
  }
  #search-results {
    margin-left: 0px;
    margin-right: 0px;
  }
  .sort-tags, .sort-container, .business-tags li:first-child, .buttons {
    margin-top: 20px;
  }
  .sort-container select {
    max-width: 80%;
  }
  .sort-container {
    padding-bottom: 10px;
  }
  #view-profile-button, #connect-button {
    width: 50%;
  }
  .mobile {
    display: inline;
  }
  .mobile.business-facebook-likes {
    display: inline;
  }
  .desktop, #works-with, .works-with-images-container {
    display: none;
  }
  .favorite-star, #business-info-top>span {
    float: right;
  }
  .business-name {
    margin-left: 0px;
  }
  .listings-list {
    padding-right: 20px;
    padding-left: 20px;
  }
  .business-info-bottom {
    margin-top: 1em;
  }
  button#clear-search {
    text-align: left;
  }
}