* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

body, html {
  height: 100vh;
}

/* main profile page */

.profile-page-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin-bottom: 3%;
  margin-top: 100px;
}

/* side nav bar */

nav.side-nav-bar {
  grid-column: 1/2;
  grid-row: 1/3;
  justify-self: center;
  padding-top: 3.8em;
}

nav.side-nav-bar hr {
  width: 25%;
  margin: 7px 0;
  height: 1px;
  color: #000;
  background-color: #000;
  border: none;
}

.sidebar-nav-link {
  display: block;
  margin-bottom: 4%;
  color: #A2A8AC;
}

.sidebar-nav-link:hover {
  color: #00BE6E;
}

.sidebar-nav-active {
  font-weight: bold;
  color: #000;
  /* font-size: 1.1em; */
}

.profile-form {
  grid-column: 2/5;
  grid-row: 1/5;
  /* padding-top: 3.5em; */
}

/* form page */

label {
  display: block;
  color: #404040;
  font-weight: 500;
  margin-bottom: 0.5em;
}

button {
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.form-container {
  margin: 0 auto;
  max-width: 1000px;
}

#edit-profile-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "firstName lastName" "phone phone" "street street" "city state" "zipCode zipCode" "briefBio briefBio" "facebook facebook" "formBottom formBottom";
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 10em;
}

#change-password-title {
  font-weight: bold;
  font-size: 2rem;
}

#change-password-warn {
  margin-top: 7%;
  font-size: 15px;
  /* font-weight: bold; */
  /* text-align: center; */
}

#change-password-form {
  display: flex;
  margin-top: 2em;
  flex-wrap: wrap;
}

#change-password-form>div {
  width: 45%;
}

.first-name {
  grid-area: firstName;
}

.last-name {
  grid-area: lastName;
}

.email {
  grid-area: email;
}

.phone {
  grid-area: phone;
}

.state {
  grid-area: state;
}

.city {
  grid-area: city;
}

.zip-code {
  grid-area: zipCode;
}

.street {
  grid-area: street;
}

.brief-bio {
  grid-area: briefBio;
}

#briefBio {
  line-height: 1.5em;
}

input, textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid gray;
}

input:focus, textarea:focus {
  outline: none;
}

.ui.search.dropdown>.text {
  z-index: 1 !important;
}

input[type="text"], input[type="password"], textarea {
  padding: 1em;
}

/* Form page profile picture */

.profile-picture-container {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

#profile-picture {
  width: 100px;
  border-radius: 50%;
}

input.image-upload {
  border: none;
}

.delete-upload-buttons {
  color: #00BE6E;
  border: none;
  background-color: #fff;
  display: inline-block;
  cursor: pointer;
}

/* img upload input */

input#img-upload {
  visibility: hidden;
}

.delete-upload-container>span {
  margin: 0 5px;
}

/* Form Bottom */

.form-bottom {
  grid-area: formBottom;
}

#upload-identification {
  border: none;
  cursor: pointer;
}

#facebook-button {
  background-color: #335393;
  color: #fff;
  padding: .7em 1.5em;
  border: none;
  margin-bottom: 2.5em;
  border-radius: 3px;
}

#facebook-button .logo {
  width: 1.3em;
  margin-right: .5em;
  vertical-align: text-bottom;
}

.edit-save-buttons {
  margin-top: 3em;
}

.edit-save-buttons>button, #password-save-button {
  background-color: #00be6e;
  color: #fff;
  padding: 1em 2em;
  border-radius: 4px;
  border: none;
  margin-right: 1em;
  margin-top: 1em;
}

.edit-save-buttons>button:hover {
  background-color: #16ab39;
}

.ui.dropdown .menu .active.item {
  background: #00be6e !important;
}

.ui.search.dropdown>input.search {
  cursor: pointer !important;
}

#change-password-form>div input {
  width: 85%;
}

.content.ReferralSucess {
  padding: 100px 100px 50px 100px !important;
  text-align: center;
}

.content.ReferralSucess img {
  width: 300px;
}

.content.ReferralSucess h1 {
  font-size: 30px;
  line-height: 35px;
}

.content.ReferralSucess p {
  font-size: 17px;
  line-height: 150%;
}

.content.ReferralSucess button.ui.button {
  margin-top: 50px;
  background-color: #00BE6E;
  color: white;
  width: 200px;
  height: 50px;
  font-size: 16px;
}

@media only screen and (max-width: 999px) {
  #mobile-options-edit {
    background-color: white;
    border-top: 1px solid #ebebeb;
    display: block;
    width: 100%;
    padding: 25px 20px;
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    vertical-align: middle;
    /* margin-bottom: 60px; */
  }
  #mobile-options-edit {
    margin-left: 0;
  }
  #mobile-options-edit .active, #mobile-options .active {
    color: #00be6e;
  }
  #mobile-options-edit span:last-child, #mobile-options span:last-child {
    float: right;
  }
  #mobile-options-edit span:first-child, #mobile-options span:first-child {
    margin-right: 20px;
  }
  #edit-profile-form {
    display: block;
  }
  #edit-profile-form input {
    margin-bottom: 20px;
  }
  .profile-page-container {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 20px;
    padding-right: 20px;
    min-height: 100vh;
    margin-top: 0px;
  }
  #change-password-title {
    margin-bottom: 10%;
    margin-top: 5%;
  }
  #change-password-form>div {
    width: 100%;
    margin-bottom: 20px;
  }
  #change-password-form>div input {
    width: 100%;
  }
  #mobile-options-edit a {
    color: rgba(0, 0, 0, .87);
  }
  .profile-picture-container {
    margin-top: 20px;
  }
  .link {
    font-size: 12px;
  }
  .profile-form {
    grid-column: 1;
  }
}

/* for iphone X and pixel 2 xl */

@media screen and (width: 375px) and (height: 812px), screen and (width: 411px) and (height: 823px) {
  #mobile-options-edit {
    margin-bottom: 60px;
  }
}