/* Karla font */

@import url('https://fonts.googleapis.com/css?family=Karla&display=swap');
html {
  box-sizing: border-box;
}

body, h1 {
  font-family: 'Karla', sans-serif !important;
}

*, *:before, *:after {
  box-sizing: inherit;
}


.rhiz-green {
  background-color: #00BE6E;
}

.rhiz-green h1 {
  color: white;
}

.react-parallax {
  color: white;
}

.sign.icon {
  opacity: .2;
}

.coverImage {
  max-width: 100%;
  height: auto;
}

.ui.link.list .item, .ui.link.list .item a:not(.ui), .ui.link.list a.item, h4.ui.header {
  color: #fff !important;
}

.ui.link.list .item a:not(.ui):hover, .ui.link.list a.item:hover {
  color: #3ebcb2 !important;
}

.ui.item.image {
  height: '50px';
  width: '50px'
}

.ui.link.list {
  font-size: 12px;
  line-height: 1.8;
}

.ui.secondary.menu.header {
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
}

a.email {
  color: #fff;
}

a.email:hover {
  color: #3ebcb2;
}

.card-icons {
  margin-top: 5%;
  margin-bottom: 5%;
}

.ui.secondary.menu {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.ui.menu .right.menu .dropdown:last-child .menu {
  left: auto;
  right: -90px !important;
}

.ui.secondary.menu .active.item {
  background: none !important;
}

.chevron {
  color: #333f48;
}

/* .rhiz-header-logo {
  height: 38px;
  width: 70px;
  margin-bottom: -10px;
} */

.ui.secondary.menu .active.item .item:hover {
  opacity: .7 !important;
}

.bio {
  margin-bottom: 5%;
  font-family: 'Karla', sans-serif;
}

.businessCard {
  font-family: 'Karla', sans-serif;
}

#header-flex {
  margin-top: 8px;
  display: flex;
  margin-right: 1.5em;
  /* flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 50px; */
}

.ui.secondary.menu .dropdown.item>.menu {
  margin-top: 47px !important;
}

#bell-svg .ui.item.dropdown.dream-team-dropdown svg {
  margin-top: 30px;
  margin-right: 10px;
  position: absolute;
}

#bell-svg-count-true .ui.item.dropdown.dream-team-dropdown svg {
  margin-top: 30px;
  margin-right: 10px;
  position: absolute;
}

#leaf-svg img {
  margin-top: 30px;
  position: absolute;
  width: 35px;
}

#leaf-svg-count-true img {
  margin-top: 30px;
  position: absolute;
  width: 35px;
  /* margin-left: -8px; */
}

#envelope-svg {
  margin-right: 35px;
  margin-left: 5px;
}

.safari-icon {
  margin-top: -15px;
}

.ui.item.dropdown.dream-team-dropdown {
  margin-left: -25px !important;
}

.dream-team-dropdown:hover {
  background: inherit !important;
}

.dream-team-dropdown-true:hover {
  background: inherit !important;
}

.dropdown.dream-team-dropdown {
  padding: 0px !important;
}

.ui.item.dropdown.dream-team-dropdown-false {
  margin-left: -20px !important;
}

.dream-team-dropdown-false:hover {
  background: inherit !important;
}

.dream-team-dropdown-false-true:hover {
  background: inherit !important;
}

.dropdown.dream-team-dropdown-false {
  padding: 0px !important;
}

.header-login {
  margin-top: 5px !important;
}

.ui.small.middle.aligned.image.rhiz-header-logo-R {
  height: 50px;
  width: 50px;
  margin-left: 0px;
}

.my-business-button {
  background-color: white !important;
  margin-bottom: 21px !important;
  padding-top: 30% !important;
  height: 80% !important;
}

.connect-ig-business {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.connect-ig-business h1 {
  margin: 0;
  font-size: 16px;
}

.loader-height {
  height: 100vh;
}

.connect-ig-business img {
  margin-right: 8px;
  width: 37px;
}

.icon-count-message {
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 15px;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  top: 3px;
  left: 35px;
}

.icon-count-bell {
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 15px;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  top: 3px;
  right: 54px;
}

.icon-count-leaf {
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 15px;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  top: 3px;
  left: 0px;
}

.connect-success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connect-success h3 {
  color: #969696;
  margin-top: 40px;
  margin-bottom: 50px;
  font-weight: normal;
}

.connect-success h1 {
  font-size: 40px;
}

.connect-success-buttons {
  display: flex;
  align-items: center;
  width: 350px;
  justify-content: space-between;
}

button.connect-success-button-message {
  color: #565B60;
  font-size: 20px;
  font-weight: 600;
}

button.connect-success-button-close {
  font-size: 20px;
  background-color: #00BE6E;
  color: white;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  border-color: transparent;
  cursor: pointer;
}

p.connect-success-button-close:hover {
  opacity: .8;
}

button.connect-success-button-close:active {
  opacity: .8;
}

@media only screen and (min-width: 785px) {
  .ui.small.middle.aligned.image.rhiz-header-logo-R {
    display: none;
  }
  .dropdown-header-mobile {
    display: none;
  }
  .Social-Links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .Social-Links img {
    height: auto;
    width: 50px;
    cursor: pointer;
    margin-right: 15px;
  }
  .my-business-button-false {
    background-color: white !important;
    margin-bottom: 21px !important;
    padding-top: 30% !important;
  }
}

#signup-button {
  background-color: #00bf6f;
  color: white;
  padding: 15px 20px;
  margin-bottom: 30px;
  margin-right: 100px;
}

#signup-button:hover {
  background-color: #008c52;
}

.ui.secondary.header.menu .ui.button.nav-button, .ui.secondary.header.menu .ui.button {
  color: #333f48;
}

/* .ui.secondary.header.menu {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 1000;
  top: 0;
} */

@media only screen and (max-width: 785px) {

  .mobile-top-padding{
    padding-top: 70px;
  }

  button.ui.button.my-business-button-false {}
  .icon-count-message{
    left: 22px;
  }
  .icon-count-leaf{
    left: -17px;
  }
  .header.ui.menu:not(.vertical) .right.menu {
    margin: 0 auto !important;
  }
  #header-flex {
    margin-top: 8px;
    display: flex;
    margin-right: 18vw;
  }
  #envelope-svg {
    margin-left: 18px;
  }
  .connect-success {
    height: 100vh;
    top: 15vh;
    position: relative;
  }
  .connect-success-buttons {
    display: flex;
    align-items: center;
    width: 85vw;
    justify-content: space-between;
  }
  p.connect-success-button-message {
    font-size: 17px;
    font-weight: 600;
  }
  p.connect-success-button-close {
    font-size: 17px;
    padding: 10px 15px 10px 15px;
  }
  .ui.menu .right.menu .dropdown:last-child .menu {
    position: fixed;
    top: 1.5%;
    left: 0px !important;
    right: 0px !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 92vh !important;
    /* bring your own prefixes */
  }
  #signup-button {
    background-color: #00bf6f;
    color: white;
    padding: 15px 20px;
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .my-business-button-false {
    background-color: white !important;
    margin-bottom: 0px !important;
    padding-top: 30% !important;
  }
  .ui.secondary.menu.header {
    width: 100vw;
    position: fixed;
    z-index: 10;
    background-color: white;
  }
  .dropdown-header {
    display: none;
  }
  .ui.item.dropdown.dream-team-dropdown-false {
    display: none !important;
  }
  .dropdown-leaf {
    display: none;
  }
  .dropdown.dream-team-dropdown {
    padding: -0 !important;
  }
  .rhiz-header-logo {
    display: none !important;
  }
  .my-business-button {
    display: none !important;
  }
  .my-business-button-false {
    margin-top: 5px !important;
    padding: 0 !important;
  }
  .ui.item.dropdown.header.login {
    right: -1vw;
    position: absolute;
  }
  .Social-Links img {
    height: auto;
    width: 30px;
    cursor: pointer;
    margin-right: 5px;
  }
  .Social-Links {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .nav-button.ui.button {
    padding: 0px 10px;
  }
  .ui.secondary.header.menu .item {
    padding-left: 0px;
  }
  .ui.secondary.menu.header .text {
    padding-right: 1rem;
  }
  .dropdown-header-mobile .item {
    padding-left: 15px !important;
  }
  .dropdown-header-mobile .text {
    padding-right: 0px;
  }
}

.tooltip {
  position: relative;
}

.tooltip-trigger {
  display: inline-block;
  text-decoration: underline;
}

.tooltip-bubble {
  min-width: 120px;
  max-width: 210px;
  position: absolute;
  z-index: -10;
}

.tooltip-bubble::after {
  content: '';
  position: absolute;
}

.tooltip-top {
  bottom: 70%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);
}

.tooltip-top::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid rgba(0, 0, 0, .7);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);
}

.tooltip-bottom ::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid rgba(0, 0, 0, .7);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);
}

.tooltip-left::after {
  border-left: 9px solid rgba(0, 0, 0, .7);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);
}

.tooltip-right::after {
  border-right: 9px solid rgba(0, 0, 0, .7);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.tooltip-message {
  background: rgba(0, 0, 0, .7);
  border-radius: 3px;
  color: #fff;
  font-size: .75rem;
  line-height: 1.4;
  padding: .75em;
  text-align: center;
}

/* react toast notfication */

.Toastify__toast--success {
  background: #00be6e !important;
  font-weight: bold;
}

.Toastify__toast--error {
  background: #D82F2B !important;
  font-weight: bold;
}

/* react toast notfication */

/* button active and hover effect */

.ui.button:active {
  opacity: .8;
}

/* button active and hover effect */

/* For some reason there is a margin on the footer on small screens, resulting in a white border on the left and right*/

.ui.fluid.container {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
  background-color: #1b1c1d;
}