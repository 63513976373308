.social-login-button {
    padding: 10px;
}

.ui.small.modal.transition.visible.active.loginModal {
    text-align: center;
}

.links {
    color: #43b02a;
    cursor: pointer;
    margin-top: 2%;
}

.links:hover {
    text-decoration: underline;
}

.forgot-password {
    margin-right: auto;
    cursor: pointer;
    font-size: 14px;
}

.forgot-password {
    text-decoration: underline;
}

.ui.secondary.menu.header {
    align-items: center;
}

.ui.button.nav-button {
    background: none;
    font-size: 15px;
    font-family: 'Karla', sans-serif;
    color: #999;
}

.ui.button.nav-button:hover {
    color: #21ba45;
    background: none;
}

.ui.menu:not(.vertical) .right.item,
.ui.menu:not(.vertical) .right.menu {
    margin-right: 1em;
}

.loginModal {
    width: 90% !important;
    position: fixed;
    top: 50vh;

}




/* -------------------------------------Begin Subscription Modal CSS ---------------------------------------------- */
.subscription-modal {
    text-align: center;
    padding: 0em 5em 6em;
}

.subscription-modal .image-container {
    width: 35%;
    margin: 0 auto;
}

.subscription-modal .subscription-type {
    margin: 2em 0;
    padding: 0 2em;
    font-size: .85em;
}
.subscription-modal .subscription-type h3 {
    font-size: 2.7em;
    
}

.subscription-modal .billing-info-container {
    margin: 4em 3em;
    padding-left: 2em;
    text-align: left;
}

.subscription-modal .billing-info-container p {
    font-size: 1.5em;
    color: #000000;
    margin-bottom: 0.75em;
}

.subscription-modal .btn {
    color: #21ba45;
    font-size: 1.5rem;
    font-weight: 100;
    padding: 0 .8em;
    border: none;
    background-color: #fff;
}

.subscription-modal .btn:focus {
    outline: none;
}

.subscription-modal .btn.cancel {
    color: red;
    font-size: 1.5em;
}



/* -------------------------------------End Subscription Modal CSS ---------------------------------------------- */
/* -------------------------------------Begin min-width 768px CSS ---------------------------------------------- */
@media (min-width: 768px) {
    .loginModal {
        width: 50rem !important;
        left: calc((100% - 50rem)/2);
    }
}
/* -------------------------------------End min-width 768px CSS ---------------------------------------------- */