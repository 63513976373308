.notification-drop-down {
  padding: 10px;
}

.view-notifications h2 {
  font-size: 18px;
  padding-bottom: 16px;
  cursor: pointer;
  display: inline-block;
  color: #999999;
}

.view-notifications {
  text-align: center;
}

.notification {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 15%;
  margin-right: 15%;
  height: 90vh;
  overflow-y: auto;
}

.notification-drop {
  overflow-y: auto;
  height: 50vh;
}

.notfication-hr-dropdown {
  border-width: 0.05em;
  margin-top: 1em;
  height: 1px;
}

.notfication-hr-page {
  border-width: 0.05em;
  margin-top: 2em;
  margin-bottom: 2em;
  height: 1px;
}

.notification {
  padding: 5px 40px 5px 40px;
}

.Toastify__toast--default {
  width: 500px;
  right: 200px;
}

.notification-name-text {
  display: flex;
  align-items: baseline;
}

.notification-name-text p {
  color: black;
  font-size: 14px;
  line-height: 0px;
}

.notfication-text-option {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 785px) {
  .notfication-text-option {
    width: 55vw;
  }
  .notification {
    padding: 5px 10px 5px 10px;
    margin: 0px;
    margin-bottom: 20px;
  }
  .notification-name-text {
    display: flex;
  }
  .notification-name-text p {
    line-height: 10px;
  }
}

.notification h2 {
  font-weight: 100;
  font-size: 15px;
}