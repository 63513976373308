.content, .review-body {
    font-size: 1em;
}

.content-title {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.banner-title {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.banner-category {
    font-size: 1.6em;
    text-transform: capitalize;
}

.address {
    color: #999;
}

#contact-radio input {
    cursor: pointer;
}

#contact-radio .jPykbq {
    display: flex;
    align-items: center;
}

.fb-icon {
    margin-right: 1%;
    width: 10px;
    height: 10px;
    margin-top: -0.2%;
}

.business-photo {
    height: 12rem;
    width: 12rem;
    margin-bottom: '6%';
    margin-top: '-20%';
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    left: 1em;
    margin-left: 3em;
}

.business-banner {
    height: 375px;
}

.business-map {
    width: 75%;
    margin-bottom: 5%;
}

.business-profile-left {
    width: 80%;
}

.pricepoint {
    font-size: .95rem;
}

.rhiz-score {
    margin-left: 30px;
    color: #00be6e;
}

.rhiz-score-base {
    margin-left: 0px;
}

.cDvSjc {
    font-size: 1em;
}

.desktop.business-sidebar {
    margin-right: 10%;
}

@media only screen and (max-width: 999px) {
    .content, .review-body {
        font-size: .95rem;
    }
    .content-title {
        font-size: 1.15em;
    }
    .banner-title {
        font-size: 1.75em;
        margin-top: -25px;
        margin-bottom: 5px;
    }
    .banner-category {
        font-size: 1em;
    }
    .business-map {
        width: 100%;
    }
    .business-profile-left {
        width: 100%;
        margin-top: 40px;
    }
    .business-photo {
        height: 8rem;
        width: 8rem;
        margin-top: 10px;
        margin-left: .5em;
    }
    .business-banner {
        height: 200px;
    }
    #mobile-options {
        margin-left: -5%
    }
    .workswith-image {
        width: 75px;
        height: auto;
    }
    .cDvSjc {
        font-size: .95rem;
    }
}

@media screen and (width: 320px) and (height: 568px) {
    /* .ui-button {
		width: 50px;
	} */
}
