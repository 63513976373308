.Inbox-Container {
  margin: 20px 2% 20px 5%;
  width: 70%;
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
  border-radius: 20px;
}

.Inbox-Container-no-business {
  margin: 20px 2% 20px 5%;
  width: 70%;
}

.Inbox-Connect-Flex {
  display: flex;
  align-items: baseline;
}

.Inbox-tab {
  display: flex;
}

.True-tab {
  display: flex;
  border-top: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
  padding: 25px 40px 25px 40px;
  align-items: baseline;
  cursor: pointer;
  background-color: white;
}

.True-tab h4 {
  margin-bottom: 0;
  font-size: 1.5em;
  margin-right: 10px;
  color: #00be6e;
}

.True-tab-no-business {
  display: flex;
  border-top: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
  padding: 25px 40px 25px 40px;
  align-items: baseline;
  cursor: pointer;
  background-color: white;
}

.True-tab-no-business h4 {
  margin-bottom: 0;
  font-size: 1.5em;
  margin-right: 10px;
  color: #00be6e;
}

.False-tab-no-business {
  display: flex;
  padding: 25px 40px 25px 40px;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
}

.False-tab-no-business h4 {
  margin-bottom: 0;
  font-size: 1.5em;
  margin-right: 10px;
  color: #777777;
}

.False-tab {
  display: flex;
  padding: 25px 40px 25px 40px;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
}

.False-tab h4 {
  margin-bottom: 0;
  font-size: 1.5em;
  margin-right: 10px;
  color: #777777;
}

.new-messages {
  padding: 0;
  width: 28px;
  height: 28px;
  font-size: 1.5em;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  background-color: #F47100;
  border-radius: 28px;
}

.Inbox-message {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #F9F9F9;
}

.Inbox-message:hover {
  opacity: .7;
}

.Inbox-message-unread {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  width: 100%;
}

.Inbox-message-unread:hover {
  opacity: .7;
}

.inbox-profile-picture {
  object-fit: contain;
  height: 5em;
  width: 5em;
  border-radius: 100%;
  margin-left: 20px;
  margin-right: 20px;
}

.inbox-business-picture {
  width: 80px;
  margin-left: 20px;
  margin-right: 20px;
}

.name-date-flex {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: baseline;
}

.name-date-flex h4 {
  text-overflow: ellipsis;
  color: #777777;
  font-size: 1.6em;
  overflow: hidden;
}

.name-date-flex p {
  font-size: 1.3em;
}

.name-message-flex {
  width: 100%;
}

.text-number-flex {
  display: flex;
  justify-content: space-between;
  width: 89%;
  align-items: baseline;
}

.text-number-flex h4 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #777777;
  font-size: 1.3em;
  width: 100%;
  overflow: hidden;
}

.message-list-container {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.connect-requests {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.connect-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  box-sizing: border-box;
}

.Connect-number-flex {
  display: flex;
  margin-bottom: 25px;
  align-items: baseline;
  margin-left: 10px;
}

.Connect-Tab {
  margin-bottom: 0;
  font-size: 1.5em;
  margin-right: 15px;
  color: #00be6e;
}

.connect-request-box {
  border: 1px solid #DDDDDD;
  width: 350px;
  padding: 0px 10px 0px 15px;
  margin-bottom: 20px;
  margin-top: 10px;
  box-shadow: 0px 0px 6px 0px rgba(102, 102, 102, 0.34);
  border-radius: 20px;
  margin-left: 10px;
}

.connect-img-header-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.connect-img-header-flex img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.connect-name-time-type-flex {
  display: flex;
  flex-direction: column;
}

.connect-name-time-flex {
  display: flex;
}

p .connect-time-ago {
  float: right;
}

.connect-name-time-flex p {
  margin: 0;
  font-size: 14px;
}

.connect-flex-data {
  display: flex;
  margin-left: 8px;
  align-items: center;
}

.connect-flex-data h4 {
  margin-right: 5px;
  color: #777777;
  margin-bottom: 0px;
}

.connect-desciption h4 {
  margin-top: 20px;
  color: #777777;
}

.connect-desciption {
  overflow: hidden;
  height: 130px;
  line-height: 25px;
  margin-left: 8px;
}

.connect-desciption:before {
  content: "";
  float: left;
  width: 5px;
  height: 200px;
}

.connect-desciption>*:first-child {
  float: right;
  width: 100%;
  margin-left: -5px;
}

.connect-desciption:after {
  content: "\02026";
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  float: right;
  position: relative;
  top: -25px;
  left: 100%;
  width: 3em;
  margin-left: -3em;
  padding-right: 5px;
  text-align: right;
  background-size: 100% 100%;
}

.connect-attachment {
  margin-left: 8px;
}

.connect-attachment {
  margin-top: 20px;
  display: flex;
}

.connect-clip {
  margin-right: 5px;
}

.connect-vertical-line {
  border-left: 1px solid #DDDDDD;
}

.connect-view-decline {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #DDDDDD;
  display: flex;
  justify-content: space-evenly;
}

.connect-view-decline h4 {
  color: #00be6e;
  cursor: pointer;
}

.connect-view-decline p {
  cursor: pointer;
  color: #D82F2B;
}

.connect-type p {
  color: #00be6e;
}

.pending-outgoing-declined {
  display: flex;
  margin-left: 10px;
}

h4.True-connect {
  margin: 0;
  font-size: 1em;
  color: #00be6e;
}

.True-connect {
  display: flex;
  border: 1px solid #DDDDDD;
  padding: 10px 20px 10px 20px;
  align-items: baseline;
  cursor: pointer;
  background-color: white;
}

h4.True-connect-no-business {
  margin: 0;
  font-size: 1em;
  color: #00be6e;
}

.True-connect-no-business {
  display: flex;
  border: 1px solid #DDDDDD;
  padding: 10px 20px 10px 20px;
  align-items: baseline;
  cursor: pointer;
  background-color: white;
}

.False-connect {
  display: flex;
  padding: 10px 20px 10px 20px;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
}

h4.False-connect {
  margin: 0;
  font-size: 1em;
  color: #777777;
}

h4.False-connect {
  margin: 0;
  font-size: 1em;
  color: #777777;
}

.False-connect-no-business {
  display: flex;
  padding: 10px 20px 10px 20px;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
}

h4.True-connect-no-business {
  margin: 0;
  font-size: 1.5em;
  color: #00be6e;
}

.True-connect-no-business {
  display: flex;
  border: 1px solid #DDDDDD;
  padding: 10px 33px 10px 33px;
  align-items: baseline;
  cursor: pointer;
  background-color: white;
}

.False-connect-no-business {
  display: flex;
  padding: 15px 33px 10px 33px;
  align-items: baseline;
  background-color: #F9F9F9;
  cursor: pointer;
}

h4.False-connect-no-business {
  margin: 0;
  font-size: 1.5em;
  color: #777777;
}

.no-message-business {
  margin: auto auto;
}

.no-connect-requests-text {
  height: 80%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.no-connect-requests-text p {
  width: 40%;
  text-align: center;
}

@media only screen and (max-width: 999px) {
  .no-message-business {
    padding: 10px 10px 0px 10px;
  }
  .pending-outgoing-declined {
    margin-left: 0px;
  }
  .True-connect, .False-connect {
    width: 34%;
    text-align: center;
  }
  .True-connect-no-business, .False-connect-no-business {
    width: 50%;
    text-align: center;
  }
  .Inbox-Container {
    border-radius: 0px;
    box-shadow: none;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .Inbox-Container {
    width: 100%;
  }
  .name-date-flex p {
    font-size: 1rem;
    position: absolute;
    right: 1rem;
  }
  .True-tab, .False-tab {
    padding: 10px;
    width: 34%;
    justify-content: center;
  }
  .True-tab-no-business, .False-tab-no-business {
    padding: 10px;
    width: 50%;
    justify-content: center;
  }
  .Inbox-tab {
    justify-content: center;
    width: 100vw;
  }
  h4.True-connect {
    justify-content: center;
  }
  h4.False-connect {
    justify-content: center;
  }
  h4.False-connect-no-business {
    justify-content: center;
  }
  h4.True-connect-no-business {
    justify-content: center;
  }
  .True-tab h4, .False-tab h4 {
    font-size: .95rem;
  }
  .True-tab-no-business h4, .False-tab-no-business h4 {
    font-size: .95rem;
  }
  h4.True-connect-no-business, h4.False-connect-no-business {
    font-size: .95rem;
  }
  .name-date-flex {
    width: 75%;
  }
  .text-number-flex {
    width: 90%;
    margin-right: 1rem;
  }
  .inbox-profile-picture {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 50px;
    height: 50px;
  }
  .text-number-flex h4 {
    font-size: 1em;
  }
  .new-messages {
    width: 20px;
    height: 20px;
    font-size: 1rem;
    margin-left: 10px;
  }
  .text-number-flex h4 {
    width: 80%;
  }
  .connect-request-box {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .view-message-left {
    padding-right: 0px;
  }
  .no-connect-requests-text {
    height: 80vh;
    width: 100%;
  }
}

@media only screen and (max-width: 785px) {
  .connect-requests {
    height: 100%;
  }
  .message-list-container {
    overflow: hidden;
    height: 100%;
  }
  .text-number-flex {
    display: flex;
    justify-content: none;
  }
  .name-date-flex h4 {
    text-overflow: ellipsis;
    color: #777777;
    font-size: 1.2em;
    overflow: hidden;
  }
  .name-date-flex {
    display: flex;
  }
  .Inbox-Container {
    margin: 0;
  }
}